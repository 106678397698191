import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme
} from '@mui/material';
import { AccessTime, DateRange, CheckCircleOutline, HourglassEmpty } from '@mui/icons-material';
import axios from 'axios';
import './TimeLine.css'; // Import CSS file for custom styles




const TimeLine = ({ id }) => {
  const [shifts, setShifts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isTableMode, setIsTableMode] = useState(true); // State to toggle between table and card mode
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
    },
  };

  const fetchTimeLine = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_BACKEND}/api/employee/shifts/${id}`, config)
      .then((res) => {
        setLoading(false);
        setShifts(res.data.shifts);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    fetchTimeLine();
  }, []);

  const getShiftStyle = () => {
    return {
      marginBottom: '16px',
      padding: '16px',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
    };
  };
  const theme = useTheme();
  const toggleView = () => {
    setIsTableMode(!isTableMode);
  };

  return (
    <div className="timeline-container">
      <Button
        variant="outlined"
        onClick={toggleView}
        style={{ marginBottom: '10px' }}
      >
        {isTableMode ? 'Switch to Card View' : 'Switch to Table View'}
      </Button>
      {loading ? (
        <div className="loading-container">
          <CircularProgress />
        </div>
      ) : isTableMode ? (
        <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 200px)', borderRadius: '20px' }}>
          <Table className='custom-table' aria-label='simple table' >
            <TableHead sx={{

              position: 'sticky',
              top: 0,
              backdropFilter: 'blur(10px)',

            }}>
              <TableRow
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  '&.MuiTableRow-root th:first-child': { borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px" },
                  '&.MuiTableRow-root th:last-child': { borderTopRightRadius: "20px", borderBottomRightRadius: "20px" },
                  '&.MuiTableRow-root th': { marginBottom: "50px" },

                }}
              >
                <TableCell align="center">
                  <Typography variant="subtitle1" style={{ fontWeight: '400', color: theme.palette.text.secondary }}>
                    Date
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle1" style={{ fontWeight: '400', color: theme.palette.text.secondary }}>
                    Start Time
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle1" style={{ fontWeight: '400', color: theme.palette.text.secondary }}>
                    End Time
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle1" style={{ fontWeight: '400', color: theme.palette.text.secondary }}>
                    Total Break
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle1" style={{ fontWeight: '400', color: theme.palette.text.secondary }}>
                    Total Shift Length
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle1" style={{ fontWeight: '400', color: theme.palette.text.secondary }}>
                    Shift Without Break
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle1" style={{ fontWeight: '400', color: theme.palette.text.secondary }}>
                    Approved
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle1" style={{ fontWeight: '400', color: theme.palette.text.secondary }}>
                    Breaks
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shifts.map((shift, index) => (
                <TableRow key={index} className="custom-row" 
                sx={{
                  '&:nth-of-type(odd)': { backgroundColor: theme.palette.card.bg },
                  '&:nth-of-type(even)': { backgroundColor: theme.palette.card.alt },
                }}
                >
                  <TableCell align="center">{shift.date}</TableCell>
                  <TableCell align="center">{shift.startTime}</TableCell>
                  <TableCell align="center">{shift.endTime}</TableCell>
                  <TableCell align="center">{shift.totalBreak}</TableCell>
                  <TableCell align="center">{shift.totalShiftLength}</TableCell>
                  <TableCell align="center">{shift.shiftWithoutBreak}</TableCell>
                  <TableCell align="center">
                    {shift.approved ? (
                      <CheckCircleOutline sx={{ color: 'green' }} />
                    ) : (
                      <CheckCircleOutline sx={{ color: 'red' }} />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {shift.break.length > 0 && shift.break[0] && (
                      shift.break.map((breakItem, breakIndex) => (
                        <div key={breakIndex}>{`Start: ${breakItem.start}, End: ${breakItem.end}`}</div>
                      )))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        // List mode for mobile
        <List>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <CircularProgress />
            </div>
          ) : (
            shifts.map((shift, index) => (
              <Paper key={index} elevation={3} sx={getShiftStyle()} style={{ marginBottom: '16px' }}>
                <div>
                  <Typography variant="h6" sx={{ marginBottom: '8px' }}>
                    Shift Details
                  </Typography>
                  <ListItem>
                    <DateRange sx={{ marginRight: '8px' }} />
                    <ListItemText primary={`Date: ${shift.date}`} />
                  </ListItem>
                  <ListItem>
                    <AccessTime sx={{ marginRight: '8px' }} />
                    <ListItemText primary={`Start Time: ${shift.startTime}`} />
                  </ListItem>
                  <ListItem>
                    <AccessTime sx={{ marginRight: '8px' }} />
                    <ListItemText primary={`End Time: ${shift.endTime}`} />
                  </ListItem>
                  <ListItem>
                    <HourglassEmpty sx={{ marginRight: '8px' }} />
                    <ListItemText primary={`Total Break: ${shift.totalBreak}`} />
                  </ListItem>
                  <ListItem>
                    <HourglassEmpty sx={{ marginRight: '8px' }} />
                    <ListItemText primary={`Total Shift Length: ${shift.totalShiftLength}`} />
                  </ListItem>
                  <ListItem>
                    <HourglassEmpty sx={{ marginRight: '8px' }} />
                    <ListItemText primary={`Shift Without Break: ${shift.shiftWithoutBreak}`} />
                  </ListItem>
                  <ListItem>
                    {shift.approved ? (
                      <CheckCircleOutline sx={{ color: 'green', marginRight: '8px' }} />
                    ) : (
                      <CheckCircleOutline sx={{ color: 'red', marginRight: '8px' }} />
                    )}
                    <ListItemText primary={`Approved: ${shift.approved ? 'Yes' : 'No'}`} />
                  </ListItem>
                  {shift.break.length > 0 && shift.break[0] && (
                    <div>
                      <Divider sx={{ marginTop: '16px', marginBottom: '8px' }} />
                      <Typography variant="h6">Breaks</Typography>
                      {shift.break.map((breakItem, breakIndex) => (
                        <ListItem key={breakIndex}>
                          <ListItemText primary={`Start: ${breakItem.start}, End: ${breakItem.end}`} />
                        </ListItem>
                      ))}
                    </div>
                  )}
                </div>
              </Paper>
            ))
          )}
        </List>
      )}
    </div>
  );
}

export default TimeLine;
import React from "react";
import { CSVLink } from "react-csv";
import { Button } from "@mui/material";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { useTheme } from "@emotion/react";



function ExportCSV({ shiftArray, dateRange, branchName }) {
  const formatDate = (date) => {
    const dt = new Date(date);
    const dd = String(dt.getDate()).padStart(2, "0");
    const mm = String(dt.getMonth() + 1).padStart(2, "0");
    const yyyy = dt.getFullYear();
    return `${dd}-${mm}-${yyyy}`;
  };
  const convertToHHMM = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    return `${hours}:${minutes}`;
  };

  const data = [];
  for (const deptName in shiftArray) {
    const employees = shiftArray[deptName];
    for (const employeeId in employees) {
      const employee = employees[employeeId];
      employee.shifts.forEach((shift) => {
        const shiftData = {
          name: `${employee.employeeDetail.fname} ${employee.employeeDetail.lname}`,
          shiftDate: formatDate(shift.startDate),
          approvedStatus: shift.approved ? "Approved" : "Unapproved",
          shiftStatus: shift.status,
          startTime: new Date(shift.startDate),
          endTime: new Date(shift.endDate),
          totalShiftLength: convertToHHMM(shift.totalShiftLength),
          totalBreak: convertToHHMM(shift.totalBreak),
          shiftWithoutBreak: convertToHHMM(shift.shiftWithoutBreak),
          department: employee.department.department,
        };
        data.push(shiftData);
      });
    }
  }
  const headers = [
    { label: "Name", key: "name" },
    { label: "Shift Date", key: "shiftDate" },
    { label: "Approved Status", key: "approvedStatus" },
    { label: "Shift Status", key: "shiftStatus" },
    { label: "Start Time", key: "startTime" },
    { label: "End Time", key: "endTime" },
    { label: "Total Shift Time (hh:mm)", key: "totalShiftLength" },
    { label: "Total Break Time (hh:mm)", key: "totalBreak" },
    {
      label: "Total Shift Time Without Break (hh:mm)",
      key: "shiftWithoutBreak",
    },
    { label: "Department", key: "department" },
  ];
  const theme = useTheme();
  const csvReport = {
    filename: `${branchName} - Shifts (${new Date(dateRange.startDate)
      .toDateString()
      .slice(0, 3)} ${new Date(dateRange.startDate)
        .toDateString()
        .slice(4, 10)} -  ${new Date(dateRange.endDate)
          .toDateString()
          .slice(0, 3)} ${new Date(dateRange.endDate)
            .toDateString()
            .slice(4, 10)})`,
    headers: headers,
    data: data,
  };
  return (
    <div>
      <CSVLink {...csvReport} newlineseparator="\r\n" style={{ textDecoration: "none" }}>
        <Button
          variant="contained"
          style={{ textTransform: "none", backgroundColor: theme.palette.success.main, color: theme.palette.text.secondary,
          fontWeight: "500" }}
          startIcon={<ImportExportIcon />}
        >
          Export
        </Button>
      </CSVLink>
    </div>
  );
}
export default ExportCSV;

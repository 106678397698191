import React, { useState } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
  Grid,
  Dialog,
} from '@mui/material';
import axios from 'axios';
import { useEffect } from 'react';
import SuccessAlerts from '../SuccessAlert';
import ErrorAlerts from '../ErrorAlert';
import { DeleteForever } from '@mui/icons-material';

const DepartmentSettings = () => {
  const [departmentData, setDepartmentData] = useState({
    department: '',
    description: '',
  });

  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [open, setOpen] = useState(false);
  const [errorMailIds, setErrorMailIds] = useState([]);
  const [errorDepartmentId, setErrorDepartmentId] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDepartmentData({ ...departmentData, [name]: value });
  };
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const config = {
   headers: {
     "Content-type": "application/json",
     Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
   },
 };

   const fetchDepartments = async () => {
      setLoading(true);
      axios.get(`${process.env.REACT_APP_API_BACKEND}/api/getDepartments/${userInfo.currentBranchId}`, config).then((response) => {
         setDepartments(response.data);
         setLoading(false);
      });
   };

  
  const handleAddDepartment = () => {
    
    if (departmentData.department && departmentData.description) {
      const newDepartment = {
        department: departmentData.department,
        description: departmentData.description
      };
      axios.post(`${process.env.REACT_APP_API_BACKEND}/api/createDepartment/${userInfo.currentBranchId}`, newDepartment, config).then((response) => {
      setDepartments([...departments, newDepartment]);
      setSuccess(response.data.message);
      }).catch((error) => {
         console.log(error);
         setError(error.response.data.message);
      });


      setDepartmentData({
        department: '',
        description: '',
      });
      setTimeout(() => {
         console.log("hello");
         setSuccess("");
         setError("");
         }, 5000);
    }
  };

  useEffect(() => {
      fetchDepartments();
      
   }, []);
   let forceDelete = false;
   const handleDelete = (id, forceDelete=false) => {
      if (forceDelete) {
         axios.delete(`${process.env.REACT_APP_API_BACKEND}/api/deleteDepartment/${id}?forceDelete=true`, config).then((response) => {
            setSuccess(response.data.message);
            fetchDepartments();
         }).catch((error) => {
            setError(error.response.data.message);
         });

         setOpen(false);
      }
      else{
      axios.delete(`${process.env.REACT_APP_API_BACKEND}/api/deleteDepartment/${id}`, config).then((response) => {
         setSuccess(response.data.message);
         fetchDepartments();
      }).catch((error) => {
         //setError(error.response.data.message);
         if (error.response.data.message == "Department is assigned to employees") {
            setErrorDepartmentId(id);
            setErrorMailIds(error.response.data.mailIds);
            setOpen(true);
         }else{
            setError(error.response.data.message);
         }
      });
   }
      setTimeout(() => {
         setSuccess("");
         setError("");
      }, 5000);
   };
   
   function getRandomColor() {
      let color = "hsl(" + Math.random() * 360 + ", 100%, 75%)";
      return color;
    }

  return (
    <Container maxWidth="lg">
      {success && <SuccessAlerts success={success} />}
      {error && <ErrorAlerts error={error} />}
      
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ padding: 2, borderRadius: "10px" }}>
            <Typography variant="h6" gutterBottom>
              Add Department
            </Typography>
            <TextField
              fullWidth
              label="Department"
              name="department"
              value={departmentData.department}
              onChange={handleChange}
              variant="outlined"
              margin="normal"
            />
            <TextField
              fullWidth
              label="Description"
              name="description"
              value={departmentData.description}
              onChange={handleChange}
              variant="outlined"
              margin="normal"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddDepartment}
            >
              Add Department
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ padding: 2, borderRadius: "10px" }}>
            <Typography variant="h6" gutterBottom>
              Departments List
            </Typography>
            <List sx={{ maxHeight: "70vh", overflow: "auto" }}>
              {!loading && departments.map((department, index) => (
                <ListItem key={index} divider>
                  <ListItemText
                  
                    // primary={`Department: ${department.department} \n Description: ${department.description}`}
                    // secondary={`Description: ${department.description}`}
                  >
                    <Typography sx={{ fontSize: "20px", fontWeight: "500" }} >
                      {department.department}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {department.description}
                    </Typography>
                    </ListItemText>
                  <DeleteForever 
                  onClick={() => handleDelete(department.id)}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
      <Dialog
         open={open}
         onClose={() => {}}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
      >
         <div style={{ padding: "20px" , width: "500px"}}>
         <Typography  gutterBottom sx={{ fontWeight: "500", fontSize: "20px" }}>
            This department is assigned to following employees:
         </Typography>
         <div style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}>
         {errorMailIds && errorMailIds.map((mailId) => (
            <div style={{ backgroundColor: `${getRandomColor()}`, padding: "5px", borderRadius: "5px", margin: "5px" }}>
               {mailId}
            </div>
         ))}
         </div>
         <Typography variant="h6" gutterBottom>
            If you delete this department, all the employees will be assigned Basic department.
         </Typography>
         <Typography variant="h6" gutterBottom>
            Do you want to continue?
         </Typography>
         <Button
            variant="contained"
            color="primary"
            onClick={() => handleDelete(errorDepartmentId, true)}
            sx={{ marginRight: "10px" }}
         >
            Yes
         </Button>
         <Button
            variant="contained"
            color="secondary"
            onClick={() => setOpen(false)}
            sx={{ marginLeft: "10px" }}
         >
            No
         </Button>
         </div>
      </Dialog>

    </Container>
  );
};

export default DepartmentSettings;

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Alert, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Snackbar, TextField, Typography, useTheme } from '@mui/material';
import moment from 'moment';

const KioskSessions = () => {
    const [sessions, setSessions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [sessionAction, setSessionAction] = useState("");
    const [sessionId, setSessionId] = useState("");
    const [changePinDialogOpen, setChangePinDialogOpen] = useState(false);
    const [newPin, setNewPin] = useState(null);
    const [confirmNewPin, setConfirmNewPin] = useState(null);
    const [newPinAlert, setNewPinAlert] = useState("");
    const [success, setSuccess] = useState("");
    const [destroyExistingSessionsPinChange, setDestroyExistingSessionsPinChange] = useState(true);
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    const config = {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
        },
    };

    const getSessions = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_BACKEND}/api/branch/kioskSession`, config);
            setSessions(res.data.kioskSessions);
            setLoading(false);
        } catch (err) {
            setError(err.response.data.message);
        }
    }

    const updateSessionStatus = async (sessionId, newStatus) => {
        try {
            // await axios.put(`${process.env.REACT_APP_API_BACKEND}/api/branch/kioskSession/${sessionId}`, { status: newStatus }, config);
            // getSessions();
            axios.put(`${process.env.REACT_APP_API_BACKEND}/api/branch/kioskSession/${sessionId}`, { status: newStatus }, config)
                .then((res) => {
                    if (newStatus === "deleted") {
                        setSuccess("Session marked as deleted");
                    }
                    if (newStatus === "active") {
                        setSuccess("Session Activated");
                    }
                    if (newStatus === "inactive") {
                        setSuccess("Session Deactivated");
                    }


                    getSessions();
                })
                .catch((err) => {
                    setError(err.response.data.message);
                });

        } catch (err) {
            setError(err.response.data.message);
        }
    }


    const handleAllowGeoBypass = async (sessionId, allowGeoBypass) => {
        try {
            axios.put(`${process.env.REACT_APP_API_BACKEND}/api/branch/kioskSession/${sessionId}`, { allowGeoBypass: allowGeoBypass }, config)
                .then((res) => {
                    setSuccess(res.data.message);
                    getSessions();
                })
                .catch((err) => {
                    setError(err.response.data.message);
                });

        } catch (err) {
            setError(err.response.data.message);
        }
    }

    const submitNewPin = async () => {

        try {
            if (newPin.length !== 6) {
                setNewPinAlert("Pin must be 6 digits");
                return;
            }
            if (newPin !== confirmNewPin) {
                setNewPinAlert("Pins do not match");
                return;
            }


            await axios.patch(`${process.env.REACT_APP_API_BACKEND}/api/branch/changePin`, { pin: newPin, destroyExistingSessions: destroyExistingSessionsPinChange }, config)
                .then((res) => {
                    setChangePinDialogOpen(false);
                    setNewPin(0);
                    setConfirmNewPin(0);
                    setDestroyExistingSessionsPinChange(true);
                    setNewPinAlert("");
                    setSuccess("Pin changed successfully");
                    getSessions();
                })
                .catch((err) => {
                    setNewPinAlert(err.response.data.message);
                });

        } catch (err) {
            setError(err.response.data.message);
        }
    }

    useEffect(() => {
        getSessions();
    }, [])

    const theme = useTheme();

    const handleConfirmationDialogClose = () => {
        setConfirmationDialogOpen(false);
    };

    const handleConfirmAction = () => {
        if (sessionAction === "delete") {
            updateSessionStatus(sessionId, "deleted");
        } else {
            updateSessionStatus(sessionId, sessionAction === "activate" ? "active" : "inactive");
        }
        setConfirmationDialogOpen(false);
    };

    const handleSessionAction = (id, action) => {
        setSessionId(id);
        setSessionAction(action);
        setConfirmationDialogOpen(true);
    };


    return (
        <div style={{ padding: "20px" }}>
            <div>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={success}
                    autoHideDuration={3000}
                    onClose={() => setSuccess("")}
                >
                    <Alert onClose={() => setSuccess("")} severity="success" sx={{ width: '100%' }}>
                        {success}
                    </Alert>
                </Snackbar>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>Kiosk Sessions</Typography>

                <Button variant="contained" color="primary" sx={{ marginBottom: "20px" }} onClick={() => setChangePinDialogOpen(true)}>
                    Change Pin
                </Button>
                <Dialog open={changePinDialogOpen} onClose={() => setChangePinDialogOpen(false)} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Change Pin</DialogTitle>
                    <DialogContent>
                        {newPinAlert && <Alert severity="error" sx={{ marginBottom: "10px" }}>{newPinAlert}</Alert>}
                        <DialogContentText sx={{ color: theme.palette.text.primary }}>
                            Enter your new pin
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="newPin"
                            label="New Pin"
                            sx={{ '& label': { color: theme.palette.text.primary } }}
                            type="number"
                            fullWidth
                            value={newPin}
                            onChange={(e) => setNewPin(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            id="confirmNewPin"
                            label="Confirm New Pin"
                            type="number"
                            fullWidth
                            sx={{ '& label': { color: theme.palette.text.primary } }}
                            value={confirmNewPin}
                            onChange={(e) => setConfirmNewPin(e.target.value)}
                        />
                        <Box sx={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                            <Typography variant="body2" sx={{ marginRight: "10px" }}>Destroy existing sessions</Typography>

                            <input type="checkbox" checked={destroyExistingSessionsPinChange} onChange={(e) => setDestroyExistingSessionsPinChange(e.target.checked)} />


                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setChangePinDialogOpen(false)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={submitNewPin} color="primary">
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>



            </div>
            <div>
                {loading ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <CircularProgress />
                    </div>
                ) : error ? (
                    <p>Error: {error}</p>
                ) : sessions.length > 0 ? (
                    <Paper sx={{ borderRadius: "10px", padding: "20px", backgroundColor: theme.palette.card.bg }}>
                        {sessions.map((session) => (
                            <Paper key={session.id} sx={{ marginBottom: "20px", padding: "15px", backgroundColor: theme.palette.card.alt, borderRadius: "10px" }}>
                                <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
                                    Session ID: #{session.id}
                                </Typography>
                                <Box sx={{ display: "flex", marginBottom: "10px" }}>
                                    <Chip label={session.status.charAt(0).toUpperCase() + session.status.slice(1)} sx={{
                                        backgroundColor: session.status === 'active' ? theme.palette.success.main : theme.palette.warning.main,
                                        color: theme.palette.getContrastText(session.status === 'active' ? theme.palette.success.main : theme.palette.warning.main),
                                        marginRight: "10px"
                                    }} />
                                    <Typography variant="body2">
                                        Last Used: {moment(session.lastUsed).format('DD-MM-YYYY HH:MM:SS')}
                                    </Typography>
                                </Box>
                                <Typography variant="body2" gutterBottom>
                                    Expiration: {moment(session.expiry).format('DD-MM-YYYY HH:MM:SS')}
                                </Typography>
                                <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", marginTop: "10px" }}>
                                    {session.data &&
                                        <>
                                            {Object.keys(session.data).map((key) => (
                                                <Box key={key} sx={{ marginRight: "10px", marginBottom: "10px" }}>
                                                    <Typography variant="subtitle2" sx={{ fontWeight: "bold", textTransform: "capitalize" }}>{key}:</Typography>
                                                    <Typography variant="body2">{session.data[key]}</Typography>
                                                </Box>
                                            ))}
                                           
                                        </>
                                    }
                                </Box>
                                <Box sx={{ marginRight: "10px", marginBottom: "10px" , display: "flex" , flexDirection: "row" , alignItems: "center"}}> 
                                            <Typography variant="subtitle2" sx={{ fontWeight: "bold", textTransform: "capitalize" }}>Geo Status:</Typography>
                                            <Typography variant="body2"
                                                sx={{ color: session.allowGeoBypass ? theme.palette.error.main : theme.palette.success.main, marginLeft: "5px" }}
                                            >{session.allowGeoBypass ? "Allowed Login From Outside Location" : "Only Allowed from Inside Loaction"}</Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", marginTop: "10px" }}>
                                    {session.kioskRequest && 
                                        <p> #RequestId: {session.kioskRequest.id} </p>
                                    }
                                </Box>
                                {session.kioskRequest && session.kioskRequest.status === 'pending' ?
                                    (<Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", marginTop: "10px" }}>
                                        Request Action Pending
                                    </Box>)
                                    :
                                (<Box sx={{ marginTop: "20px" }}>
                                    {session.status === 'active' ? (
                                        <Button variant="contained" size="small" onClick={() => handleSessionAction(session.id, 'inactive')} sx={{ backgroundColor: theme.palette.warning.main, color: theme.palette.getContrastText(theme.palette.warning.main), marginRight: "10px" }}>
                                            Deactivate
                                        </Button>
                                    ) : (
                                        <Button variant="contained" size="small" color="success" onClick={() => handleSessionAction(session.id, 'activate')} sx={{ backgroundColor: theme.palette.success.main, color: theme.palette.getContrastText(theme.palette.success.main), marginRight: "10px" }}>
                                            Activate
                                        </Button>
                                    )}
                                    {
                                        session.allowGeoBypass ? (
                                            <Button variant="contained" size="small" color="info" onClick={() => handleAllowGeoBypass(session.id, false)} sx={{ backgroundColor: theme.palette.info.main, color: theme.palette.getContrastText(theme.palette.info.main), margin: "5px" }}>
                                                Restrict Geo Bypass
                                            </Button>
                                        ) :
                                            (
                                                <Button variant="contained" size="small" color="info" onClick={() => handleAllowGeoBypass(session.id, true)} sx={{ backgroundColor: theme.palette.error.main, color: theme.palette.getContrastText(theme.palette.error.main), margin: "5px" }}>
                                                    Allow Geo Bypass
                                                </Button>
                                            )

                                    }
                                    <Button variant="contained" size="small" color="error" onClick={() => handleSessionAction(session.id, 'delete')} sx={{ backgroundColor: theme.palette.error.main, color: theme.palette.getContrastText(theme.palette.error.main), margin: "5px" }}>
                                        Delete
                                    </Button>
                                </Box>)
}
                            </Paper>
                        ))}
                    </Paper>
                ) : (
                    <p>No sessions found</p>
                )}
                <Dialog
                    open={confirmationDialogOpen}
                    onClose={handleConfirmationDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Confirm Action</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" sx={{ color: theme.palette.text.primary }}>
                            Are you sure you want to {sessionAction === "delete" ? "delete" : (sessionAction === "activate" ? "activate" : "deactivate")} this session?
                            {sessionAction === "delete" ? " This action cannot be undone." : ""}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleConfirmationDialogClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleConfirmAction} color="primary" autoFocus>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

        </div>
    )
}

export default KioskSessions;

import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@mui/material";
import { withStyles } from "@material-ui/styles";
import AddIcon from "@mui/icons-material/Add";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import SettingsIcon from "@mui/icons-material/Settings";
import VisibilityIcon from "@mui/icons-material/Visibility";

// custom style for input text field
const styles = (theme) => ({
  input: {
    height: 40,
    paddingRight: 0,
  },
});

const BookingListHeader = withStyles(styles)((props) => {
//   <BookingListHeader 
//   bookingList={bookingData} 
//   setSortedBookingList={setSortedBookingList}
// />
  const { classes, bookingList, setSortedBookingList } = props;
  const [search, setSearch] = useState("");
  const [employee, setEmployee] = useState("All");
  const [status, setStatus] = useState("All");
  const [type, setType] = useState("All");
  const [source, setSource] = useState("All");

  const data = bookingList;

  // data: {
  //   contact:{
  //     name:"aa"
  //   }
  // }
 const handleSearch = (e) => {
    setSearch(e.target.value);
    const filteredData = data.filter((item) => {
      if (item.contact.name.toLowerCase().includes(e.target.value.toLowerCase()) || item.contact.email.toLowerCase().includes(e.target.value.toLowerCase()) || item.contact.phone.toLowerCase().includes(e.target.value.toLowerCase()) || item.bookingId.toLowerCase().includes(e.target.value.toLowerCase())) {
        return item;
      }
    });
    setSortedBookingList(filteredData);
  };
  const handleStatus = (e) => {
    setStatus(e.target.value);
    const filteredData = data.filter((item) => {
      if (item.status.toLowerCase() == (e.target.value.toLowerCase())) {
        return item;
      }
      if (e.target.value == "AllExceptCancelled") {
        return item.status.toLowerCase() != "cancelled";
      }
      if (e.target.value == "All") {
        return item;
      }
    });
    setSortedBookingList(filteredData);
  };
  const handleType = (e) => {
    setType(e.target.value);
    const filteredData = data.filter((item) => {
      if (item.type.toLowerCase().includes(e.target.value.toLowerCase())) {
        return item;
      }
    });
    setSortedBookingList(filteredData);
  };
  const handleSource = (e) => {
    setSource(e.target.value);
    const filteredData = data.filter((item) => {
      if (item.source.toLowerCase().includes(e.target.value.toLowerCase())) {
        return item;
      }
    });
    setSortedBookingList(filteredData);
  };
  const handleEmployee = (e) => {
    setEmployee(e.target.value);
    const filteredData = data.filter((item) => {
      if (item.employee.toLowerCase().includes(e.target.value.toLowerCase())) {
        return item;
      }
    });
    setSortedBookingList(filteredData);
  };

  return (
    <div>
      <div
        className="header"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div className="left-container">
          <div style={{ fontSize: "1.4rem" }}>Bookings</div> <br />
          <span>Search</span>
          <br />
          <TextField
            id="outlined-basic"
            style={{ width: "300px" }}
            value={search}
            onChange={handleSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: "0 5px 5px 0",
                      backgroundColor: "#1a237e",
                      textTransform: "capitalize",
                      fontWeight: "600",
                      height: "2.5rem",
                    }}
                  >
                    <SearchIcon />
                  </Button>
                </InputAdornment>
              ),
              className: classes.input,
            }}
            variant="outlined"
          />
        </div>
        <div className="right-container" style={{ marginLeft: "1rem" }}>
          {/* <Button variant="contained" style={{backgroundColor:"#1a237e",textTransform:"capitalize", fontWeight:"600"}}><AddIcon/> Invite employee</Button> */}
          <Stack spacing={1} direction="row" justifyContent="right">
            <Button
              variant="contained"
              style={{ textTransform: "none", backgroundColor: "#22baee", fontWeight:"500" }}
              startIcon={<ImportExportIcon />}
            >
              Export
            </Button>
            <Button
              variant="contained"
              style={{ textTransform: "none", backgroundColor: "#e0e0e0", color: "#000", fontWeight:"500" }}
              startIcon={<SettingsIcon />}
            >
              Booking Settings
            </Button>
            <Button
              variant="contained"
              style={{ textTransform: "none", backgroundColor: "#4caf50", fontWeight:"500"}}
              startIcon={<VisibilityIcon />}
            >
              View Bookings
            </Button>
            <Button
              variant="contained"
              style={{ textTransform: "none", fontWeight:"500" }}
              startIcon={<AddIcon />}
            >
              Add Booking
            </Button>
          </Stack>

          <Stack spacing={1} direction="row" justifyContent="right">
            <div>
              <div style={{ marginTop: "1.2rem" }}>Employee</div>
              <FormControl fullWidth size="small">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={employee}
                  onChange={handleEmployee}
                  style={{ width: "7rem" }}
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  <MenuItem value={"Active"}>Active</MenuItem>
                  <MenuItem value={"Inactive"}>Inactive</MenuItem>
                  <MenuItem value={"Onboarding"}>Onboarding</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <div style={{ marginTop: "1.2rem" }}>Status</div>
              <FormControl fullWidth size="small">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={status}
                  onChange={handleStatus}
                  style={{ width: "7rem" }}
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  <MenuItem value={"AllExceptCancelled"}>All Except Cancelled</MenuItem>
                  <MenuItem value={"Confirmed"}>Confirmed</MenuItem>
                  <MenuItem value={"Unconfirmed"}>Unconfirmed</MenuItem>
                  <MenuItem value={"Seated"}>Seated</MenuItem>
                  <MenuItem value={"NoShow"}>No Show</MenuItem>
                  <MenuItem value={"Cancelled"}>Cancelled</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <div style={{ marginTop: "1.2rem" }}>Type</div>
              <FormControl fullWidth size="small">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type}
                  style={{ width: "7rem" }}
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  <MenuItem value={"Default"}>Default</MenuItem>
                  <MenuItem value={"Event"}>Event</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <div style={{ marginTop: "1.2rem" }}>Source</div>
              <FormControl fullWidth size="small">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={source}
                  style={{ width: "7rem" }}
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  <MenuItem value={"WalkIn"}>Walk In</MenuItem>
                  <MenuItem value={"Online"}>Online</MenuItem>
                </Select>
              </FormControl>
            </div>
          </Stack>
        </div>
      </div>
    </div>
  );
});

export default BookingListHeader;

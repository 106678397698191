import React, { useState } from "react";
import EmployeeTable from "../components/Employees/Employees/EmployeeTable";
import EmployeeHeader from "../components/Employees/Employees/EmployeeHeader";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import {
  Link,
  Route,
  useLocation,
  useHistory,
  matchPath,
  useParams,
} from "react-router-dom";
import MobileEmployeeHeader from "../components/Employees/Employees/MobileEmployeeHeader";

const Employee = () => {

  // getting logged in user info
  const userLogin = useSelector((state) => state.userLogin);
  const {userInfo } = userLogin;
  const history = useHistory();
  const location = useLocation();
  const [employees, setEmployees] = useState([]);
  const [mainEmployees, setMainEmployees] = useState([]);
  const [employeeLoading, setEmployeeLoading] = useState(false);
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1024);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
    },
  };

  async function fetchEmployees() {
    setEmployeeLoading(true);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}/api/employees`,
      config
    );
    setEmployeeLoading(false);
    setEmployees(data.employees);
    setMainEmployees(data.employees);
  }

  useEffect(() => {
    fetchEmployees();
    if(userInfo.isAdmin && userInfo.flag == 'Signed Up'){
      history.push('/user/onboard')
    }
    if (userInfo.flag == "Registered") {
      history.push("/onboard");
    }
    if (userInfo.flag == "Onboarding") {
      history.push("/registered");
    }    
}, [userInfo])

  return (
    <div
      style={{
        overflow: "auto",
      }}
    >
      {isMobile ? (
        <MobileEmployeeHeader setEmployeeLoading={setEmployeeLoading} employees={employees} mainEmployees={mainEmployees} setEmployees={setEmployees} userInfo={userInfo} fetchEmployees={fetchEmployees} />
      ) : (
      <EmployeeHeader setEmployeeLoading={setEmployeeLoading} employees={employees} mainEmployees={mainEmployees} setEmployees={setEmployees} userInfo={userInfo} fetchEmployees={fetchEmployees} />
      )}
      <EmployeeTable employeeLoading={employeeLoading} employees={employees} setEmployees={setEmployees} />
    </div>
  );
};

export default Employee;

import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import { Avatar, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
// import TimePicker from "@mui/lab/TimePicker";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Checkbox from "@mui/material/Checkbox";
import AddIcon from "@mui/icons-material/Add";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import axios from "axios";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Link } from "react-router-dom";
import ShiftTimeLine from "./ShiftTimeLine";
import { useSelector } from "react-redux";
import { TimelapseOutlined } from "@mui/icons-material";
import moment from "moment";
import dayjs from "dayjs";
import { parseISO } from "date-fns";

export default function ShiftBox(props) {
  const [open, setOpen] = useState(false);
  const fullData = { employeeDetail: props.employee, email: props.email };
  const [startvalue, setStartValue] = useState(new Date(props.shift.startDate));
  const [endvalue, setEndValue] = useState(
    !props.shift.endDate ? new Date() : new Date(props.shift.endDate)
  );
  const [startTime, setStartTime] = useState(props.shift.startDate);
  const [endTime, setEndTime] = useState(props.shift.endDate);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [breakTime, setBrakeTime] = useState(
    Array.isArray(props.shift.break) ? props.shift.break : []
  );
  const [shiftApproved, setShiftApproved] = useState(props.shift.approved);
  const [shiftLength, setShiftLength] = useState(props.shift.totalShiftLength);
  const [totalBreak, setTotalBreak] = useState(props.shift.totalBreak);
  const [shiftWOBreak, setShiftWOBreak] = useState(
    props.shift.shiftWithoutBreak
  );
 
  const theme = useTheme();
  // for opening and closing of the box
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  // function to add break in the break array
  const addBreak = () => {
    const newArray = [...breakTime];
    newArray.push({ start: new Date(), end: new Date() });
    setBrakeTime(newArray);
    newArray.map((i) => {
      setTotalBreak(totalBreak + calculateBreakLength(i.start, i.end));
    });

  };


  function convertS3ToCloudFront(url) {
    // Replace the AWS S3 domain with the CloudFront domain
    const cloudFrontDomain = 'https://d2k8ghk99lpcqm.cloudfront.net/';
    const s3Domain = 'https://cms-shifts.s3.ap-southeast-2.amazonaws.com/';
    
    // Check if the URL starts with the S3 domain
    if (url.startsWith(s3Domain)) {
      // Replace the S3 domain with the CloudFront domain
      const convertedUrl = url.replace(s3Domain, cloudFrontDomain);
      return convertedUrl;
    } else {
      // URL doesn't match the expected pattern
      console.error('Invalid URL format');
      return url;
    }
  }
  
 

  const getAvatarName = (employee) => {
    if (employee.employeeDetail) {
      if (employee.employeeDetail.fname && employee.employeeDetail.lname) {

        return employee.employeeDetail.fname.charAt(0).toUpperCase() + employee.employeeDetail.lname.charAt(0).toUpperCase();
      }
      else if (employee.employeeDetail.fname) {
        return employee.employeeDetail.fname.charAt(0).toUpperCase();
      }
      else if (employee.employeeDetail.lname) {
        return employee.employeeDetail.lname.charAt(0).toUpperCase();
      }
    }
    else {
      return employee.email.charAt(0).toUpperCase();
    }
  }
  // function to update the start time for particular break in the break array
  const updateStartBreak = (value, index) => {  
    const newArray = [...breakTime];
    newArray[index]["start"] = value;
  };

  // function to update the end time for particular break in the break array
  const updateEndBreak = (value, index) => { 
    const newArray = [...breakTime];
    newArray[index]["end"] = value;
    // setBrakeTime(newArray);

  };

  // function to remove break from the break array
  const removeBreak = (index) => {
    const newArray = [...breakTime];
    newArray.splice(index, 1);
    setBrakeTime(newArray);
  };
  // function to format dates for making the usable
  const formatTime = (date) => {
    const d = new Date(date);
    return d.toLocaleTimeString("en-GB");
  };

  // custom styling for approved abd unapproved shifts
  const shiftStyle = {
    Approved: {
      backgroundColor: "#ddf3de",
      border: "1px solid #87ca8a",
      borderLeft: "5px solid #87ca8a",
      cursor: "pointer",
    },
    Pending: {
      backgroundColor: "#2196f32e",
      borderRadius: "5px",
      border: "1px solid #64b5f6",
      borderLeft: "5px solid #64b5f6",
      cursor: "pointer",
      fontSize: "14px",
    },
  };
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // submit handler for sending an update request
  const submitHandler = () => {

    const data = {
      startDate: startvalue,
      endDate: endvalue,
      break: breakTime,
      approved: shiftApproved,
      totalShiftLength: shiftLength,
      totalBreak: totalBreak,
      shiftWithoutBreak: shiftWOBreak,
    };
    const config = {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
      },
    };
    //convert data to json
    const jsonData = JSON.stringify(data);

    axios
      .patch(
        `${process.env.REACT_APP_API_BACKEND}/api/employee/shiftedit/${props.shift.id}`,
        {
          startDate: startvalue,
          endDate: endvalue,
          break: breakTime,
          approved: shiftApproved,
          totalShiftLength: shiftLength,
          totalBreak: totalBreak,
          shiftWithoutBreak: shiftWOBreak,
        },
        config
      )
      .then((res) => {
        // setShifts(res.data);
        props.setSuccess(res.data.message);
        setStartTime(startvalue);
        setEndTime(endvalue);

        // props.fetchShifts();
        handleClose();
        // window.location.reload(false);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        handleClose();
        props.setError(err.response.data.message);
      });

  };

  const handleEndShift = () => {
    const config = {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
      },
    };
    axios
      .patch(
        `${process.env.REACT_APP_API_BACKEND}/api/employee/shiftend/${props.shift.id}`, {}, config
      )
      .then((res) => {
        // setShifts(res.data);
        props.setSuccess(res.data.message);
        props.fetchShifts();
        handleClose();
        // window.location.reload(false);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        handleClose();
        props.setError(err.response.data.message);
      });
  }



  // function to toggle shift between approved and unapproved
  const handleShiftApproved = () => {
    if (shiftApproved == true) {
      setShiftApproved(false);
    } else {
      setShiftApproved(true);
    }
  };
  // function to calculate the total break
  const approveAndUpdate = () => {
    const data = {
      startDate: startvalue,
      endDate: endvalue,
      break: breakTime,
      approved: shiftApproved,
      totalShiftLength: shiftLength,
      totalBreak: totalBreak,
      shiftWithoutBreak: shiftWOBreak,
    };
    const config = {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
      },
    };
    //convert data to json
    const jsonData = JSON.stringify(data);

    axios
      .patch(
        `${process.env.REACT_APP_API_BACKEND}/api/employee/shiftapprove/${props.shift.id}`,
        {
        },
        config
      )
      .then((res) => {
        // setShifts(res.data);
        props.setSuccess(res.data.message);
        setShiftApproved(true);
        handleClose();
        // window.location.reload(false);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        handleClose();
        props.setError(err.response.data.message);
      });
  };


  function calculateBreakLength(start, end) {
    let now = new Date(start);
    let endDate = new Date(end);
    let diff = Math.abs(endDate - now);

    let hours = Math.floor(diff / 3.6e6);
    let minutes = Math.floor((diff % 3.6e6) / 6e4);
    let total;
    if (hours < 10) {
      if (minutes < 10) {
        total = "0" + hours + ":0" + minutes;
      } else {
        total = "0" + hours + ":" + minutes;
      }
    } else {
      if (minutes < 10) {
        total = hours + ":0" + minutes;
      } else {
        total = hours + ":" + minutes;
      }
    }
    return total;
  }
    function calculateTotalBreak() {
    let hours = 0;
    let minutes = 0;
    let seconds = 0;
    breakTime.map((brk) => {
      let now = new Date(brk.start);
      let endDate = brk.end ? new Date(brk.end) : new Date();
      let diff = Math.abs(endDate - now);

      hours = hours + Math.floor(diff / 3.6e6);
      minutes = minutes + Math.floor((diff % 3.6e6) / 6e4);
      if (minutes > 59) {
        minutes = minutes - 60;
        hours = hours + 1;
      }
      seconds = seconds + Math.round(Math.floor((diff % 6e4) / 1000));
    });
    let total;
    if (hours < 10) {
      if (minutes < 10) {
        if (seconds < 10) {
          total = "0" + hours + ":0" + minutes + ":0" + seconds;
        } else {
          total = "0" + hours + ":0" + minutes + ":" + seconds;
        }
      } else {
        if (seconds < 10) {
          total = "0" + hours + ":" + minutes + ":0" + seconds;
        } else {
          total = "0" + hours + ":" + minutes + ":" + seconds;
        }
      }
    } else {
      if (minutes < 10) {
        if (seconds < 10) {
          total = hours + ":0" + minutes + ":0" + seconds;
        } else {
          total = hours + ":0" + minutes + ":" + seconds;
        }
      } else {
        if (seconds < 10) {
          total = hours + ":" + minutes + ":0" + seconds;
        } else {
          total = hours + ":" + minutes + ":" + seconds;
        }
      }
    }

    setTotalBreak(total);
  }

  function calculateTotalShiftLength() {
    let now = new Date(startvalue);
    let endDate = new Date(endvalue);
    let diff = Math.abs(endDate - now);

    let hours = Math.floor(diff / 3.6e6);
    let minutes = Math.floor((diff % 3.6e6) / 6e4);
    let seconds = Math.round(Math.floor((diff % 6e4) / 1000));
    let total;
    if (hours < 10) {
      if (minutes < 10) {
        if (seconds < 10) {
          total = "0" + hours + ":0" + minutes + ":0" + seconds;
        } else {
          total = "0" + hours + ":0" + minutes + ":" + seconds;
        }
      } else {
        if (seconds < 10) {
          total = "0" + hours + ":" + minutes + ":0" + seconds;
        } else {
          total = "0" + hours + ":" + minutes + ":" + seconds;
        }
      }
    } else {
      if (minutes < 10) {
        if (seconds < 10) {
          total = hours + ":0" + minutes + ":0" + seconds;
        } else {
          total = hours + ":0" + minutes + ":" + seconds;
        }
      } else {
        if (seconds < 10) {
          total = hours + ":" + minutes + ":0" + seconds;
        } else {
          total = hours + ":" + minutes + ":" + seconds;
        }
      }
    }

    setShiftLength(total);
  }
  const formatShiftLength = (time) => {
    let now = new Date(time);
    let hours = Math.floor(now / 3.6e6);
    let minutes = Math.floor((now % 3.6e6) / 6e4);
    let seconds = String(Math.floor(now % 6e4) / 1000).substring(0, 2);
    let total;
    if (hours < 10) {
      if (minutes < 10) {
        if (seconds < 10) {
          total = "0" + hours + ":0" + minutes + ":0" + seconds;
        } else {
          total = "0" + hours + ":0" + minutes + ":" + seconds;
        }
      } else {
        if (seconds < 10) {
          total = "0" + hours + ":" + minutes + ":0" + seconds;
        } else {
          total = "0" + hours + ":" + minutes + ":" + seconds;
        }
      }
    } else {
      if (minutes < 10) {
        if (seconds < 10) {
          total = hours + ":0" + minutes + ":0" + seconds;
        } else {
          total = hours + ":0" + minutes + ":" + seconds;
        }
      } else {
        if (seconds < 10) {
          total = hours + ":" + minutes + ":0" + seconds;
        } else {
          total = hours + ":" + minutes + ":" + seconds;
        }
      }
    }
    return total;
  };




  const calculateshiftWithoutBreak = () => {
    let hours;
    let minutes;
    let seconds;

    const totalBreakHours = parseInt(totalBreak.slice(0, 2));
    const totalBreakMinutes = parseInt(totalBreak.slice(3, 5));
    const totalBreakSeconds = parseInt(totalBreak.slice(6, 8));

    const shiftLengthHours = parseInt(shiftLength.slice(0, 2));
    const shiftLengthMinutes = parseInt(shiftLength.slice(3, 5));
    const shiftLengthSeconds = parseInt(shiftLength.slice(6, 8));

    hours = shiftLengthHours - totalBreakHours;
    minutes = shiftLengthMinutes - totalBreakMinutes;
    seconds = shiftLengthSeconds - totalBreakSeconds;

    // Handle negative values
    if (seconds < 0) {
      minutes -= 1;
      seconds += 60;
    }
    if (minutes < 0) {
      hours -= 1;
      minutes += 60;
    }

    // Ensure that hours, minutes, and seconds are always two digits
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    const total = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    setShiftWOBreak(total);
  }
  const roundOffTime = (hhmmss) => {
    const [hours, minutes, seconds] = hhmmss.split(":");
    const totalSeconds = parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
    const roundedSeconds = Math.round(totalSeconds / 60) * 60;
    const roundedHours = Math.floor(roundedSeconds / 3600);
    const roundedMinutes = Math.floor((roundedSeconds % 3600) / 60);
    const formattedHours = roundedHours.toString().padStart(2, '0');
    const formattedMinutes = roundedMinutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
  };

  useEffect(() => {
    calculateTotalBreak();
    calculateTotalShiftLength();
    calculateshiftWithoutBreak();
  }, [
    startvalue,
    endvalue,
    breakTime,
    shiftApproved,
    shiftLength,
    totalBreak,
    shiftWOBreak,
  ]);

  const handleStartDateChange = (date) => {
    const d = date;

    setStartValue(d);


    calculateTotalShiftLength();
    calculateshiftWithoutBreak();
  };
  const handleEndDateChange = (date) => {
    const d = date;

    setEndValue(d);


    calculateTotalShiftLength();
    calculateshiftWithoutBreak();
  };
  const deleteShift = (id) => {
    const config = {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
      },
    };
    axios
      .delete(
        `${process.env.REACT_APP_API_BACKEND}/api/employee/shiftdelete/${id}`,
        config
      )
      .then((res) => {
        // setShifts(res.data);
        props.setSuccess(res.data.message);
        props.fetchShifts();
        // window.location.reload(false);
      })
      .catch((err) => {
        setOpen(false);
        console.log(err.response.data.message);
        props.setError(err.response.data.message);
        // props.fetchShifts();
      });
  }
  return (
    <>

      <Box sx={{ width: "180px", paddingBottom: "10px", borderRadius: "20px", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", textAlign: "center", cursor: "pointer", backgroundColor: theme.palette.card.bg, transition: "background-color 0.3s ease", margin: "auto", marginTop: "5px" }} onClick={handleClickOpen}>
        <Box sx={{
          fontWeight: "500",
          backgroundColor: shiftApproved ? theme.palette.success.main : theme.palette.button.blue,
          color: shiftApproved ? theme.palette.text.secondary : theme.palette.text.primary, borderRadius: "10px", width: "90%", margin: "10px 2px 2px 2px", textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center"
        }}>
          <Box sx={{ padding: "2px 0" }}>
            {formatTime(startTime).slice(0, 5)} -{" "}
            {endTime ? formatTime(endTime).slice(0, 5) : <span>Active</span>}
          </Box>
        </Box>
        <Box sx={{
          display: "flex", flexDirection: "row",
          justifyContent: "space-between", alignItems: "center",
          textAlign: "center",
          fontSize: "1rem",
          padding: "0",
          fontWeight: "300",
        }}>
          <Box sx={{ marginTop: "5px" }}>
            Shift:{" "} <span>{shiftLength && shiftLength.slice(0, 5)}</span>
          </Box>
          <Box sx={{ marginRight: "5px", marginLeft: "5px" }}>
            |
          </Box>
          <Box sx={{ marginTop: "5px" }}>
            Break: <span>{totalBreak && totalBreak.slice(0, 5)}</span>
          </Box>
        </Box>
        <Box sx={{ fontSize: "1rem", fontWeight: "300", marginTop: "5px" }} >
          Shift w/o break:{" "}
          <span
            style={{ color: "#ee5622", fontWeight: "600" }}
          >{shiftWOBreak && shiftWOBreak.slice(0, 5)} </span>
        </Box>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        style={{ borderRadius: "30px", width: "max-content", margin: "auto" }}
      >
        <DialogContent style={{ display: "flex", padding: "0", }}>

          <div
            style={{
              padding: "20px",
              backgroundColor: "#f3f3f3",
              display: "flex",
              flexDirection: "column",
              flex: "1",
              borderRadius: "20px",
              position: "sticky",
              top: "0",
              zIndex: "100",
              color: theme.palette.text.secondary,
              boxShadow: "0px 0px 10px 0px #000000ff",
            }}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>

              <Avatar style={{
                width: "5rem", height: "5rem", color: "#fff", fontWeight: "500", fontSize: "2rem",
                backgroundColor: "#474d56", borderRadius: "20%", boxShadow: "0px 0px 5px 0px #1d1d1d74",
                margin: "auto",
              }}
              >
                {getAvatarName(fullData)}
              </Avatar>
              <Link
                style={{ textDecoration: "none", color: "black", margin: "auto" }}
                to={`/employee/${props.employeeId}`}

              >

                <div
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    textAlign: "center",
                    margin: "auto",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      marginTop: "0",
                    }}
                  >
                    {props.employee.title} <br />
                    {props.employee.fname} {props.employee.lname}
                  </p>

                </div>
              </Link>

              <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex" }}>
                    <AccessTimeIcon />
                    <span style={{ fontWeight: "600", fontSize: "1rem" }}>
                      {shiftLength.slice(0, 5)}
                    </span>
                  </div>

                  <div>
                    <span style={{ fontSize: "0.8rem", textAlign: "left", color: "#454545" }}>
                      Shift length
                    </span>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column" , marginTop: "10px"}}>
                  <div style={{ display: "flex" }}>
                  <FormatListBulletedIcon />
                    <span style={{ fontWeight: "600", fontSize: "1rem" }}>
                      {totalBreak.slice(0, 5)}
                    </span>
                  </div>

                  <div>
                    <span style={{ fontSize: "0.8rem", textAlign: "left", color: "#454545" }}>
                      Total Break
                    </span>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}>
                  <div style={{ display: "flex" }}>
                    <TimelapseOutlined />
                    <span
                      style={{
                        fontWeight: "600",
                        fontSize: "1rem",
                        color: "#ee5622",
                      }}
                    >
                      {shiftWOBreak.slice(0, 5)}
                    </span>
                  </div>
                  <div>
                    <span style={{ fontSize: "0.8rem", textAlign: "left", color: "#454545" }}>
                      Shift w/o break
                    </span>
                  </div>
                </div>
                <div style={{ marginTop: "1rem" }}>
                  <span style={{ fontSize: "0.8rem", textAlign: "left", color: "#454545" }}>
                    Shift ID: #{props.shift.id}
                  </span>
                </div>
              </div>

              {!props.shift.endDate &&
                <Button variant="contained"
                  onClick={handleEndShift}
                  style={{ backgroundColor: "#ee5622", color: "#fff", fontWeight: "500", marginTop: "1rem" }}>
                  End Shift
                </Button>
              }
            </div>
          </div>

          <div style={{ padding: "20px", flex: "4", paddingTop: "0" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h4 style={{ fontWeight: "400" }}>
                Edit Shift on:{" "}
                <span style={{ fontWeight: "600" }}>
                  {new Date(props.shift.startDate).toString().slice(0, 15)}
                </span>
              </h4>
              {!props.shift.endDate && <h4 style={{ color: "#ee5622" }}>Currently Active</h4>}
            </div>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDateTimePicker
                    ampm={false}
                    label="Start *"
                    sx={{ '& label': { color: theme.palette.text.primary } }}
                    value={startvalue}
                    slotProps={{ textField: { size: 'small' } }}
                    onChange={(newValue) => {
                      handleStartDateChange(newValue);
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDateTimePicker
                    ampm={false}
                    label="End *"
                    sx={{ '& label': { color: theme.palette.text.primary } }}
                    slotProps={{ textField: { size: 'small' } }}
                    value={endvalue}
                    onChange={(newValue) => {
                      handleEndDateChange(newValue);
                    }}
                  // disableOpenPicker={true}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <img
                  src={props.shift.startImage && convertS3ToCloudFront(props.shift.startImage)}
                  alt="Start Shift Image"
                  style={{
                    width: "100%",
                    marginTop: "8px",
                    borderRadius: "5px",
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <img
                  // src={props.shift.endImage}
                  src={props.shift.endImage && convertS3ToCloudFront(props.shift.endImage)}
                  alt="End Shift Image"
                  style={{
                    width: "100%",
                    marginTop: "8px",
                    borderRadius: "5px",
                  }}
                />
              </Grid>
            </Grid>
            <p>Breaks</p>
            {breakTime == "" ? (
              <div>No breaks Found.</div>
            ) : (
              breakTime.map((brk, index) => (
                <Grid container spacing={2} key={index} style={{marginTop: '0.1rem'}}>
                  <Grid item xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="From"
                        defaultValue={new Date()} 
                        value={new Date(brk.start)}
                        ampm={false}
                        slotProps={{ textField: { size: 'small' } }}
                        sx={{ '& label': { color: theme.palette.text.primary } }}
                        onChange={(newValue) => {
                          updateStartBreak(newValue, index);
                          calculateTotalBreak();
                          calculateshiftWithoutBreak();
                        }}
                        />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="To"
                        defaultValue={new Date()}
                        value={new Date(brk.end)}
                        ampm={false}
                        slotProps={{ textField: { size: 'small' } }}
                        sx={{ '& label': { color: theme.palette.text.primary } }}
                        onChange={(newValue) => {
                          updateEndBreak(newValue, index);
                          calculateTotalBreak();
                          calculateshiftWithoutBreak();
                        }}
                      
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={2} style={{paddingTop: "10px", 
                  display: "flex", 
                  justifyContent: "space-between", 
                  width: "100%",
                  alignItems: "center"}}>
                    <div style={{ fontWeight: "500", marginRight: "5px" }}>
                      {calculateBreakLength(brk.start, brk.end ? brk.end : new Date()).slice(1)}
                    </div>
                    
                        <RemoveCircleOutlineIcon sx={{ color: "red", fontSize: "1.5rem" }} style={{ cursor: "pointer" }} onClick={() => removeBreak(index)} />
                  
                  </Grid>
                </Grid>
              ))
            )}
            <Button
              variant="contained"
              style={{
                marginTop: "1rem",
                textTransform: "capitalize",
                backgroundColor: "#22baee",
                fontWeight: "500",
              }}
              onClick={addBreak}
              startIcon={<AddIcon />}
            >
              Add Break
            </Button>
            <div style={{ marginTop: "1rem" ,marginBottom: "1rem" , display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <div>
              <Checkbox
                checked={shiftApproved}
                sx={{ paddingLeft: "0" }}
                onChange={handleShiftApproved}
              />{" "}
              Shift Approved
            </div>
            <ShiftTimeLine shiftTimelines={props.shift.shiftTimelines} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Button
                  variant="contained"
                  style={{
                    textTransform: "capitalize",
                    backgroundColor: "#f44336",
                  }}
                  onClick={() => { deleteShift(props.shift.id) }}
                  startIcon={<DeleteIcon />}
                >
                  Delete
                </Button>

              </div>

              <div style={{ marginRight: "0" }}>
                <Button
                  variant="contained"
                  style={{ textTransform: "capitalize" }}
                  startIcon={<DoneIcon />}
                  onClick={submitHandler}
                >
                  Update
                </Button>
              </div>

              {!shiftApproved ? <div>
                <Button
                  variant="contained"
                  style={{
                    textTransform: "capitalize",
                    backgroundColor: "#32CD32",
                    color: "#fff",
                    fontWeight: "500",
                    width: "max-content"
                  }}
                  onClick={approveAndUpdate}
                  startIcon={<DoneIcon />}
                >
                  Approve Shift
                </Button>
              </div>
                : <></>}

            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

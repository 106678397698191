import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { withStyles, makeStyles } from "@material-ui/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import TablePagination from "@mui/material/TablePagination";
import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";
import { useSelector } from "react-redux";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Box, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Link, useHistory, useLocation } from "react-router-dom";
import TableLoader from "../TableLoader";
// import { bookingData } from "./sampleBookingData";
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import LanguageIcon from '@mui/icons-material/Language';



//custom style for table
const tableStyles = makeStyles({
  tableRow: {
    height: 20,
  },
  tableCell: {
    padding: "10px 15px",
  },
  bodyCell: {
    padding: "6px 16px",
  },
});
const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 20,
  },
}))(TableRow);
const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "10px 5px",
  },
}))(TableCell);

const BookingListTable = (props) => {
  const { bookingList, setSortedBookingList, loading } = props;
//json parse the bookingList and set it to data
  const bookingData = bookingList;
  const classes = tableStyles();

  // state for pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // function to handle pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // function to handle the number of rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const statusColor = {
   "Confirmed": "#0ac2f5",
   "Unconfirmed": "#f44336",
   "Seated": "#4ede57",
   "No Show": "#ff9800",
    "Cancelled": "#9e9e9e",
  };

  return (
    <div>
      <Paper
        sx={{
          marginTop: "1rem",
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        }}
      >
        <TableContainer sx={{ width: "100%", overflowX: "hidden" }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600" }}
                  className={classes.tableCell}
                >
                  Booking #
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600" }}
                  className={classes.tableCell}
                >
                  Booking Date
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600" }}
                  className={classes.tableCell}
                >
                  Contact
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600" }}
                  className={classes.tableCell}
                >
                  Covers
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600" }}
                  className={classes.tableCell}
                >
                  Table
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600" }}
                  className={classes.tableCell}
                >
                  Type
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600" }}
                  className={classes.tableCell}
                >
                  Employee
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600" }}
                  className={classes.tableCell}
                >
                  Status
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600" }}
                  className={classes.tableCell}
                ></TableCell>
              </TableRow>
            </TableHead>
           {
            !loading && (
              <TableBody>
              {bookingData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <StyledTableRow
                    key={row._id}
                    className={classes.tableRow}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      className={classes.bodyCell}
                    >
                     <table>
                      <tbody>
                        <tr>
                          <td style={{fontWeight: "600", fontSize: "1rem"}}>{row.bookingId}</td>
                        </tr>
                        <tr>
                          <td>
                          {row.source === "WalkIn"  && <DirectionsWalkIcon color="primary" style={{fontSize: "1.2rem"}}/>}
                          {row.source === "Online"  && <LanguageIcon color="primary" style={{fontSize: "1.2rem"}}/>}
                          </td>
                        </tr>
                        </tbody>
                     </table>
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={classes.bodyCell}
                    >
                      <table>
                        <tbody>
                        <tr>
                          <td style={{ fontSize: "1rem"}}>{row.bookingDate.date}</td>
                        </tr>
                        <tr>
                          <td>{row.bookingDate.timeSlot}</td>
                        </tr>
                        </tbody>
                      </table>
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={classes.bodyCell}
                    >
                      <table>
                        <tbody>

                        <tr>
                          <td rowSpan={2} style={{ fontSize: "1rem"}}><AccountCircleIcon sx={{fontSize: "2.5rem", color: "#979797"}}/></td>
                          <td style={{ fontSize: "0.8rem", fontWeight: "600"}}>{row.contact.name}</td>
                        </tr>
                        <tr>
                          <td>{row.contact.phone}</td>
                        </tr>
                        </tbody>
                      </table>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.bodyCell}
                      style={{ fontSize: "1rem"}}
                    >
                      {row.covers}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.bodyCell}
                    >
                      <table>
                        <tbody>
                        <tr>
                          <td style={{ fontSize: "0.9rem"}}>{row.table.area}</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "0.8rem"}}>{row.table.table}</td>
                        </tr>
                        </tbody>
                      </table>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.bodyCell}
                    >
                      {row.type=="default" && <span style={{fontSize: "0.8rem", backgroundColor:"#e3e3e3", padding:"5px 10px", borderRadius: "10px"}}>{row.type.charAt(0).toUpperCase() + row.type.slice(1)}</span>}
                      {row.type=="event" && <span style={{fontSize: "0.8rem", backgroundColor:"#7d00d1", fontColor: "#fff", fontWeight: "600", padding:"5px 10px", borderRadius: "10px", color: "#fff"}}>{row.type.charAt(0).toUpperCase() + row.type.slice(1)}</span>}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.bodyCell}
                    >
                      {row.employee}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.bodyCell}
                    >
                      <span style={{
                          backgroundColor: statusColor[row.status],
                          color: "#fff",
                          fontSize: "0.8rem",
                          fontWeight: "600",
                          padding: "0.4rem 0.6rem",
                          borderRadius: "10px",
                        }}>
                        {row.status}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={classes.bodyCell}
                    >
                      <Link to={`/booking/${row.bookingId}`}  style={{ textDecoration: "none", color: "black" }}> 
                        <EditIcon />
                      </Link>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
            )
           }
          </Table>
        </TableContainer>
        {
            loading && (
              <TableLoader/>
            )
           }
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={bookingData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

          

export default BookingListTable;

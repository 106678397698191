import * as React from "react";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Link } from "react-router-dom";
import ShiftTimeLine from "./ShiftTimeLine";
import { Avatar, Chip, TextField, Typography, useTheme } from "@mui/material";
import { useEffect } from "react";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import TimelapseOutlined from "@mui/icons-material/TimelapseOutlined";


export default function ViewOnlyShiftBox(props) {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullData = { employeeDetail: props.employee, email: props.email };
    const [startvalue, setStartValue] = useState(new Date(props.shift.startDate));
    const [endvalue, setEndValue] = useState(
        !props.shift.endDate ? new Date() : new Date(props.shift.endDate)
    );
    const [breakTime, setBrakeTime] = useState(
        Array.isArray(props.shift.break) ? props.shift.break : []
    );
    const [shiftApproved, setShiftApproved] = useState(props.shift.approved);
    const [shiftLength, setShiftLength] = useState(props.shift.totalShiftLength);
    const [totalBreak, setTotalBreak] = useState(props.shift.totalBreak);
    const [shiftWOBreak, setShiftWOBreak] = useState(
        props.shift.shiftWithoutBreak
    );

    function convertS3ToCloudFront(url) {
        // Replace the AWS S3 domain with the CloudFront domain
        const cloudFrontDomain = 'https://d2k8ghk99lpcqm.cloudfront.net/';
        const s3Domain = 'https://cms-shifts.s3.ap-southeast-2.amazonaws.com/';
        
        // Check if the URL starts with the S3 domain
        if (url.startsWith(s3Domain)) {
          // Replace the S3 domain with the CloudFront domain
          const convertedUrl = url.replace(s3Domain, cloudFrontDomain);
          return convertedUrl;
        } else {
          // URL doesn't match the expected pattern
          console.error('Invalid URL format');
          return url;
        }
      }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const formatTime = (timestamp) => {
        const date = new Date(timestamp);
        return `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
    };

    const {
        startImage,
        endImage,
        startDate,
        endDate,
        shiftWithoutBreak,
        totalShiftLength,
        approved
    } = props.shift;



    const subtractTime = (time1, time2) => {
        //smaple 2023-08-11T08:54:19.659Z, 2023-08-11T09:31:42.968Z
        const date1 = new Date(time1);
        const date2 = new Date(time2);
        const diff = date2.getTime() - date1.getTime();
        //round to hh mm
        const hours = Math.floor(diff / (1000 * 60 * 60));
        const minutes = Math.floor((diff / (1000 * 60)) % 60);
        const seconds = Math.floor((diff / 1000) % 60);
        //return hh:mm
        return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
    };

    function calculateTotalBreak() {
        let hours = 0;
        let minutes = 0;
        let seconds = 0;
        breakTime.map((brk) => {
            let now = new Date(brk.start);
            let endDate = brk.end ? new Date(brk.end) : new Date();
            let diff = Math.abs(endDate - now);

            hours = hours + Math.floor(diff / 3.6e6);
            minutes = minutes + Math.floor((diff % 3.6e6) / 6e4);
            if (minutes > 59) {
                minutes = minutes - 60;
                hours = hours + 1;
            }
            seconds = seconds + Math.round(Math.floor((diff % 6e4) / 1000));
        });
        let total;
        if (hours < 10) {
            if (minutes < 10) {
                if (seconds < 10) {
                    total = "0" + hours + ":0" + minutes + ":0" + seconds;
                } else {
                    total = "0" + hours + ":0" + minutes + ":" + seconds;
                }
            } else {
                if (seconds < 10) {
                    total = "0" + hours + ":" + minutes + ":0" + seconds;
                } else {
                    total = "0" + hours + ":" + minutes + ":" + seconds;
                }
            }
        } else {
            if (minutes < 10) {
                if (seconds < 10) {
                    total = hours + ":0" + minutes + ":0" + seconds;
                } else {
                    total = hours + ":0" + minutes + ":" + seconds;
                }
            } else {
                if (seconds < 10) {
                    total = hours + ":" + minutes + ":0" + seconds;
                } else {
                    total = hours + ":" + minutes + ":" + seconds;
                }
            }
        }

        setTotalBreak(total);
    }

    function calculateTotalShiftLength() {
        let now = new Date(startvalue);
        let endDate = new Date(endvalue);
        let diff = Math.abs(endDate - now);

        let hours = Math.floor(diff / 3.6e6);
        let minutes = Math.floor((diff % 3.6e6) / 6e4);
        let seconds = Math.round(Math.floor((diff % 6e4) / 1000));
        let total;
        if (hours < 10) {
            if (minutes < 10) {
                if (seconds < 10) {
                    total = "0" + hours + ":0" + minutes + ":0" + seconds;
                } else {
                    total = "0" + hours + ":0" + minutes + ":" + seconds;
                }
            } else {
                if (seconds < 10) {
                    total = "0" + hours + ":" + minutes + ":0" + seconds;
                } else {
                    total = "0" + hours + ":" + minutes + ":" + seconds;
                }
            }
        } else {
            if (minutes < 10) {
                if (seconds < 10) {
                    total = hours + ":0" + minutes + ":0" + seconds;
                } else {
                    total = hours + ":0" + minutes + ":" + seconds;
                }
            } else {
                if (seconds < 10) {
                    total = hours + ":" + minutes + ":0" + seconds;
                } else {
                    total = hours + ":" + minutes + ":" + seconds;
                }
            }
        }

        setShiftLength(total);
    }

    const calculateshiftWithoutBreak = () => {
        let hours;
        let minutes;
        let seconds;

        const totalBreakHours = parseInt(totalBreak.slice(0, 2));
        const totalBreakMinutes = parseInt(totalBreak.slice(3, 5));
        const totalBreakSeconds = parseInt(totalBreak.slice(6, 8));

        const shiftLengthHours = parseInt(shiftLength.slice(0, 2));
        const shiftLengthMinutes = parseInt(shiftLength.slice(3, 5));
        const shiftLengthSeconds = parseInt(shiftLength.slice(6, 8));

        hours = shiftLengthHours - totalBreakHours;
        minutes = shiftLengthMinutes - totalBreakMinutes;
        seconds = shiftLengthSeconds - totalBreakSeconds;

        // Handle negative values
        if (seconds < 0) {
            minutes -= 1;
            seconds += 60;
        }
        if (minutes < 0) {
            hours -= 1;
            minutes += 60;
        }

        // Ensure that hours, minutes, and seconds are always two digits
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        const total = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
        setShiftWOBreak(total);
    }

    const getAvatarName = (employee) => {
        if (employee.employeeDetail) {
            if (employee.employeeDetail.fname && employee.employeeDetail.lname) {

                return employee.employeeDetail.fname.charAt(0).toUpperCase() + employee.employeeDetail.lname.charAt(0).toUpperCase();
            }
            else if (employee.employeeDetail.fname) {
                return employee.employeeDetail.fname.charAt(0).toUpperCase();
            }
            else if (employee.employeeDetail.lname) {
                return employee.employeeDetail.lname.charAt(0).toUpperCase();
            }
        }
        else {
            return employee.email.charAt(0).toUpperCase();
        }
    }

    useEffect(() => {
        calculateTotalBreak();
        calculateTotalShiftLength();
        calculateshiftWithoutBreak();
    }, [
        startvalue,
        endvalue,
        breakTime,
        totalBreak,
        shiftWOBreak,
        shiftLength,
        calculateshiftWithoutBreak,
    ]);


    return (
        <>
            {/* <Box
                sx={{
                    width: 180,
                    borderRadius: "5px",
                }}
                onClick={handleClickOpen}
                style={approved ? {
                    backgroundColor: "#81c78460",
                    border: "1px solid #81c784",
                    borderLeft: "5px solid #81c784",
                } : {
                    backgroundColor: "#d3e8f9",
                    border: "1px solid #2196f3",
                    borderLeft: "5px solid #2196f3",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "auto",
                        width: "150px",
                        padding: "6px",
                        paddingRight: "0",
                        lineHeight: "8px",
                    }}

                >
                    <Box
                        sx={{
                            textAlign: "right",
                            fontSize: "16px",
                            fontWeight: "500",
                            padding: "5px",
                            backgroundColor: "white",
                            borderRadius: "5px",
                            width: "100%",
                            height: "100%",
                        }}
                    >
                        {formatTime(startDate).slice(0, 5)} -{" "}
                        {endDate ? (
                            formatTime(endDate).slice(0, 5)
                        ) : (
                            <span style={{ fontWeight: "600" }}>Active</span>
                        )}
                    </Box>
                    <Box
                        sx={{
                            textAlign: "right",
                            fontSize: "0.9rem",
                        }}
                    >
                        <p>
                            Total Shift:{" "}
                            <span style={{ fontWeight: "600" }}>{shiftLength.slice(0, 5)}</span>
                        </p>
                        <p>
                            Break: <span style={{ fontWeight: "600" }}>{totalBreak.slice(0, 5)}</span>
                        </p>
                        <p>
                            Shift w/o break:{" "}
                            <span style={{ color: "#ee5622", fontWeight: "600" }}>{shiftWOBreak.slice(0, 5)} </span>
                        </p>
                    </Box>
                </Box>
            </Box> */}
            <Box sx={{ width: "180px", paddingBottom: "10px", borderRadius: "20px", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", textAlign: "center", cursor: "pointer", backgroundColor: theme.palette.card.bg, transition: "background-color 0.3s ease", margin: "auto", marginTop: "5px" }} onClick={handleClickOpen}>
                <Box sx={{
                    fontWeight: "500",
                    backgroundColor: shiftApproved ? theme.palette.success.main : theme.palette.button.blue,
                    color: shiftApproved ? theme.palette.text.secondary : theme.palette.text.primary, borderRadius: "10px", width: "90%", margin: "10px 2px 2px 2px", textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center"
                }}>
                    <Box sx={{ padding: "2px 0" }}>
                        {formatTime(startDate).slice(0, 5)} -{" "}
                        {endDate ? formatTime(endDate).slice(0, 5)
                            :
                            <span>Active</span>}
                    </Box>
                </Box>
                <Box sx={{
                    display: "flex", flexDirection: "row",
                    justifyContent: "space-between", alignItems: "center",
                    textAlign: "center",
                    fontSize: "1rem",
                    padding: "0",
                    fontWeight: "300",
                }}>
                    <Box sx={{ marginTop: "5px" }}>
                        Shift:{" "} <span>{shiftLength && shiftLength.slice(0, 5)}</span>
                    </Box>
                    <Box sx={{ marginRight: "5px", marginLeft: "5px" }}>
                        |
                    </Box>
                    <Box sx={{ marginTop: "5px" }}>
                        Break: <span>{totalBreak && totalBreak.slice(0, 5)}</span>
                    </Box>
                </Box>
                <Box sx={{ fontSize: "1rem", fontWeight: "300", marginTop: "5px" }} >
                    Shift w/o break:{" "}
                    <span
                        style={{ color: "#ee5622", fontWeight: "600" }}
                    >{shiftWOBreak && shiftWOBreak.slice(0, 5)} </span>
                </Box>
            </Box>
            <Dialog
                open={open}
                onClose={handleClose}
                style={{ borderRadius: "30px", width: "max-content", margin: "auto" }}
            >
                <DialogContent style={{ display: "flex", padding: "0", }}>
                    <div
                        style={{
                            padding: "20px",
                            backgroundColor: "#f3f3f3",
                            display: "flex",
                            flexDirection: "column",
                            flex: "1",
                            borderRadius: "20px",
                            position: "sticky",
                            top: "0",
                            zIndex: "100",
                            color: theme.palette.text.secondary,
                            boxShadow: "0px 0px 10px 0px #000000ff",

                        }}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>

                            <Avatar style={{
                                width: "5rem", height: "5rem", color: "#fff", fontWeight: "500", fontSize: "2rem",
                                backgroundColor: "#474d56", borderRadius: "20%", boxShadow: "0px 0px 5px 0px #1d1d1d74",
                                margin: "auto",
                            }}
                            >
                                {getAvatarName(fullData)}
                            </Avatar>
                            <Link
                                style={{ textDecoration: "none", color: "black", margin: "auto" }}
                                to={`/employee/${props.employeeId}`}

                            >

                                <div
                                    style={{
                                        fontSize: "1.1rem",
                                        fontWeight: "600",
                                        textAlign: "center",
                                        margin: "auto",
                                    }}
                                >
                                    <p
                                        style={{
                                            textAlign: "center",
                                            marginTop: "0",
                                        }}
                                    >
                                        {props.employee.title} <br />
                                        {props.employee.fname} {props.employee.lname}
                                    </p>

                                </div>
                            </Link>

                            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div style={{ display: "flex" }}>
                                        <AccessTimeIcon />
                                        <span style={{ fontWeight: "600", fontSize: "1rem" }}>
                                            {shiftLength.slice(0, 5)}
                                        </span>
                                    </div>

                                    <div>
                                        <span style={{ fontSize: "0.8rem", textAlign: "left", color: "#454545" }}>
                                            Shift length
                                        </span>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}>
                                    <div style={{ display: "flex" }}>
                                        <FormatListBulletedIcon />
                                        <span style={{ fontWeight: "600", fontSize: "1rem" }}>
                                            {totalBreak.slice(0, 5)}
                                        </span>
                                    </div>

                                    <div>
                                        <span style={{ fontSize: "0.8rem", textAlign: "left", color: "#454545" }}>
                                            Total Break
                                        </span>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}>
                                    <div style={{ display: "flex" }}>
                                        <TimelapseOutlined />
                                        <span
                                            style={{
                                                fontWeight: "600",
                                                fontSize: "1rem",
                                                color: "#ee5622",
                                            }}
                                        >
                                            {shiftWOBreak.slice(0, 5)}
                                        </span>
                                    </div>
                                    <div>
                                        <span style={{ fontSize: "0.8rem", textAlign: "left", color: "#454545" }}>
                                            Shift w/o break
                                        </span>
                                    </div>
                                </div>
                                <div style={{ marginTop: "1rem" }}>
                                    <span style={{ fontSize: "0.8rem", textAlign: "left", color: "#454545" }}>
                                        Shift ID: #{props.shift.id}
                                    </span>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div style={{ padding: "20px", flex: "4", paddingTop: "0" }}>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <h4 style={{ fontWeight: "400" }}>
                                Edit Shift on:{" "}
                                <span style={{ fontWeight: "600" }}>
                                    {new Date(props.shift.startDate).toString().slice(0, 15)}
                                </span>
                            </h4>
                            {!props.shift.endDate && <h4 style={{ color: "#ee5622" }}>Currently Active</h4>}
                        </div>

                        <Grid container spacing={2}>
                            <Grid item xs={5}>

                                <Typography variant="body1" style={{ fontWeight: "600" }}>
                                    Start Time: <span style={{ backgroundColor: "#ee5622", color: "white", fontWeight: "600", padding: "2px 5px", borderRadius: "5px" }}>
                                        {formatTime(props.shift.startDate).slice(0, 5)}
                                    </span>

                                </Typography>


                            </Grid>
                            <Grid item xs={5}>

                                <Typography variant="body1" style={{ fontWeight: "600" }}>

                                    End Time: <span style={{ backgroundColor: "#ee5622", color: "white", fontWeight: "600", padding: "2px 5px", borderRadius: "5px" }}>
                                        {props.shift.endDate ? formatTime(props.shift.endDate).slice(0, 5) : "Active"}
                                    </span>
                                </Typography>

                            </Grid>
                            <Grid item xs={5}>
                                <img
                                    // src={props.shift.startImage}
                                    src={props.shift.startImage && convertS3ToCloudFront(props.shift.startImage)}
                                    alt="Start Shift Image"
                                    style={{
                                        width: "100%",
                                        marginTop: "8px",
                                        borderRadius: "5px",
                                    }}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <img
                                    // src={props.shift.endImage}
                                    src={props.shift.endImage && convertS3ToCloudFront(props.shift.endImage)}
                                    alt="End Shift Image"
                                    style={{
                                        width: "100%",
                                        marginTop: "8px",
                                        borderRadius: "5px",
                                    }}
                                />
                            </Grid>
                        </Grid>



                        <Typography variant="h6" style={{ fontWeight: "600", marginTop: "10px" }}>
                            Breaks
                        </Typography>
                        {props.shift.break === null
                            ||
                            props.shift.break.length === 0

                            ? (
                                <div>No breaks Found.</div>
                            ) : (
                                props.shift.break.map((brk, index) => (
                                    <Grid container spacing={2} key={brk.start}>

                                        <Grid item xs={10} sx={{ margin: "2px" }}>
                                            <Typography variant="body1" >
                                                Break {index + 1}: <span style={{ backgroundColor: "#ee5622", color: "white", fontWeight: "600", padding: "2px 5px", borderRadius: "5px" }}>
                                                    {formatTime(brk.start).slice(0, 5) + " to " + formatTime(brk.end).slice(0, 5) + " = " + subtractTime(brk.start, brk.end)}
                                                </span>

                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ))
                            )}




                        <ShiftTimeLine shiftTimelines={props.shift.shiftTimelines} />

                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}

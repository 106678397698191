import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Avatar, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DateTimePicker from "@mui/lab/DateTimePicker";
// import TimePicker from "@mui/lab/TimePicker";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import { fontWeight, textAlign } from "@mui/system";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useSelector } from "react-redux";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
// import TimePicker from "@mui/lab/TimePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Checkbox from "@mui/material/Checkbox";
import DoneIcon from "@mui/icons-material/Done";

const CreateShift = (props) => {
  const [open, setOpen] = React.useState(false);
  const [startvalue, setStartValue] = React.useState(new Date());
  const [endvalue, setEndValue] = React.useState(new Date());
  const [starttime, setStartTime] = React.useState(null);
  const [endtime, setEndTime] = React.useState(null);
  const [breakTime, setBrakeTime] = React.useState([]);
  const [employees, setEmployees] = useState([]);
  const [employee, setEmployee] = useState({email:"NA",name:"NA",id:"NA"});
  const [shiftApproved, setShiftApproved] = useState(false);
  const [shiftLength, setShiftLength] = useState("00:00:00");
  const [totalBreak, setTotalBreak] = useState("00:00:00");
  const [shiftWOBreak, setShiftWOBreak] = useState("00:00:00");

  const [avatar, setAvatar] = useState('/broken-image.jpg');
  // getting logged in user info
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
    },
  };
  async function fetchEmployees() {
    // const { data } = await axios.get(
    //   `${process.env.REACT_APP_API_BACKEND}/api/employees`,
    //   config
    // );
    // data.employees.filter((employee) => {
    //   return employee.flag.flag === "Active";
    // }).map((employee) => {
    //   return setEmployees((employees) => [...employees, employee]); // adding employees to the array
    // }, []);
    axios.get(`${process.env.REACT_APP_API_BACKEND}/api/employees`,config)
    .then((res)=>{
      // res.data.employees.filter((employee) => {
      //   return employee.flag.flag === "Active"&& employee.employeeDetail.fname!==null;
      // }).map((employee) => {
      //  //a2z

      // }, []);
      const filteredEmployees = res.data.employees.filter((employee) => {
        return employee.flag.flag === "Active"&& employee.employeeDetail.fname!==null;
      }
      );
      filteredEmployees.sort((a, b) => {
        if (a.employeeDetail.fname < b.employeeDetail.fname) {
          return -1;
        }
        if (a.employeeDetail.fname > b.employeeDetail.fname) {
          return 1;
        }
        return 0;
      }
      );
      setEmployees(filteredEmployees);
    })
    .catch((err)=>{
      console.log(err.response.data.message);
    })


  }
  useEffect(() => {
    setEmployees([]);
    fetchEmployees();
    if(!open){
      setAvatar('/broken-image.jpg');
      setStartValue(new Date());
      setEndValue(new Date());
      setBrakeTime([]);
      setEmployee({email:"NA",name:"NA",id:"NA"});
      setShiftLength("00:00:00");
      setTotalBreak("00:00:00");
      setShiftWOBreak("00:00:00");

    }
  } , [open]);
  useEffect(() => {
    calculateTotalBreak();
    calculateTotalShiftLength();
    calculateshiftWithoutBreak();
    // setShiftWOBreak("00:00:00");
  }, [ 
    startvalue,
    endvalue,
    breakTime,
    totalBreak,
    shiftWOBreak,
    shiftLength,
    calculateshiftWithoutBreak,
  ]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  // function to add break in the break array
  const addBreak = () => {
    const newArray = [...breakTime];
    newArray.push({ start: new Date(), end: new Date() });
    setBrakeTime(newArray);
    newArray.map((i) => {
      setTotalBreak(totalBreak + calculateBreakLength(i.start, i.end));
    });

  };

  // function to update the start time for particular break in the break array
  const updateStartBreak = (value, index) => {
    const newArray = [...breakTime];
    newArray[index].start = value;
    // setBrakeTime(newArray);
   
  };

  const getAvatarName = (employee) => {
    // if (employee.employeeDetail) {
    //   if (employee.employeeDetail.fname && employee.employeeDetail.lname) {

    //     return employee.employeeDetail.fname.charAt(0).toUpperCase() + employee.employeeDetail.lname.charAt(0).toUpperCase();
    //   }
    //   else if (employee.employeeDetail.fname) {
    //     return employee.employeeDetail.fname.charAt(0).toUpperCase();
    //   }
    //   else if (employee.employeeDetail.lname) {
    //     return employee.employeeDetail.lname.charAt(0).toUpperCase();
    //   }
    // }
    // else {
    //   return employee.email.charAt(0).toUpperCase();
    // }
    if (employee.name!=="NA") {
      //if split by space is not empty
      if (employee.name.split(" ").length > 1) {
        return employee.name.split(" ")[0].charAt(0).toUpperCase() + employee.name.split(" ")[1].charAt(0).toUpperCase();
      }
      else {
        return employee.name.charAt(0).toUpperCase();
      }

    }
    else if (employee.email!=="NA") {
      if (employee.email.split("@")[0].split(".").length > 1) {
        return employee.email.split("@")[0].split(".")[0].charAt(0).toUpperCase() + employee.email.split("@")[0].split(".")[1].charAt(0).toUpperCase();
      }
      else {
        return employee.email.split("@")[0].charAt(0).toUpperCase();
      }
    }
    else {
      return "NA";
    }



  }
  // function to update the end time for particular break in the break array
  const updateEndBreak = (value, index) => {
    const newArray = [...breakTime];
    newArray[index]["end"] = value;
    // setBrakeTime(newArray);

  };

  // function to remove break from the break array
  const removeBreak = (index) => {
    const newArray = [...breakTime];
    newArray.splice(index, 1);
    setBrakeTime(newArray);
  };
  // function to format dates for making the usable
  const formatTime = (date) => {
    const d = new Date(date);
    return d.toLocaleTimeString("en-GB");
  };

   // function to calculate the total break
   function calculateBreakLength(start, end) {
    let now = new Date(start);
    let endDate = new Date(end);
    let diff = Math.abs(endDate - now);

    let hours = Math.floor(diff / 3.6e6);
    let minutes = Math.floor((diff % 3.6e6) / 6e4);
    let total;
    if (hours < 10) {
      if (minutes < 10) {
        total = "0" + hours + ":0" + minutes;
      } else {
        total = "0" + hours + ":" + minutes;
      }
    } else {
      if (minutes < 10) {
        total = hours + ":0" + minutes;
      } else {
        total = hours + ":" + minutes;
      }
    }
    return total;
  }
  function calculateTotalBreak() {
    let hours = 0;
    let minutes = 0;
    let seconds = 0;
    breakTime.map((brk) => {
      let now = new Date(brk.start);
      let endDate = brk.end ? new Date(brk.end) : new Date();
      let diff = Math.abs(endDate - now);

      hours = hours + Math.floor(diff / 3.6e6);
      minutes = minutes + Math.floor((diff % 3.6e6) / 6e4);
      if (minutes > 59) {
        minutes = minutes - 60;
        hours = hours + 1;
      }
      seconds = seconds + Math.round(Math.floor((diff % 6e4) / 1000));
    });
    let total;
    if (hours < 10) {
      if (minutes < 10) {
        if (seconds < 10) {
          total = "0" + hours + ":0" + minutes + ":0" + seconds;
        } else {
          total = "0" + hours + ":0" + minutes + ":" + seconds;
        }
      } else {
        if (seconds < 10) {
          total = "0" + hours + ":" + minutes + ":0" + seconds;
        } else {
          total = "0" + hours + ":" + minutes + ":" + seconds;
        }
      }
    } else {
      if (minutes < 10) {
        if (seconds < 10) {
          total =  hours + ":0" + minutes + ":0" + seconds;
        } else {
          total =  hours + ":0" + minutes + ":" + seconds;
        }
      } else {
        if (seconds < 10) {
          total =  hours + ":" + minutes + ":0" + seconds;
        } else {
          total =  hours + ":" + minutes + ":" + seconds;
        }
      }
    }

    setTotalBreak(total);
  }
  const handelEmployee =(e)=>{
    setEmployee(e.target.value);
  }
  const handleShiftApproved = () => {
    if (shiftApproved == true) {
      setShiftApproved(false);
    } else {
      setShiftApproved(true);
    }
  };
  function calculateTotalShiftLength() {
    let now = new Date(startvalue);
    let endDate = new Date(endvalue);
    let diff = Math.abs(endDate - now);

    let hours = Math.floor(diff / 3.6e6);
    let minutes = Math.floor((diff % 3.6e6) / 6e4);
    let seconds = Math.round(Math.floor((diff % 6e4) / 1000));
    let total;
    if (hours < 10) {
      if (minutes < 10) {
        if (seconds < 10) {
          total = "0" + hours + ":0" + minutes + ":0" + seconds;
        } else {
          total = "0" + hours + ":0" + minutes + ":" + seconds;
        }
      } else {
        if (seconds < 10) {
          total = "0" + hours + ":" + minutes + ":0" + seconds;
        } else {
          total = "0" + hours + ":" + minutes + ":" + seconds;
        }
      }
    } else {
      if (minutes < 10) {
        if (seconds < 10) {
          total =  hours + ":0" + minutes + ":0" + seconds;
        } else {
          total =  hours + ":0" + minutes + ":" + seconds;
        }
      } else {
        if (seconds < 10) {
          total =  hours + ":" + minutes + ":0" + seconds;
        } else {
          total =  hours + ":" + minutes + ":" + seconds;
        }
      }
    }

    setShiftLength(total);
  }
  
  const theme = useTheme();

  function calculateshiftWithoutBreak() {
    let hours;
    let minutes;
    let seconds;

    // setTotalBreak(calculateBreakLength(brk.start, brk.end))
    let totalBreakHours = totalBreak.slice(0, 2)=="00" && totalBreak.slice(3,5)!="00"?"01":totalBreak.slice(0, 2);
    let shiftLengthMinutes=parseInt(shiftLength.slice(3, 5))<30 && totalBreak.slice(3,5)!="00" ?(Math.abs("60"-totalBreak.slice(3, 5))+parseInt(shiftLength.slice(3, 5))):(Math.abs(shiftLength.slice(3, 5)-totalBreak.slice(3, 5)));
    hours = Math.abs(shiftLength.slice(0, 2) - totalBreakHours);
    minutes = shiftLengthMinutes;
    seconds = Math.abs(shiftLength.slice(6, 8) - totalBreak.slice(6, 8));
    let total;
    if (hours < 10) {
      if (minutes < 10) {
        total = "0" + hours + ":0" + minutes + ":" + seconds;
      } else {
        total = "0" + hours + ":" + minutes + ":" + seconds;
      }
    } else {
      if (minutes < 10) {
        total = hours + ":0" + minutes + ":" + seconds;
      } else {
        total = hours + ":" + minutes + ":" + seconds;
      }
    }
    setShiftWOBreak(total);
  }

  
  // submit handler for sending an update request
  const submitHandler = () => {
   if(employee.id=="NA"){
    alert("Please select an employee");
   }
    const data = {
      startDate: startvalue,
      endDate: endvalue,
      break: breakTime,
      approved: shiftApproved,
      totalShiftLength: shiftLength,
      totalBreak: totalBreak,
      shiftWithoutBreak: shiftWOBreak,
    };
    const config = {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
      },
    };
    //convert data to json
    const jsonData = JSON.stringify(data);

    axios
      .post(
        `${process.env.REACT_APP_API_BACKEND}/api/employee/addshift/${employee.id}`,
        {
          startDate: startvalue,
          endDate: endvalue,
          startTime: startvalue,
          endDTime: endvalue,
          break: breakTime,
          approved: shiftApproved,
          totalShiftLength: shiftLength,
          totalBreak: totalBreak,
          shiftWithoutBreak: shiftWOBreak,
        },
        config
      )
      .then((res) => {
        // setShifts(res.data);
        setOpen(false);
        props.setSuccess(res.data.message);
        props.fetchShifts();
        // window.location.reload(false);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        props.setError(err.response.data.message);
      });
  };

    return (
        <div>
        <Button
            variant="contained"
            style={{ textTransform: "none", fontWeight:"500" }}
            startIcon={<AddIcon />}
            onClick={handleClickOpen}
        >
            Create Shift
        </Button>
        <Dialog
        open={open}
        onClose={handleClose}
        style={{ borderRadius: "30px", width: "max-content", margin: "auto" }}
      >
       <DialogContent style={{ display: "flex", padding: "0", }}>
       <div
            style={{
              padding: "20px",
              backgroundColor: "#f3f3f3",
              display: "flex",
              flexDirection: "column",
              flex: "1",
              borderRadius: "20px",
              position: "sticky",
              top: "0",
              zIndex: "100",
              color: theme.palette.text.secondary,
              boxShadow: "0px 0px 10px 0px #000000ff",
            }}>
            <div style={{margin:"0 auto"}}>
            <Avatar style={{
                width: "5rem", height: "5rem", color: "#fff", fontWeight: "500", fontSize: "2rem",
                backgroundColor: "#474d56", borderRadius: "20%", boxShadow: "0px 0px 5px 0px #1d1d1d74",
                margin: "auto",
              }}
              >
                {getAvatarName(employee)}
              </Avatar>
              <div
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    textAlign: "center",
                    margin: "auto",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      marginTop: "0",
                      marginBottom: "5px",
                    }}
                  >
            {employee.name}
            </p>
            </div>
            </div>
           
            <Grid container spacing={2}>
              <Grid item xs={3} md={3}>
                <AccessTimeIcon />
              </Grid>
              <Grid item xs={9} md={9}>
                <span style={{ fontWeight: "600", textAlign: "center" }}>
                  {shiftLength.slice(0, 5)}
                </span>
                <br />
                <span style={{ fontSize: "0.8rem", textAlign: "center" }}>
                  Shift length
                </span>
                <br />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3} md={3}></Grid>
              <Grid item xs={9} md={9}>
                <span style={{ fontWeight: "600", textAlign: "center" }}>
                  {totalBreak.slice(0, 5)}
                </span>
                <br />
                <span style={{ fontSize: "0.8rem", textAlign: "center" }}>
                  Total Break
                </span>
                <br />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3} md={3}></Grid>
              <Grid item xs={9} md={9}>
                <span
                  style={{
                    fontWeight: "600",
                    textAlign: "center",
                    color: "#ee5622",
                  }}
                >
                  {shiftWOBreak.slice(0, 5)}
                </span>
                <br />
                <span style={{ fontSize: "0.8rem", textAlign: "center" }}>
                  Shift w/o break
                </span>
                <br />
              </Grid>
            </Grid>
          </div>
          <div style={{ padding: "20px", fontSize: "20px"}}>
            <Typography variant="subtitle1">Create Shift</Typography>
            <div style={{marginBottom: "20px"}}>
                <Typography variant="caption">Employee*</Typography>
                <FormControl fullWidth size="small">
                <Select
                    displayEmpty
                    value= {employee}
                    onChange={handelEmployee}
                    MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
                >
                  <MenuItem value={employee} >{employee.name}</MenuItem>
                {employees.map((employees, index) => (
                <MenuItem key={index} value={{email:employees.email,name:`${employees.employeeDetail.fname} ${employees.employeeDetail.lname}`,id:employees.id}}>{employees.employeeDetail.fname} {employees.employeeDetail.lname}</MenuItem>
            ))}
        </Select>
      </FormControl>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDateTimePicker
                   sx={{ '& label': { color: theme.palette.text.primary } }}
                    ampm={false}
                    label="Start *"
                    value={startvalue}
                    onChange={(newValue) => {
                      setStartValue(newValue);
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDateTimePicker
                     sx={{ '& label': { color: theme.palette.text.primary } }}
                    ampm={false}
                    label="End *"
                    value={endvalue}
                    onChange={(newValue) => {
                      setEndValue(newValue);
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <p>Breaks</p>
            {breakTime =="" ? (
              <div>No breaks Found.</div>
            ) : (
              breakTime.map((brk, index) => (
                <Grid container spacing={2} key={index} style={{marginTop: '0.1rem'}}>
                  <Grid item xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="From"
                        defaultValue={new Date()} 
                        value={new Date(brk.start)}
                        ampm={false}
                        slotProps={{ textField: { size: 'small' } }}
                        sx={{ '& label': { color: theme.palette.text.primary } }}
                        onChange={(newValue) => {
                          updateStartBreak(newValue, index);
                          calculateTotalBreak();
                          calculateshiftWithoutBreak();
                        }}
                        />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                        label="To"
                        defaultValue={new Date()}
                        value={new Date(brk.end)}
                        ampm={false}
                        slotProps={{ textField: { size: 'small' } }}
                        sx={{ '& label': { color: theme.palette.text.primary } }}
                        onChange={(newValue) => {
                          updateEndBreak(newValue, index);
                          calculateTotalBreak();
                          calculateshiftWithoutBreak();
                        }}
                      
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={2} style={{paddingTop: "10px", 
                  display: "flex", 
                  justifyContent: "space-between", 
                  width: "100%",
                  alignItems: "center"}}>
                    <div style={{ fontWeight: "500", marginRight: "5px" }}>
                      {calculateBreakLength(brk.start, brk.end ? brk.end : new Date()).slice(1)}
                    </div>
                    
                        <RemoveCircleOutlineIcon sx={{ color: "red", fontSize: "1.5rem" }} style={{ cursor: "pointer" }} onClick={() => removeBreak(index)} />
                  
                  </Grid>
                </Grid>
              ))
            )}
            <Button
              variant="contained"
              style={{ marginTop: "1rem" }}
              onClick={addBreak}
            >
              Add Break
            </Button>
            {
              userInfo.isAdmin &&
                <div style={{ marginTop: "1rem" }}>
              <Checkbox
                checked={shiftApproved}
                sx={{ paddingLeft: "0" }}
                onChange={handleShiftApproved}
              />{" "}
              Shift Approved
            </div>}
            <div
              style={{
                marginTop: "1rem",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Button
                  style={{
                    marginLeft: "1rem",
                    textTransform: "capitalize",
                    backgroundColor: "#e0e0e0",
                    color: "#000",
                    fontWeight: "500",
                  }}
                  onClick={() => handleClose()}
                  variant="contained"
                >
                  {" "}
                  Close
                </Button>
              </div>
              <div>
                <Button
                  variant="contained"
                  style={{ textTransform: "capitalize" }}
                  startIcon={<DoneIcon />}
                  onClick={submitHandler}
                >
                  Create
                </Button>
              </div>
            </div>
          </div>
          
        </DialogContent>
      </Dialog>
        </div>   
    )
}

export default CreateShift;
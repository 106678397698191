import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { withStyles, styled } from "@material-ui/styles";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

// custom style for input text field
const styles = (theme) => ({
  input: {
    height: 40,
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
  },
});

const AddLead = withStyles(styles)((props) => {
  // getting logged in user info
  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;
  const { classes } = props;

  // state for handling step count
  const [activeStep, setActiveStep] = useState(0);
  const history = useHistory();
  const location = useLocation();

  // function to go to next step
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // function to go to previous step
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  //function to reste all the step count
  const handleReset = () => {
    setActiveStep(0);
  };

  //go back function to route to the previous page when the button is clicked
  const goBack = () => {
    history.goBack();
  };

  return (
    <div>
      <Button variant="contained" onClick={goBack}>
        Go Back
      </Button>{" "}
      <br />
      <div style={{ fontSize: "1.3rem", marginTop: "1rem" }}>Add lead</div>
      <Box
        sx={{ maxWidth: "100%" }}
        style={{
          margin: "1.5rem 0.5rem 2.5rem 0.5rem",
          backgroundColor: "white",
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        }}
      >
        <form>
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            style={{ padding: "1rem 2rem" }}
          >
            <Step>
              <StepLabel>
                <span style={{ fontSize: "1.2rem" }}>Lead information</span>
              </StepLabel>
              <StepContent>
                <Grid container spacing={2} style={{ margin: "1.2rem 0" }}>
                  <Grid item xs={12} md={12}>
                    <div>Lead title</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Type lead title..."
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value=""
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Where is the lead from?</div>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Age"
                      fullWidth
                      input={<OutlinedInput />}
                      size="small"
                      value="Website"
                    >
                      <MenuItem value={"Website"}>Website</MenuItem>
                      <MenuItem value={"Marketing Campaign"}>
                        Marketing Campaign
                      </MenuItem>
                      <MenuItem value={"Phone"}>Phone</MenuItem>
                      <MenuItem value={"Walk-in"}>Walk-in</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Lead type</div>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Age"
                      fullWidth
                      input={<OutlinedInput />}
                      size="small"
                      value="Default"
                    >
                      <MenuItem value={"Default"}>Default</MenuItem>
                      <MenuItem value={"Feedback"}>Feedback</MenuItem>
                      <MenuItem value={"General"}>General</MenuItem>
                    </Select>
                  </Grid>
                </Grid>

                <Box sx={{ mb: 2 }}>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Next
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>
                <span style={{ fontSize: "1.2rem" }}>
                  {" "}
                  Lead primary contact
                </span>
              </StepLabel>
              <StepContent>
                <Grid container spacing={2} style={{ margin: "1.2rem 0" }}>
                  <Grid item xs={12} md={12}>
                    <div>Type contact name</div>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value=""
                      label="Type contact name"
                      placeholder="Type contact name"
                      fullWidth
                      input={<OutlinedInput />}
                      size="small"
                    >
                      <MenuItem value={"Single"}>Single</MenuItem>
                      <MenuItem value={"Married"}>Married</MenuItem>
                      <MenuItem value={"Divorced"}>Divorced</MenuItem>
                      <MenuItem value={"Defacto"}>Defacto</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    Can't find the contact?{" "}
                    <Button
                      variant="contained"
                      style={{ textTransform: "none", fontWeight: "500" }}
                      startIcon={<AddIcon />}
                    >
                      Add Contact
                    </Button>
                  </Grid>
                </Grid>

                <Box sx={{ mb: 2 }}>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Next
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>
                <span style={{ fontSize: "1.2rem" }}>Description</span>
              </StepLabel>
              <StepContent>
                <Grid container spacing={2} style={{ margin: "1.2rem 0" }}>
                  <Grid item xs={12} md={12}>
                    <div>Description</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Type any extra information"
                      multiline
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value=""
                    />
                  </Grid>
                </Grid>

                <Box sx={{ mb: 2 }}>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Finalise
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          </Stepper>
        </form>
        {/* {activeStep === 3 && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )} */}
      </Box>
    </div>
  );
});

export default AddLead;

// <Box sx={{ maxWidth: "100%" }}>
// <Stepper activeStep={activeStep} orientation="vertical">
//   {steps.map((step, index) => (
//     <Step key={step.label}>
//       <StepLabel
//         optional={
//           index === 3 ? (
//             <Typography variant="caption">Last step</Typography>
//           ) : null
//         }
//       >
//         {step.label}
//       </StepLabel>
//       <StepContent>
//         <Typography>{step.description}</Typography>
//         <Box sx={{ mb: 2 }}>
//           <div>
//             <Button
//               disabled={index === 0}
//               onClick={handleBack}
//               sx={{ mt: 1, mr: 1 }}
//             >
//               Back
//             </Button>
//             <Button
//               variant="contained"
//               onClick={handleNext}
//               sx={{ mt: 1, mr: 1 }}
//             >
//               {index === steps.length - 1 ? "Finish" : "Next"}
//             </Button>
//           </div>
//         </Box>
//       </StepContent>
//     </Step>
//   ))}
// </Stepper>
// {activeStep === steps.length && (
//   <Paper square elevation={0} sx={{ p: 3 }}>
//     <Typography>All steps completed - you&apos;re finished</Typography>
//     <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
//       Reset
//     </Button>
//   </Paper>
// )}
// </Box>

import { createTheme } from "@mui/material";



const newTheme = createTheme({
    palette: {
      mode: 'dark',
        primary: {
        // main: "#4effca"
        main: "#81f7ff"
        },
        secondary: {
        main: "#000000",
        },
        background: {
        default: "#363b43",
        paper: "#000000ea",
        alert: "#e15454",
        },
        
        text: {
        primary: "#ffffff",
        secondary: "#000000",
        },
        card: {
            bg: "#292a2c",
            text: "#ffffff",
            alt: "#474d56",
        },
        dialog: {
        primary: "#ffffff",
        secondary: "#000000",
        paper: "#ffffff",
        bg : "#292a2c",
        },
        button: {
        blue: "#4e95ff",
        red: "#e15454",
        grey: "#d6d6d6",
        green: "#4effca",
        },
        success: {
        main: "#4FFFB0",
        },
        error: {
        main: "#e15454",
        },
        

        
    },
    components: {
      MuiDialog: {
        styleOverrides: {
          paper: {
            backgroundColor: '#292a2c',
            color: '#ffffff',
          },
        },
      },
      
      MuiDayCalendar: {
        styleOverrides: {
          root: {
            color: '#bbdefb',
            borderRadius: 0,
            borderWidth: 0,
            borderColor: '#2196f3',
            border: '0px solid',
          },
          weekNumber: {
            color: '#bbdefb !important',
          },
          weekNumberLabel: {
            color: '#bbdefb !important',
          },
          weekDayLabel: {
            color: '#bbdefb !important',
          },
          //arrow MuiSvgIcon-root 
          switchHeader: {
            color: '#bbdefb !important',
          },

          //toolbar
          // MuiPickersToolbar-root

        },
      },
      //MuiPickersToolbar-root
      MuiPickersToolbar: {
        styleOverrides: {
          root: {
            color: '#bbdefb !important',
          },
          

        },
      },

     
      MuiPickersCalendarHeader: {
        styleOverrides: {
          switchViewIcon: {
            color: '#bbdefb  !important',
          },
        },
      },
      MuiPickersArrowSwitcher: {
        styleOverrides: {
          root: {
            color: '#bbdefb !important',
            '& .MuiSvgIcon-root': {
              color: '#bbdefb !important',
            },
          },

        },
      },

    },
    typography: {
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
      },


    });


export default newTheme;


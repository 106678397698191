import React, { useEffect } from 'react';
import { useState } from 'react';
import './UDM.css';

const UnderDevelopmentMessage = (props) => {
    //show this message as popup  
    const {dumData, msg, goBack } = props;
    const [visible, setVisible] = useState(true);
    const [message, setMessage] = useState("This feature is under development. Please check back later.");
    
    useEffect(() => {
        if(msg){
            setMessage(msg);
        }
    }, [msg])

   

    return (

        visible && <div className='udm-back'>
            <div className='udm-msg'>
                <h1>{message}</h1>
                {dumData && <p onClick={()=>{setVisible(false)}} style={{textDecoration: "underline", cursor: "pointer"}}>For Dummy Data preview Click here.</p>}
                {goBack && <p onClick={()=>{window.history.back()}} style={{textDecoration: "underline", cursor: "pointer"}}>Go Back</p>}
            </div>
        </div>
    
    );
}

export default UnderDevelopmentMessage;
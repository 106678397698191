import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import SettingsIcon from "@mui/icons-material/Settings";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { withStyles, makeStyles } from "@material-ui/styles";
import TablePagination from "@mui/material/TablePagination";
import { Box } from "@mui/material";

// custom style for table

const tableStyles = makeStyles({
  tableRow: {
    height: 20,
  },
  tableCell: {
    padding: "10px 16px",
  },
  bodyCell: {
    padding: "6px 16px",
  },
});
const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 20,
  },
}))(TableRow);
const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "10px 5px",
  },
}))(TableCell);

// custom style for input text field
const styles = (theme) => ({
  input: {
    height: 40,
    paddingRight: 0,
  },
});

const SalesCustomers = withStyles(styles)((props) => {
  const tableclasses = tableStyles();
  const { classes } = props;
  const [dob, setDob] = useState("");
  // state for pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // function to handle pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // function to handle the number of rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <div
        className="header"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {" "}
        <div className="left-container" style={{ paddingTop: "3.5rem" }}>
          <span>Search by name, entity name, suburb</span>
          <br />
          <TextField
            id="outlined-basic"
            style={{ width: "450px" }}
            value={""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: "0 5px 5px 0",
                      backgroundColor: "#1a237e",
                      textTransform: "capitalize",
                      fontWeight: "600",
                      height: "2.5rem",
                    }}
                  >
                    Search
                  </Button>
                </InputAdornment>
              ),
              className: classes.input,
            }}
            variant="outlined"
          />
        </div>
        <div className="right-container" style={{ marginLeft: "1rem" }}>
          {/* <Button variant="contained" style={{backgroundColor:"#1a237e",textTransform:"capitalize", fontWeight:"600"}}><AddIcon/> Invite employee</Button> */}
          <Stack spacing={1} direction="row" justifyContent="right">
            <Link to="/crm/contact/create">
              <Button
                variant="contained"
                style={{ textTransform: "none", fontWeight: "500" }}
                startIcon={<AddIcon />}
              >
                Add Customer
              </Button>
            </Link>
          </Stack>

          <Stack spacing={1} direction="row" justifyContent="right">
            <div>
              <div style={{ marginTop: "1.2rem" }}>Type</div>
              <FormControl fullWidth size="small">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value="All"
                  style={{ width: "12rem" }}
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  <MenuItem value={"Active"}>General</MenuItem>
                  <MenuItem value={"Inactive"}>Retail</MenuItem>
                  <MenuItem value={"Onboarding"}>Customer</MenuItem>
                  <MenuItem value={"Onboarding"}>Supplier</MenuItem>
                  <MenuItem value={"Onboarding"}>Wholesale</MenuItem>
                  <MenuItem value={"Onboarding"}>Restaurants</MenuItem>
                  <MenuItem value={"Onboarding"}>Online</MenuItem>
                  <MenuItem value={"Onboarding"}>Distributor</MenuItem>
                </Select>
              </FormControl>
            </div>
          </Stack>
        </div>
      </div>
      <div>
        <Paper
          sx={{
            marginTop: "1rem",
            boxShadow:
              "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
          }}
        >
          <TableContainer sx={{ width: "100%", overflowX: "hidden" }}>
            <Table sx={{ minWidth: "90vw" }} aria-label="simple table">
              <TableHead>
                <TableRow className={tableclasses.tableRow}>
                  <TableCell
                    align="left"
                    style={{ fontWeight: "600" }}
                    className={tableclasses.tableCell}
                  >
                    Company name
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ fontWeight: "600" }}
                    className={tableclasses.tableCell}
                  >
                    Suburb
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ fontWeight: "600" }}
                    className={tableclasses.tableCell}
                  >
                    Contact
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ fontWeight: "600" }}
                    className={tableclasses.tableCell}
                  >
                    Type
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ fontWeight: "600" }}
                    className={tableclasses.tableCell}
                  >
                    In dispute
                  </TableCell>

                  <TableCell
                    align="left"
                    style={{ fontWeight: "600" }}
                    className={tableclasses.tableCell}
                  ></TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
});

export default SalesCustomers;

import React from "react";
import ReturnsHeader from "../components/Purchase Orders/Returns/ReturnsHeader";
import ReturnsTable from "../components/Purchase Orders/Returns/ReturnsTable";

function Returns() {
  return (
    <div style={{ width: "1500px" }}>
      <ReturnsHeader />
      <ReturnsTable />
    </div>
  );
}

export default Returns;

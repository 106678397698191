import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Button, CardActionArea, CardActions, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import logo from "../crinitis-logo.png";
import { useLocation, useHistory, Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import InputAdornment from "@mui/material/InputAdornment";
import LockIcon from "@mui/icons-material/Lock";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions/userActions";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorAlert from "../components/ErrorAlert";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import axios from "axios";

export default function Login() {
  const [value, setValue] = useState("/login");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const location = useLocation();
  const history = useHistory();
  const redirectData = sessionStorage.getItem("redirectData") ? JSON.parse(sessionStorage.getItem("redirectData")) : null;
  const path = redirectData ? redirectData.path : '/';
  // getting logged in user info
  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;
  const dispatch = useDispatch();
  const redirect = location.search ? location.search.split("=")[1] : "/";
  useEffect(() => {
    if (userInfo && userInfo.JWT_TOKEN) {
      if (redirectData) {
        sessionStorage.removeItem("redirectData");
        if(userInfo.currentBranchId != redirectData.branchId){
          switchBranch(redirectData.branchId);
        }else
          history.push(redirectData.path);
      } else{
        history.push('/');
      }
    }
  }, [history, userInfo, redirect]);

  const switchBranch = (branch) => {
    window.scrollTo(0, 0);
    const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
        },
      };
    axios
      .get(
        `${process.env.REACT_APP_API_BACKEND}/api/branch/switch/${branch}`,
        config
      )
      .then((res) => {
        localStorage.setItem("userInfo", JSON.stringify(res.data));
        setTimeout(() => {
            //go to the path with window.location instead of navigate.push
            window.location.href = path;
        }
        , 2000);
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };


  const submitHandler = (e) => {
    e.preventDefault();
    if (!email) {
      setErrorMessage("Please provide an email");
      if (!show) {
        setShow(true);
        setTimeout(() => {
          setShow(false);
          setErrorMessage("");
        }, 5000);
      }
    } else if (!password) {
      setErrorMessage("Please provide a password");
      if (!show) {
        setShow(true);
        setTimeout(() => {
          setShow(false);
          setErrorMessage("");
        }, 5000);
      }
    } else {
      dispatch(login(email.toLowerCase(), password));
      if (!show) {
        setShow(true);
        setTimeout(() => {
          setShow(false);
        }, 5000);
      }
    }
  };
  const handleChange = (event, newValue) => {
    history.push(newValue);
  };
  const theme = useTheme();

  return (
    <div
      style={{
        backgroundColor: "#1f1f1fff",
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "20rem",
          marginBottom: "1rem",
          zIndex: "1000",
          position: "absolute",
          top: 50,
          left: 0,
          right: 0,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="secondary" />
          </div>
        )}
        {show && error && <ErrorAlert error={error} />}
        {show && errorMessage && <ErrorAlert error={errorMessage} />}
      </div>
      <div>
        <h3 style={{ fontWeight: "400", color: "white" }}>Are you a Admin? <Link to="/user/login" style={{ color: "white", fontWeight: "500" }}>Login Here</Link></h3>
      </div>

      <Card
        elevation={3}
        sx={{
          width: 360,
          borderRadius: "20px",
          backgroundColor: "#292a2c",
        }}>
        <div
          style={{
            padding: "1rem 2.5rem 0.1rem 2.5rem",
            backgroundColor: "#969696ff",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <img src={logo} alt="logo" style={{ height: "100px", margin: "auto" }} />
          </div>

          <p
            style={{
              textAlign: "center",
              fontSize: "1rem",
              marginTop: "0.1rem",
            }}
          >
            Employee / Manager Login
          </p>
        </div>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Tabs
            aria-label="basic tabs example"
            value={value}
            onChange={handleChange}
            centered
          >
            <Tab value="/login" label="Login" style={{ width: "50%", color: theme.palette.text.primary }} />
            <Tab value="/signup" label="Signup" style={{ width: "50%", color: theme.palette.text.primary }} />
          </Tabs>
        </Box>

        <Box noValidate autoComplete="off">
          <form
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            onSubmit={submitHandler}
          >
            <TextField
              id="outlined-basic"
              label="Email"
              type="email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              //label color="secondary"
              sx={{ '& label': { color: 'white' } }}
              style={{
                width: "85%",
                margin: "auto",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CreditCardIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id="outlined-basic"
              label="Password"
              type="password"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              sx={{ '& label': { color: 'white' } }}
              style={{
                width: "85%",
                margin: "auto",
                marginTop: "1rem",
                marginBottom: "0.5rem",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Link to='/reset-password/employee' style={{ textDecoration: "none", color: "grey" }}>
              <div style={{ textAlign: "center", padding: "0.8rem 0" }}>
                Forgot Password?
              </div>
            </Link>
            <Button
              type="submit"
              style={{
                width: "100%",
                color: "white",
                padding: "1.8rem 0",
                borderRadius: "0",
                color: "#000",
              }}
              sx={{
                backgroundColor: "primary.main",
                "&:hover": {
                  backgroundColor: "primary.dark",
                },
              }}
            >
              LOG IN <ArrowForwardIosIcon sx={{ fontSize: "1rem" }} />
            </Button>
          </form>
        </Box>
      </Card>
    </div>
  );
}

import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import RoleSettings from './RoleSetting';
import DepartmentSettings from './DepartmentSettings';
import GeoTagging from './GeoTagging/GeoTagging';

const customStyles = {
  tab: {
    fontSize: '1.2rem',
    fontWeight: '400',
    // Styles for inactive tabs
    '&.MuiTab-root': {
      color: "#fff"
    },
    '&.Mui-selected': {
      // Styles for the active tab
      // backgroundColor: '#2196F3', // Change this to your desired active tab color
      // color: 'white', // Change this to your desired active tab text color
      // borderRadius: '20px 20px 20px 20px',
      backgroundColor: '#69bcff', // Change this to your desired active tab color
      color: '#000', // Change this to your desired active tab text color
      borderRadius: '20px 20px 20px 20px',
    },

  },
};

const TabPanel = (props) => {
  const { children, value, index } = props;

  return (
    <div
      hidden={value !== index}
      style={{
        height: "80vh",
      }
      }
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};



const GeneralSettings = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flex: '0 0 auto', width: '200px', marginRight: '20px' }}>
      <Box sx={{
        backgroundColor: '#333',
        borderRadius: '10px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        marginBottom: '20px'
      }}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab label="Departments"
            sx={{
              color: value === 0 ? '#fff' : 'rgba(255, 255, 255, 0.7)',
              fontSize: '1.2rem',
              fontWeight: 600,
              '&:hover': {
                color: '#fff',
                opacity: 0.8,
              },
            }} />
          <Tab label="Roles"
            sx={{
              color: value === 1 ? '#fff' : 'rgba(255, 255, 255, 0.7)',
              fontSize: '1.2rem',
              fontWeight: 600,
              '&:hover': {
                color: '#fff',
                opacity: 0.8,
              },
            }}
          />
          <Tab label="Geo Tagging"
            sx={{
              color: value === 2 ? '#fff' : 'rgba(255, 255, 255, 0.7)',
              fontSize: '1.2rem',
              fontWeight: 600,
              '&:hover': {
                color: '#fff',
                opacity: 0.8,
              },
            }}
          />
        </Tabs>
      </Box>
      </div>
            <div style={{ flex: '1' }}>
      <TabPanel value={value} index={0}>
        <DepartmentSettings />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <RoleSettings />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <GeoTagging />
      </TabPanel>
      </div>
    </div>
  );
};

export default GeneralSettings;
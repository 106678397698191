import axios from "axios";
import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
const CheckCurrentBranch = ({ children, location: { pathname } }) => {
    const userLogin = useSelector((state) => state.userLogin);
  const {userInfo } = userLogin;
  const switchBranch = ()=>{
    const config = {
        headers: {
            Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
            content: "application/json",
        },
    };
    axios.get(`${process.env.REACT_APP_API_BACKEND}/api/branch/updateduser`,config)
    .then((res)=>{
        if(res.data.currentBranchId!=userInfo.currentBranchId){
            localStorage.setItem("userInfo", JSON.stringify(res.data));
            window.location.reload(false);
        }

    })
    .catch((err) => {
        console.log(err.response.data.message);                 
      });
  }
    useEffect(() => {
        if(userInfo){
            switchBranch();
        }
    }, [pathname]);

    return children || null;
};

export default withRouter(CheckCurrentBranch);
import axios from "axios";
import { useEffect } from "react";


import React from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2),
    },
    scheduleBox: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));



const EmployeeScheduleList = ({ id }) => {
    const classes = useStyles();
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
   
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
        },
    };

    const fetchTimeLine = () => {
        axios.get(`${process.env.REACT_APP_API_BACKEND}/api/schedule/employee/${id}`, config)
        .then((res) => {
            res.data.employeeSchedules.sort((a, b) => {
                return new Date(b.date) - new Date(a.date);
            });
            setData(res.data);
            setLoading(false);
        })
        .catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        fetchTimeLine();
    }
    , []);


    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                {!loading && data.employeeSchedules.map((schedule) => (
                    <Grid item xs={12} sm={3} key={schedule.id}>
                        <Paper elevation={2} className={classes.scheduleBox} sx={{ borderRadius: "20px" }}>
                            <Typography variant="h6">{schedule.day}</Typography>
                            <Typography variant="body1">
                                Date: {new Date(schedule.date).toLocaleDateString()}
                            </Typography>
                            <Typography variant="body1">Start Time: {schedule.startTime}</Typography>
                            <Typography variant="body1">End Time: {schedule.endTime}</Typography>
                            <Typography variant="body1">Total Hours: {schedule.totalHours}</Typography>
                            <Typography variant="body1">
                                Published: {schedule.published ? 'Yes' : 'No'}
                            </Typography>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};


export default EmployeeScheduleList;

import React from 'react'
import Skeleton from '@mui/material/Skeleton'
function TableLoader() {
  return (
      <>
    <Skeleton height={50} />
    <Skeleton height={50} animation="wave" />
    <Skeleton height={50} animation={false} />
    </>
  )
}

export default TableLoader
import React, { useState } from 'react';
import { AppBar, Tab, Tabs, Typography, Box, Container } from '@mui/material';
import GeneralSettings from '../components/My Company/Settings';
import Genral from '../components/My Company/Settings';

const customStyles = {
  tab: {
    fontSize: '1.5rem',
    fontWeight: '400',
    // Styles for inactive tabs
    '&.MuiTab-root': { 
        color: 'black',
    },
    '&.Mui-selected': {
        // Styles for the active tab
        backgroundColor: '#2196F3', // Change this to your desired active tab color
        color: 'white', // Change this to your desired active tab text color
        borderRadius: '20px 20px 0px 0px',
      },
  },
  tabPanel: {
    backgroundColor: '#EFEFEF', // Change this to your desired inactive tab content color
  },
};

const TabPanel = (props) => {
  const { children, value, index } = props;

  return (
    <div
      hidden={value !== index}
      sx={customStyles.tabPanel} // Apply custom styles to inactive tab content
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const SettingsPage = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Settings
      </Typography>
      {/* <AppBar position="static"
      color='transparent'
      elevation={0}
      sx={{ borderBottom: 1, borderColor: 'divider'}}
      >
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab
            label="General"
            sx={customStyles.tab} // Apply custom styles to the "General" tab
          />
          <Tab
            label="Notifications"
            sx={customStyles.tab} // Apply custom styles to the "Notifications" tab
          />
          <Tab
            label="Profile"
            sx={customStyles.tab} // Apply custom styles to the "Profile" tab
          />
        </Tabs>
      </AppBar> */}
      <TabPanel value={tabValue} index={0}>
        <GeneralSettings/>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        {/* Content for Notifications tab */}
        <Typography variant="h6">Notification Settings</Typography>
        <Genral/>
        {/* Add your settings form or content here */}
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        {/* Content for Profile tab */}
        <Typography variant="h6">Profile Settings</Typography>
        {/* Add your settings form or content here */}
      </TabPanel>
      </div>
  );
};

export default SettingsPage;

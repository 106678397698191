import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import { Alert } from '@mui/material';

const UserDepartmentsSidebar = ({ currentDepartment, departments, onDepartmentChange, isOpen, onClose, newDepartment, assignDepartment}) => {
  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      
      <List sx={{ width: 300, marginTop: 10 }}>
        <ListItem>
          <ListItemText primary={`Current Department: ${currentDepartment.department}`} />
        </ListItem>
        <ListItem>
          {console.log(departments)}
          <FormControl fullWidth>
            <Select
               defaultValue={''}
              value={newDepartment.id === 1 ? 1 : newDepartment.id}
              onChange={onDepartmentChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Select a new department' }}
              
            >
              {departments && departments.map((department) => (
                <MenuItem key={department.id} value={department.id}>
                  {department.department}
                </MenuItem>
              ))}
            </Select>
           <Button
            variant="contained"
            style={{ marginTop: "10px" }}
            onClick={assignDepartment}
            >
            Update
           </Button>
          </FormControl>
          
        </ListItem>
      </List>
    </Drawer>
  );
};

const DepartmentChangeButton = ({ currentDepartment , empId, fetchEmployees, setSuccess, setError}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [newDepartment, setNewDepartment] = useState(currentDepartment);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  
  const onDepartmentChange = (event) => {
    //department is in format of {id: 1, department: "Admin"}
    setNewDepartment({id: event.target.value, department: departments.find((department) => department.id === event.target.value).department});

  };

  const config = {
    headers: {
      "Content-Type": "Application/json",
      Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
    },
  };
  const fetchDepartments = async () => {
    console.log(userInfo);
    axios.get(`${process.env.REACT_APP_API_BACKEND}/api/getDepartments/${userInfo.currentBranchId}`, config).then((response) => {
        setDepartments(response.data);
    });
    };

  
    const assignDepartment = async () => {
      console.log(newDepartment);
      axios.post(`${process.env.REACT_APP_API_BACKEND}/api/employee/assignDepartment/${empId}`, {deptId: newDepartment.id}, config).then((response) => {
        setSuccess(response.data.message);
        setError("");
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        fetchEmployees();
      }).catch((error) => {
        setError(error);
        setSuccess("");
        setTimeout(() => {
          setError("");
        }, 3000);
      });
    };



        

  const toggleSidebar = () => {
    fetchDepartments();
    setIsOpen(!isOpen);
  };
  console.log(currentDepartment)
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button variant="contained" onClick={toggleSidebar} sx={{fontSize: 12}} >
        Change Department 
      </Button>
      <UserDepartmentsSidebar
        currentDepartment={currentDepartment}
        departments={departments}
        isOpen={isOpen}
        onClose={handleClose}
        onDepartmentChange={onDepartmentChange}
        newDepartment={newDepartment}
        assignDepartment={assignDepartment}
      />
    </>
  );
};

export default DepartmentChangeButton;



import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@mui/material";
import { withStyles } from "@material-ui/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CreateShift from "./CreateShift";
import { DateRangePicker } from 'materialui-react-daterange-picker';
import ExportCSV from "./ExportCSV";
import { set } from "date-fns";
import { useTheme } from "@emotion/react";


// custom styling for input text field
const styles = (theme) => ({
  input: {
    height: 40,
    paddingRight: 0,
  },
});

const ShiftsHeader = withStyles(styles)((props) => {
  const { classes, firstday, shiftArray, lastday, setDateRange, startDay, month, startMonth, endDay, endMonth, dateRange, allShifts, error,
    setError,
    success,
    setSuccess,
    fetchShifts,
    setShiftArray,
    userInfo,
    search,
    setSearch
  } = props;
  const [filter, setFilter] = useState("");
  const [status, setStatus] = useState("All");
  const [open, setOpen] = useState(false);
  const theme = useTheme(); 

  const toggle = () => setOpen(!open);

  const saveDateRange = () => {
    sessionStorage.setItem("dateRange", JSON.stringify(dateRange));
  }
  const change = (range) => {

    setDateRange(range);
    saveDateRange();
  }
  const handleClickOpen = () => {
    setOpen(true);
  };
  const goPrevious = () => {
    let startday = dateRange.startDate;
    let endday = dateRange.endDate;
    startday.setDate(startday.getDate() - 7);
    endday.setDate(endday.getDate() - 7);
    setDateRange({ startDate: startday, endDate: endday })
    saveDateRange();
  }
  const goNext = () => {
    let startday = dateRange.startDate;
    let endday = dateRange.endDate;
    startday.setDate(startday.getDate() + 7);
    endday.setDate(endday.getDate() + 7);
    setDateRange({ startDate: startday, endDate: endday })
    saveDateRange();
  }
  
  const searchHandler = (e) => {
    setSearch(e.target.value);
  
    if (!e.target.value || e.target.value.trim() === "") {
      setShiftArray(allShifts);
      props.setIsSearch(false);
      return;
    }
  
    const searchText = e.target.value.toLowerCase();
  
    const filteredData = {};
  
    Object.keys(allShifts).forEach((deptName) => {
      filteredData[deptName] = allShifts[deptName].filter((shift) => {
        const emailMatches = shift.email.toLowerCase().includes(searchText);
        const fnameMatches = shift.employeeDetail.fname.toLowerCase().includes(searchText);
        const lnameMatches = shift.employeeDetail.lname.toLowerCase().includes(searchText);
        return emailMatches || fnameMatches || lnameMatches;
      });
    });
  
    const filteredDataWithoutEmptyObjects = Object.keys(filteredData).reduce((acc, deptName) => {
      if (filteredData[deptName].length > 0) {
        acc[deptName] = filteredData[deptName];
      }
      return acc;
    }, {});
  
    setShiftArray(filteredDataWithoutEmptyObjects);
    props.setIsSearch(true);
  };
  


  const filterHandler = (e) => {
    setStatus(e.target.value);
  
    if (e.target.value === "All") {
      props.setShiftArray(allShifts);
    } else if (e.target.value === "Approved") {
      const filteredShifts = {};
      Object.keys(shiftArray).forEach((deptName) => {
        filteredShifts[deptName] = shiftArray[deptName].map((employee) => ({
          ...employee,
          shifts: employee.shifts.filter((shift) => shift.approved),
        }));
      });
      props.setShiftArray(filteredShifts);
    } else if (e.target.value === "Unconfirmed") {
      const filteredShifts = {};
      Object.keys(allShifts).forEach((deptName) => {
        filteredShifts[deptName] = allShifts[deptName].map((employee) => ({
          ...employee,
          shifts: employee.shifts.filter((shift) => !shift.approved),
        }));
      });
      props.setShiftArray(filteredShifts);
    }
    else {
      const filteredShifts = {};
      Object.keys(allShifts).forEach((deptName) => {
        filteredShifts[deptName] = allShifts[deptName].map((employee) => ({
          ...employee,
          shifts: employee.shifts.filter((shift) => shift.status === e.target.value),
        }));
      });
      props.setShiftArray(filteredShifts);
    }
  };

  





  return (
    <div>
      <div
        className="header"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div className="left-container">
          <div style={{ fontSize: "1.4rem" }}>Employees Shifts</div> <br />
          <span>Search by name/email</span>
          <br />
         
          <TextField
            id="outlined-basic"
            value={search}
            onChange={searchHandler}
            style={{ width: "550px" }}
            sx={{ borderRadius: 1, backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary, "& .MuiInputBase-input": { backgroundColor: theme.palette.card.bg, padding: "10px"} }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: theme.palette.text.primary }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: "0 5px 5px 0",
                      textTransform: "capitalize",
                      fontWeight: "600",
                      position: "absolute",
                      right: "0",
                      height: "100%"
                    }}
                    onClick={searchHandler}
                  >
                    Search
                  </Button>
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />


        </div>
        <div className="right-container" style={{ marginLeft: "1rem" }}>
          {/* <Button variant="contained" style={{backgroundColor:"#1a237e",textTransform:"capitalize", fontWeight:"600"}}><AddIcon/> Invite employee</Button> */}
          <Stack spacing={1} direction="row" justifyContent="right">
            
            <ExportCSV shiftArray={shiftArray} dateRange={dateRange} branchName={userInfo.branchName} />
            {userInfo.isAdmin || Object.keys(userInfo.permissionData).length !== 0 && userInfo.permissionData.ShiftCreate && userInfo.permissionData.ShiftCreate.read===true ?
            <CreateShift fetchShifts={fetchShifts} success={success} error={error} setSuccess={setSuccess} setError={setError} />: 
            null
            }
            </Stack>

          <Stack spacing={1} direction="row" justifyContent="right">
            <div>
              <div style={{ marginTop: "1.2rem" }}>Status</div>
              <FormControl fullWidth size="small">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={status}
                  style={{ width: "10rem" }}
                  onChange={filterHandler}
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  {/* <MenuItem value={"Hide Unconfirmed"}>Hide Unconfirmed</MenuItem> */}
                  <MenuItem value={"Approved"}>Approved</MenuItem>
                  {/* <MenuItem value={"Confirmed"}>Confirmed</MenuItem> */}
                  <MenuItem value={"Unconfirmed"}>Unconfirmed</MenuItem>
                  {/* <MenuItem value={"Cancelled"}>Cancelled</MenuItem> */}
                </Select>
              </FormControl>
            </div>
            <div style={{ marginTop: "2.8rem" }}>
              <Button
                variant="contained"
                style={{ textTransform: "none", fontWeight: "500" }}
                startIcon={<ArrowBackIcon />}
                onClick={goPrevious}
              >
                Previous
              </Button>
            </div>
            <div style={{ marginTop: "2.8rem" }}>
              <Button
                variant="outlined"
                style={{ textTransform: "none", fontWeight: "600" }}
                onClick={handleClickOpen}
              >
                {/* {startDay} {month[startMonth]} - {endDay} {month[endMonth]} */}
                {(startDay + month[startMonth] === endDay + month[endMonth]) ? (startDay + " " + month[startMonth]) : (startDay + " " + month[startMonth] + " - " + endDay + " " + month[endMonth])}
              </Button>
              <div style={{ position: "relative" }}>
                {open && <div className="date-range-picker-container"></div>}
                <div className="date-range-picker">
                  <DateRangePicker
                    open={open}
                    toggle={toggle}
                    onChange={change}
                    initialDateRange={{ startDate: firstday, endDate: lastday }}
                  />
                </div>
              </div>
            </div>
            <div style={{ marginTop: "2.8rem" }}>
              <Button
                variant="contained"
                style={{ textTransform: "none", fontWeight: "500" }}
                endIcon={<ArrowForwardIcon />}
                onClick={goNext}
              >
                Next
              </Button>
            </div>
          </Stack>
        </div>
      </div>
    </div>
  );
});

export default ShiftsHeader;

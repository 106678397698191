import React, { useEffect } from "react";
import { useState } from "react";
import ShiftsHeader from "../components/Employees/Shifts/ShiftsHeader";
import ShiftsTable from "../components/Employees/Shifts/ShiftsTable";
import { startOfWeek, endOfWeek, set } from "date-fns";
import axios from "axios";
import { useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner";
function Shifts() {
  const [shiftLoading, setShiftLoading] = useState(true);
  const [shiftArray, setShiftArray] = useState([]);
  const [allShifts, setAllShifts] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [search, setSearch] = useState("");
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  let curr = new Date();
  let firstday = startOfWeek(curr, { weekStartsOn: 1 });
  let lastday = endOfWeek(curr, { weekStartsOn: 1 });

  //check if the date range is in the session storage
  if (sessionStorage.getItem("dateRange")) {
    let newDateRange = JSON.parse(sessionStorage.getItem("dateRange"));
    firstday = new Date(newDateRange.startDate);
    lastday = new Date(newDateRange.endDate);
  }

  const [dateRange, setDateRange] = useState({
    startDate: firstday,
    endDate: lastday,
  });
  let startMonth = dateRange.startDate.getMonth();
  let endMonth = dateRange.endDate.getMonth();
  let startDay = dateRange.startDate.getDate();
  let endDay = dateRange.endDate.getDate();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  // to format date in the form backend is accepting
  const formatDate = (date) => {
    date = new Date(date);
    let dd = String(date.getDate()).padStart(2, "0");
    let mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = date.getFullYear();
    date = yyyy + "-" + mm + "-" + dd;
    return date;
  };


  // to fetch shifts from backend
  const fetchShifts = () => {
    setShiftLoading(true);
    const startDate = formatDate(dateRange.startDate);
    const endDate = formatDate(dateRange.endDate);

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_BACKEND}/api/employees/shifts`,
        { startDate: startDate, endDate: endDate },
        config
      )
      .then((res) => {
        setAllShifts(res.data.shiftsByDepartment);
        if (!isSearch) {
          setShiftArray(res.data.shiftsByDepartment);
          // setSortedRoleWiseShifts(handelRoleWiseShiftData(res.data.user, roles));
        } else {
          const searchText = search.toLowerCase();

          const filteredData = {};

          Object.keys(res.data.shiftsByDepartment).forEach((deptName) => {
            filteredData[deptName] = res.data.shiftsByDepartment[deptName].filter((shift) => {
              const emailMatches = shift.email.toLowerCase().includes(searchText);
              const fnameMatches = shift.employeeDetail.fname.toLowerCase().includes(searchText);
              const lnameMatches = shift.employeeDetail.lname.toLowerCase().includes(searchText);
              return emailMatches || fnameMatches || lnameMatches;
            });
          }
          );
          const filteredDataWithoutEmptyObjects = Object.keys(filteredData).reduce((acc, deptName) => {
            if (filteredData[deptName].length > 0) {
              acc[deptName] = filteredData[deptName];
            }
            return acc;
          }, {});
          setShiftArray(filteredDataWithoutEmptyObjects);
        }


        

        setShiftLoading(false);
      })
      .catch((err) => {
        setShiftLoading(false);
        setError(err.response.data.message);
      });
  };





  useEffect(() => {
    if (!isSearch) {
      setShiftArray(allShifts);
    }
  }, [allShifts])


  return (
    <div>
      <ShiftsHeader
        shiftArray={shiftArray}
        setShiftArray={setShiftArray}
        firstday={firstday}
        lastday={lastday}
        dateRange={dateRange}
        setDateRange={setDateRange}
        startMonth={startMonth}
        endMonth={endMonth}
        startDay={startDay}
        endDay={endDay}
        month={month}
        setShiftLoading={setShiftLoading}
        setAllShifts={setAllShifts}
        allShifts={allShifts}
        fetchShifts={fetchShifts}
        success={success}
        setSuccess={setSuccess}
        error={error}
        setError={setError}
        setIsSearch={setIsSearch}
        userInfo={userInfo}
        search={search}
        setSearch={setSearch}
      />

      <ShiftsTable
        shiftArray={shiftArray}
        setShiftArray={setShiftArray}
        firstday={firstday}
        lastday={lastday}
        dateRange={dateRange}
        setDateRange={setDateRange}
        startMonth={startMonth}
        endMonth={endMonth}
        startDay={startDay}
        endDay={endDay}
        month={month}
        dayNames={dayNames}
        shiftLoading={shiftLoading}
        setShiftLoading={setShiftLoading}
        allShifts={allShifts}
        setAllShifts={setAllShifts}
        fetchShifts={fetchShifts}
        success={success}
        setSuccess={setSuccess}
        error={error}
        setError={setError}
      />

    </div>
  );
}

export default Shifts;

import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { withStyles, makeStyles } from "@material-ui/styles";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import SuccessAlerts from "../components/SuccessAlert";
import ErrorAlerts from "../components/ErrorAlert";
import { TailSpin } from "react-loader-spinner";
import { TextField, useTheme } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const tableStyles = makeStyles({
  tableRow: {
    height: 10,
  },
  tableCell: {
    padding: "8px 0",
  },
  bodyCell: {
    padding: "5px 16px",
  },
  input: {
    height: 40,
  },
});
const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 20,
  },
}))(TableRow);
const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "10px 5px",
  },
}))(TableCell);

function Emergency({ id }) {
  const classes = tableStyles();
  const [employee, setEmployee] = useState({});
  const [edit, setEdit] = useState(0);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [workPhone, setWorkPhone] = useState("");
  const [contactType, setContactType] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  function fetchEmployee() {
    setLoading(true);
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
      },
    };
    axios
      .get(`${process.env.REACT_APP_API_BACKEND}/api/employee/${id}`, config)
      .then((res) => {
        setLoading(false);
        console.log(res.data.employee);
        setEmployee(res.data.employee);
      })
      .catch((err) => {
        setLoading(false);
        // console.log(err.response.data.message);
        console.log(err.response.data.message);
        // console.log(errormessage);
      });
    setFirstName(employee.firstName);
    setLastName(employee.lastName);
    setEmail(employee.email);
    setMobile(employee.mobile);
    setWorkPhone(employee.workPhone);
    setContactType(employee.contactType);
  }
  const submitHandler = () => {
    const config = {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
      },
    };
    console.log("submitted");
    axios
      .patch(
        `${process.env.REACT_APP_API_BACKEND}/api/employee/update/${id}`,
        {
          firstName: firstName,
          lastName: lastName,
          email: email,
          mobile: mobile,
          workPhone: workPhone,
          contactType: contactType,
        },
        config
      )
      .then((res) => {
        setSuccess(res.data.message);
        fetchEmployee();
        setEdit(0);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setError(err.response.data.message);
      });
  };

  useEffect(() => {
    fetchEmployee();
  }, []);

  const editHandler = () => {
    if (edit == 0) {
      fetchEmployee();
      setEdit(1);
    } else {
      fetchEmployee();
      setEdit(0);
    }
  };

  if (success) {
    setTimeout(() => {
      setSuccess("");
    }, 5000);
  }
  if (error) {
    setTimeout(() => {
      setError("");
    }, 5000);
  }
  return (
    <div>
      {loading ? (
        <div className="loader">
          <TailSpin color={theme.palette.primary.main} height={120} width={120} />
        </div>
      ) : (
        <>
          <div
            style={{
              width: "20rem",
              marginBottom: "1rem",
              zIndex: "1000",
              position: "absolute",
              top: 70,
              left: 0,
              right: 0,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {error && <ErrorAlerts error={error} />}
            {success && <SuccessAlerts success={success} />}
          </div>
          {edit == 0 && (
            <div>
              <Stack
                spacing={1}
                mb={2}
                mt={1}
                direction="row"
                justifyContent="right"
              >
                <Button
                  variant="contained"
                  style={{ textTransform: "none" }}
                  onClick={editHandler}
                >
                  Edit Information
                </Button>
              </Stack>
            </div>
          )}
          {edit == 1 && (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  marginTop: "0.7rem",
                  color: "#32c0f0",
                  fontSize: "1.2rem",
                }}
              >
                Please save your changes.
              </div>
              <Stack
                spacing={1}
                mb={2}
                mt={1}
                direction="row"
                justifyContent="right"
              >
                <Button
                  variant="contained"
                  style={{
                    textTransform: "none",
                    backgroundColor: "#e0e0e0",
                    color: "#000000",
                    fontWeight: "600",
                  }}
                  onClick={editHandler}
                >
                  Cancel changes
                </Button>
                <Button
                  variant="contained"
                  style={{ textTransform: "none" }}
                  onClick={submitHandler}
                >
                  Save changes
                </Button>
              </Stack>
            </div>
          )}
          {edit == 0 && (
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={4}>
                  <Item>
                    {" "}
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow className={classes.tableRow}>
                            <TableCell
                              className={classes.tableCell}
                              style={{ fontWeight: "600", fontSize: "1.1rem" }}
                            >
                              Primary contact
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              align="right"
                            ></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            className={classes.tableRow}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              className={classes.tableCell}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              First name
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {employee ? employee.firstName : ""}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            className={classes.tableRow}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              className={classes.tableCell}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Last name
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {employee ? employee.lastName : ""}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            className={classes.tableRow}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              className={classes.tableCell}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Email
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {employee ? employee.email : ""}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            className={classes.tableRow}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              className={classes.tableCell}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Mobile
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {employee ? employee.mobile : ""}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            className={classes.tableRow}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              className={classes.tableCell}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Work phone
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {employee ? employee.workPhone : ""}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            className={classes.tableRow}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              className={classes.tableCell}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Contact type
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {employee ? employee.contactType : ""}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Item>
                </Grid>
              </Grid>
            </Box>
          )}
          {edit == 1 && (
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={4}>
                  <Item>
                    {" "}
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow className={classes.tableRow}>
                            <TableCell
                              className={classes.tableCell}
                              style={{ fontWeight: "600", fontSize: "1.1rem" }}
                            >
                              Primary contact
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              align="right"
                            ></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            className={classes.tableRow}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              className={classes.tableCell}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              First name
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <TextField
                                value={firstName}
                                InputProps={{
                                  className: classes.input,
                                }}
                                onChange={(e) => setFirstName(e.target.value)}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow
                            className={classes.tableRow}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              className={classes.tableCell}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Last name
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <TextField
                                value={lastName}
                                InputProps={{
                                  className: classes.input,
                                }}
                                onChange={(e) => setLastName(e.target.value)}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow
                            className={classes.tableRow}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              className={classes.tableCell}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Email
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <TextField
                                value={email}
                                InputProps={{
                                  className: classes.input,
                                }}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow
                            className={classes.tableRow}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              className={classes.tableCell}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Mobile
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <TextField
                                value={mobile}
                                InputProps={{
                                  className: classes.input,
                                }}
                                onChange={(e) => setMobile(e.target.value)}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow
                            className={classes.tableRow}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              className={classes.tableCell}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Work phone
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <TextField
                                value={workPhone}
                                InputProps={{
                                  className: classes.input,
                                }}
                                onChange={(e) => setWorkPhone(e.target.value)}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow
                            className={classes.tableRow}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              className={classes.tableCell}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              Contact type
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Age"
                                fullWidth
                                input={<OutlinedInput />}
                                size="small"
                                value={contactType}
                                sx={{ width: 225 }}
                                onChange={(e) => setContactType(e.target.value)}
                              >
                                <MenuItem value={"Family"}>Family</MenuItem>
                                <MenuItem value={"Friend"}>Friend</MenuItem>
                                <MenuItem value={"Partner"}>Partner</MenuItem>
                              </Select>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Item>
                </Grid>
              </Grid>
            </Box>
          )}
        </>
      )}
    </div>
  );
}

export default Emergency;

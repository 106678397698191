import React from "react";
import BatchesHeader from "../components/Purchase Orders/Batches/BatchesHeader";
import BatchesTable from "../components/Purchase Orders/Batches/BatchesTable";

function Batches() {
  return (
    <div>
      <BatchesHeader />
      <BatchesTable />
    </div>
  );
}

export default Batches;

import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Alert, Button, CardActionArea, CardActions } from "@mui/material";
import Box from "@mui/material/Box";
import logo from "../crinitis-logo.png";
import { useLocation, useHistory, Link, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import InputAdornment from "@mui/material/InputAdornment";
import LockIcon from "@mui/icons-material/Lock";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions/userActions";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorAlert from "../components/ErrorAlert";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from "axios";
export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [otp, setOTP] = useState("");
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const [showOTP, setSHowOTP] = useState(false);
  const location = useLocation();
  const history = useHistory();

  // getting logged in user info
  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;
  const params = useParams();
  const post = params.post;

  const verifyOTP = (e) => {
    e.preventDefault();
    if (!email) {
      setErrorMessage("Please provide an email");
      if (!show) {
        setShow(true);
        setTimeout(() => {
          setShow(false);
          setErrorMessage("");
        }, 5000);
      }
    } else if (!email) {
      setErrorMessage("Please provide otp");
      if (!show) {
        setShow(true);
        setTimeout(() => {
          setShow(false);
          setErrorMessage("");
        }, 5000);
      }
    }
    else {
      axios.post(`${process.env.REACT_APP_API_BACKEND}/api/otp/confirm`, { email: email, otp: otp })
        .then(res => {
          console.log(res);
          if (res.data.success) {
            setShow(true);
            sessionStorage.setItem("password-reset-token", res.data.JWT_TOKEN);
            setMessage("OTP verified successfully");
            setTimeout(() => {
              history.push(`/new-password/${post}`);
              setShow(false);
              setMessage("");
            }, 3000);
          }
        }).catch(err => {
          console.log(err);
          setShow(true);
          setErrorMessage(err.response.data.message);
          setTimeout(() => {
            setShow(false);
            setErrorMessage("");
          }, 2000);
        })

    }
  };
  const handleChange = (event, newValue) => {
    history.push(newValue);
  };
  const goBack = () => {
    history.goBack();
  }
  const requestOTP = (e) => {
    e.preventDefault();
    if (!email) {
      setErrorMessage("Please provide an email");
      if (!show) {
        setShow(true);
        setTimeout(() => {
          setShow(false);
          setErrorMessage("");
        }, 5000);
      }
    } else {
      // dispatch(login(email.toLowerCase(), password));
      axios.post(`${process.env.REACT_APP_API_BACKEND}/api/${post}/otp`, { email: email }).then(res => {
        console.log(res);
        setSHowOTP(true);
        setMessage(res.data.message);
        setShow(true);
        setTimeout(() => {
          setShow(false);
          setMessage("");
        }, 5000);
      }).catch(err => {
        console.log(err);
        setShow(true);
        setErrorMessage(err.response.data.message);
        setTimeout(() => {
          setShow(false);
          setErrorMessage("");
        }, 5000);
      })
      if (!show) {
        setShow(true);
        setTimeout(() => {
          setShow(false);
        }, 5000);
      }
    }
  }

  return (
    <div
      style={{
        backgroundColor: "#1f1f1fff",
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "20rem",
          marginBottom: "1rem",
          zIndex: "1000",
          position: "absolute",
          top: 50,
          left: 0,
          right: 0,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="secondary" />
          </div>
        )}
        {show && errorMessage && <ErrorAlert error={errorMessage} />}
        {show && message && <Alert severity="success">{message}</Alert>}
      </div>
      {/* <Card sx={{ maxWidth: 310 }}>
        <div
          style={{
            padding: "1rem 1.5rem 0.1rem 0.2rem",
            backgroundColor: "#ebebeb",
          }}
        >
            <div style={{display:"flex",justifyContent:"space-between"}}>
                <div style={{marginRight:"1rem",marginLeft:'0.5rem',}}  onClick={goBack}>
       <ArrowBackIcon sx={{backgroundColor: "#fff",borderRadius:"50%",fontSize:"1rem",padding:"0.2rem",color:"black"}}/>
       </div>
 
          <CardMedia component="img" image={logo} alt="logo" />
          </div> */}
      <Card
        elevation={3}
        sx={{
          width: 360,
          borderRadius: "20px",
          backgroundColor: "#292a2c",
        }}>
        <div
          style={{
            padding: "1rem 2.5rem 0.1rem 2.5rem",
            backgroundColor: "#969696ff",
          }}
        >

          <div style={{ marginRight: "1rem", marginLeft: '0.5rem', position: "relative", top: "0.5rem", left: "0.1rem" }}
            onClick={goBack}>
            <ArrowBackIcon sx={{ backgroundColor: "#fff", borderRadius: "50%", fontSize: "1rem", padding: "0.2rem", color: "black" }} />
          </div>
          <div style={{ textAlign: "center" }}>
            <img src={logo} alt="logo" style={{ height: "100px", margin: "auto" }} />
          </div>
          <p
            style={{
              textAlign: "center",
              fontSize: "1.5rem",
              marginTop: "0.3rem",
              marginBottom: "0.8rem",
              marginLeft: "1.5rem",
            }}
          >
            Reset your password
          </p>
        </div>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
        >

        </Box>
        <p style={{ margin: '0.7rem auto', textAlign: "center", fontSize: "0.85rem", width: "85%", color: "#9a9a9a", lineHeight: "170%" }}>Please enter your email address. We will send you an email to reset your password.</p>
        <Box noValidate autoComplete="off">
          <form
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          // onSubmit={submitHandler}
          >
            <TextField
              id="outlined-basic"
              label="Email"
              type="email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ '& label': { color: 'white' } }}
              required
              style={{
                width: "85%",
                margin: "auto",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CreditCardIcon />
                  </InputAdornment>
                ),
              }}
            />
            {showOTP && <TextField
              id="outlined-basic"
              label="OTP"
              type="otp"
              variant="outlined"
              value={otp}
              onChange={(e) => setOTP(e.target.value)}
              required
              sx={{ '& label': { color: 'white' } }}
              style={{
                width: "85%",
                margin: "auto",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CreditCardIcon />
                  </InputAdornment>
                ),
              }}
            />}

            {!showOTP &&
              <Button
                type="submit"
                style={{
                  width: "100%",

                  color: "#000",  
                  padding: "1.8rem 0",
                  borderRadius: "0",

                }}
                sx={{
                  backgroundColor: "primary.main",
                  "&:hover": {
                    backgroundColor: "primary.dark",
                  },
                 
                }}
                onClick={requestOTP}
              >
                Send Email <ArrowForwardIosIcon sx={{ fontSize: "1rem" }} />
              </Button>
            }
            {showOTP && <Button
              type="submit"
              style={{
                width: "100%",
                color: "#000",
                padding: "1.8rem 0",
                borderRadius: "0",

              }}
              sx={{
                backgroundColor: "primary.main",
                "&:hover": {
                  backgroundColor: "primary.dark",
                },
                
              }}
              onClick={verifyOTP}
            >
              Verify OTP <ArrowForwardIosIcon sx={{ fontSize: "1rem" }} />
            </Button>
            }
          </form>
        </Box>
      </Card>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { withStyles, styled } from "@material-ui/styles";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { DropzoneArea } from "react-mui-dropzone";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import SuccessAlerts from "../../SuccessAlert";
import ErrorAlerts from "../../ErrorAlert";
import { CircularProgress, useTheme } from "@mui/material";
// custom styling for input text field
const styles = (theme) => ({
  input: {
    height: 40,
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
  },
});

const CreateNewBranchTab = withStyles(styles)((props) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { classes } = props;
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [loading, setLoading] = useState(false);
  // state for handling step count
  const [activeStep, setActiveStep] = useState(0);
  const [files, setFiles] = useState([]);
  const [value, setValue] = useState("");
  const [branchName, setBranchName] = useState("");
  const [abn, setAbn] = useState("");
  const [website, setWebsite] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [clocation, setCLocation] = useState("");
  const [employee, setEmployee] = useState("");
    const [address, setAddress] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [twitter, setTwitter] = useState("");

  const [bankName, setBankName] = useState("");
  const [BSB, setBSB] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const history = useHistory();
  const location = useLocation();
  function removeNonAlphabet(str) {
        return str.replace(/[^a-zA-Z]/g, '');
    }
  // function to go to next step
  const handleNext1 = () => {
    //check if all fields are filled out
    if (
      branchName !== "" &&
     email!=="" && clocation!=="" && phone!==""
    ) {
      let testname = removeNonAlphabet(branchName);
      if(testname.length < 2){
        alert("Branch name must be at least 2 characters long other than numbers and special characters");
      }else{
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      alert("Please fill out all fields");
    }
  };
  const handleNext2 = () => {
    if (employee !== "") {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      alert("Please provide a primary contact");
    }
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // function to go to previous step
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  //function to reste all the step count
  const handleReset = () => {
    setActiveStep(0);
  };

  const fileUploadHandler = (newFiles) => {
    setFiles(newFiles);
    // setFiles(newFiles);
    console.log(files);
  };

  const theme = useTheme();
  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const file = files[0];
    formData.append("name", branchName);
    formData.append("ABN", abn);
    // formData.append("lname", lname);
    formData.append("website", website);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("location", clocation);
    // formData.append("gender", gender);
    // formData.append("medicareNumber", medicareNumber);
    formData.append("employee", employee);
    formData.append("address", address);
    formData.append("address2", address2);
    formData.append("city", city);
    formData.append("state", state);
    formData.append("postCode", postalCode);
    formData.append("country", country);
    formData.append("bankName", bankName);
    formData.append("BSB", BSB);
    formData.append("accountNumber", accountNumber);
    formData.append("facebook", facebook);
    formData.append("instagram", instagram);
    formData.append("linkedIn", linkedIn);
    formData.append("twitter", twitter);
    formData.append("branchLogo", file);
    // formData.append("workingRights", workingRights);
    // formData.append("taxFileNumber", taxFileNumber);
    // formData.append("firstName", eFname);
    // formData.append("lastName", eLname);
    // formData.append("email", eEmail);
    // formData.append("mobile", eMobile);
    // formData.append("workPhone", eWorkPhone);
    // formData.append("contactType", eContactType);
    // files.forEach((file) => {
    //   formData.append("branchLogo", file); // appending image one by one for the same key
    // });
    window.scrollTo(0, 0)
    console.log(files);
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_API_BACKEND}/api/${userInfo.companyId}/registerbranch`,
        formData,
        config
      )
      .then((res) => {
        console.log(res.data.message);
        setLoading(false);
        setSuccess(res.data.message);
        localStorage.setItem("userInfo", JSON.stringify(res.data));
        // history.push("/registered");
      })
      .catch((err) => {
        // console.log(err.response.data.message);
        setLoading(false);
        console.log(err.response.data.message);
        setError(err.response.data.message);
        // console.log(errormessage);
      });
  };
  if (success) {
    setTimeout(() => {
      setSuccess("");
      window.location.reload(false);
    }, 2000);
  }
  if (error) {
    setTimeout(() => {
      setError("");
    }, 3000);
  }
  return (
    <div>
      <div style={{ textAlign: "center", margin: "auto", marginTop: "1rem" }}>
          {loading && <CircularProgress color="secondary" />}
          {error && <ErrorAlerts error={error} />}
          {success && <SuccessAlerts success={success} />}
        </div>
      <div style={{ fontSize: "1.3rem",marginTop:"1.5rem" }}>
        Please fill Branch details below
      </div>
      <Box
        sx={{ maxWidth: "100%" }}
        style={{
          margin: "2.5rem 1.5rem",
          backgroundColor: theme.palette.card.bg,
          borderRadius: "10px",
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        }}
      >
        <form onSubmit={submitHandler}>
          
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            style={{ padding: "1rem 2rem" }}
          >
            <Step>
              <StepLabel>
                <span style={{ fontSize: "1.2rem" }}>Branch Information</span>
              </StepLabel>
              <StepContent>
                <Grid container spacing={2} style={{ margin: "1.2rem 0" }}>
                  <Grid item xs={12} md={6}>
                    <div>Branch Name*</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Branch Name"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required
                      variant="outlined"
                      value={branchName}
                      onChange={(e) => setBranchName(e.target.value)}
                    />
                  </Grid>
                  
                  <Grid item xs={12} md={6}>
                    <div>ABN</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="ABN"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required
                      variant="outlined"
                      value={abn}
                      onChange={(e) => setAbn(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Website</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Website"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required
                      variant="outlined"
                      value={website}
                      onChange={(e) => setWebsite(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Phone*</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Phone"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      type="number"
                      required
                      variant="outlined"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </Grid>
                  
                  <Grid item xs={12} md={6}>
                    <div>Email*</div>
                    <TextField
                    type="email"
                      id="outlined-basic"
                      placeholder="Email"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Location*</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Location"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={clocation}
                      onChange={(e) => setCLocation(e.target.value)}
                    />
                  </Grid>
                </Grid>

                <Box sx={{ mb: 2 }}>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleNext1}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Next
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>
                <span style={{ fontSize: "1.2rem" }}>
                  {" "}
                  Primary Contact
                </span>
              </StepLabel>
              <StepContent>
                <Grid container spacing={2} style={{ margin: "1.2rem 0" }}>
                  <Grid item xs={12} md={6}>
                    <div>Employee*</div>
                   
                          <TextField
                            id="outlined-basic"
                            placeholder="Employee"
                            fullWidth
                            InputProps={{
                              className: classes.input,
                            }}
                            required={false}
                            variant="outlined"
                            value={employee}
                            onChange={(e) => setEmployee(e.target.value)}
                          />


                  </Grid>
                  
                </Grid>

                <Box sx={{ mb: 2 }}>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleNext2}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Next
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>
                <span style={{ fontSize: "1.2rem" }}>Primary Address</span>
              </StepLabel>
              <StepContent>
                <Grid container spacing={2} style={{ margin: "1.2rem 0" }}>
                  <Grid item xs={12} md={6}>
                    <div>Address</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Address"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Address 2</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Address 2"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={address2}
                      onChange={(e) => setAddress2(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>City</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="City"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>State</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="State"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Post code</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Post code"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={postalCode}
                      onChange={(e) => setPostalCode(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Country</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Country"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    />
                  </Grid>
                </Grid>

                <Box sx={{ mb: 2 }}>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Next
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>
                <span style={{ fontSize: "1.2rem" }}>Social Info</span>
              </StepLabel>
              <StepContent>
                <Grid container spacing={2} style={{ margin: "1.2rem 0" }}>
                  <Grid item xs={12} md={6}>
                    <div>Facebook</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Facebook"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={facebook}
                      onChange={(e) => setFacebook(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Instagram</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Instagram"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={instagram}
                      onChange={(e) => setInstagram(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Linked In</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Linked In"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={linkedIn}
                      onChange={(e) => setLinkedIn(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Twitter</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Twitter"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={twitter}
                      onChange={(e) => setTwitter(e.target.value)}
                    />
                  </Grid>
                </Grid>

                <Box sx={{ mb: 2 }}>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Next
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>
                <span style={{ fontSize: "1.2rem" }}>Bank Details</span>
              </StepLabel>
              <StepContent>
                <Grid container spacing={2} style={{ margin: "1.2rem 0" }}>
                  <Grid item xs={12} md={6}>
                    <div>Bank name</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Bank name"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>BSB</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="BSB"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={BSB}
                      onChange={(e) => setBSB(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Account number</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Account number"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={accountNumber}
                      onChange={(e) => setAccountNumber(e.target.value)}
                    />
                  </Grid>
                </Grid>

                <Box sx={{ mb: 2 }}>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Next
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
            
            
           
            <Step>
              <StepLabel>
                <span style={{ fontSize: "1.2rem" }}>Upload Company Logo</span>
              </StepLabel>
              <StepContent>
                <Grid container spacing={2} style={{ margin: "1.2rem 0" }}>
                  <Grid item xs={12} md={12}>
                    <DropzoneArea
                      showPreviews={true}
                      showPreviewsInDropzone={false}
                      useChipsForPreview
                      previewGridProps={{
                        container: { spacing: 1, direction: "row" },
                      }}
                      previewChipProps={{
                        classes: { root: classes.previewChip },
                      }}
                      acceptedFiles={['image/*']} 
                      filesLimit={1}
                      previewText="Selected files"
                      onChange={fileUploadHandler}
                      fileObjects={files}
                    />
                  </Grid>
                </Grid>

                <Box sx={{ mb: 2 }}>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                    <Button
                     
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Next
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          </Stepper>
        
        {activeStep == 6 && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <h3 style={{fontWeight:"500"}}>All steps completed - you&apos;re finished</h3 >
            <Button  type="submit" variant="contained" onClick={submitHandler} sx={{ mt: 1, mr: 1 }}>
              Submit
            </Button>
          </Paper>
        )}
        </form>
      </Box>
    </div>
  );
});

export default CreateNewBranchTab;

// <Box sx={{ maxWidth: "100%" }}>
// <Stepper activeStep={activeStep} orientation="vertical">
//   {steps.map((step, index) => (
//     <Step key={step.label}>
//       <StepLabel
//         optional={
//           index === 3 ? (
//             <Typography variant="caption">Last step</Typography>
//           ) : null
//         }
//       >
//         {step.label}
//       </StepLabel>
//       <StepContent>
//         <Typography>{step.description}</Typography>
//         <Box sx={{ mb: 2 }}>
//           <div>
//             <Button
//               disabled={index === 0}
//               onClick={handleBack}
//               sx={{ mt: 1, mr: 1 }}
//             >
//               Back
//             </Button>
//             <Button
//               variant="contained"
//               onClick={handleNext}
//               sx={{ mt: 1, mr: 1 }}
//             >
//               {index === steps.length - 1 ? "Finish" : "Next"}
//             </Button>
//           </div>
//         </Box>
//       </StepContent>
//     </Step>
//   ))}
// </Stepper>
// {activeStep === steps.length && (
//   <Paper square elevation={0} sx={{ p: 3 }}>
//     <Typography>All steps completed - you&apos;re finished</Typography>
//     <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
//       Reset
//     </Button>
//   </Paper>
// )}
// </Box>

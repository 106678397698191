import React from "react";
import CRMCompanyHeader from "../components/CRM/Companies/CRMCompanyHeader";
import CRMCompanyTable from "../components/CRM/Companies/CRMCompanyTable";
import UnderDevelopmentMessage from "../components/UnderDevelopment/UnderDevelopmentMessage";

function CRMCompany() {
  return (
    <div>
      <UnderDevelopmentMessage/>
      <CRMCompanyHeader />
      <CRMCompanyTable />
    </div>
  );
}

export default CRMCompany;

import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import BookingListHeader from "../components/My Bookings/BookingListHeader";
import BookingListTable from "../components/My Bookings/BookingListTable";
import UnderDevelopmentMessage from "../components/UnderDevelopment/UnderDevelopmentMessage";
// import { bookingData } from "../components/My Bookings/sampleBookingData";

function BookingList() {
  const [loading, setLoading] = useState(false);
const [bookingData, setBookingData] = useState([]);
  const [sortedBookingList, setSortedBookingList] = useState(bookingData);
  
  const fetchBookingList = () => {
    setLoading(true)
    axios.get("https://api.jsonbin.io/v3/b/6353b5fb0e6a79321e30da2d", {
      headers: {
        "Content-Type": "application/json",
        "x-master-key":
          "$2b$10$d7BPL3Fwd1Wc0qNbQJoX.uBCFuQ044KLhmWWnIW5qzTEFdybwIDK.",
      },
    }).then((res) => {
      setBookingData(res.data.record);
      setSortedBookingList(res.data.record);
      setLoading(false);
      console.log(res.data.record);
    }).catch((err) => {
      console.log(err);
    }
    )
  }
  useEffect(() => {
    fetchBookingList();
  }, []);


  return (
    <div>
      <UnderDevelopmentMessage dumData={true} />
      <BookingListHeader 
        bookingList={bookingData} 
        setSortedBookingList={setSortedBookingList}
      />
      <BookingListTable 
        loading={loading}
        bookingList={sortedBookingList}
        setSortedBookingList={setSortedBookingList}
      />
    </div>
  );
}

export default BookingList;

import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@mui/material";
import { withStyles } from "@material-ui/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import InviteEmployee from "../components/InviteEmployee";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import InviteEmployee from "../../Employees/Employees/InviteEmployee";
// custom style for input text field
const styles = (theme) => ({
  input: {
    height: 40,
    paddingRight: 0,
  },
});
const ReturnsHeader = withStyles(styles)((props) => {
  const { classes } = props;
  const [dob, setDob] = useState("");
  return (
    <div>
      {" "}
      <div
        className="header"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {" "}
        <div className="left-container">
          <div style={{ fontSize: "1.4rem" }}>Order returns</div> <br />
          <span>Search by order number, description</span>
          <br />
          <TextField
            id="outlined-basic"
            style={{ width: "450px" }}
            value={""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: "0 5px 5px 0",
                      backgroundColor: "#1a237e",
                      textTransform: "capitalize",
                      fontWeight: "600",
                      height: "2.5rem",
                    }}
                  >
                    Search
                  </Button>
                </InputAdornment>
              ),
              className: classes.input,
            }}
            variant="outlined"
          />
        </div>
        <div className="right-container" style={{ marginLeft: "1rem" }}>
          {/* <Button variant="contained" style={{backgroundColor:"#1a237e",textTransform:"capitalize", fontWeight:"600"}}><AddIcon/> Invite employee</Button> */}
          <div style={{ marginTop: "2.5rem" }}></div>
          <Stack spacing={1} direction="row" justifyContent="right">
            <div>
              <div style={{ marginTop: "1.2rem" }}>Return date</div>
              <FormControl fullWidth size="small">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={dob}
                    format="dd/MM/yyyy"
                    onChange={(newValue) => {
                      setDob(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        placeholder="DD/MM/YYYY"
                        fullWidth
                        variant="standard"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
            </div>
            <div>
              <div style={{ marginTop: "1.2rem" }}>Company</div>
              <FormControl fullWidth size="small">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value="All"
                  style={{ width: "16rem" }}
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  <MenuItem value={"Active"}>Unpaid</MenuItem>
                  <MenuItem value={"Inactive"}>Paid</MenuItem>
                  <MenuItem value={"Onboarding"}>Partially paid</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <div style={{ marginTop: "1.2rem" }}>Sort by latest</div>
              <FormControl fullWidth size="small">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value="All"
                  style={{ width: "10rem" }}
                >
                  <MenuItem value={"All"}>All orders</MenuItem>
                  <MenuItem value={"Active"}>Quoted</MenuItem>
                  <MenuItem value={"Inactive"}>Pending</MenuItem>
                  <MenuItem value={"Onboarding"}>Confirmed</MenuItem>
                  <MenuItem value={"Onboarding"}>Delivered</MenuItem>
                  <MenuItem value={"Onboarding"}>Void</MenuItem>
                </Select>
              </FormControl>
            </div>
          </Stack>
        </div>
      </div>
    </div>
  );
});

export default ReturnsHeader;

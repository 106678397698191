import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { withStyles, makeStyles } from "@material-ui/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import TablePagination from "@mui/material/TablePagination";
import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";
import { useSelector } from "react-redux";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Avatar, Box, TextField, useTheme } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Link, useHistory, useLocation } from "react-router-dom";
import TableLoader from "../../TableLoader";
import { RemoveRedEyeOutlined } from "@mui/icons-material";

// custom style for table
const tableStyles = makeStyles({
  tableRow: {
    height: 20,
  },
  tableCell: {
    padding: "10px 16px",
  },
  bodyCell: {
    padding: "6px 16px",
  },
});
const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 20,
  },
}))(TableRow);
const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "10px 5px",
  },
}))(TableCell);

const EmployeeTable = ({ employees, setEmployees, employeeLoading }) => {
  const classes = tableStyles();

  // state for pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [timesort, setTimeSort] = useState("Newest Employees");

  // function to handle pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const theme = useTheme();
  // function to handle the sorting
  const handleChange = (event) => {
    setTimeSort(event.target.value);
    if (timesort == "Oldest Employees") {
      const sorted = [...employees].sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setEmployees(sorted);
    }
    if (timesort == "Newest Employees") {
      const sorted = [...employees].sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
      console.log(sorted);
      setEmployees(sorted);
    }
  };

  // function to handle the number of rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
    },
  };

  async function fetchEmployees() {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}/api/employees`,
      config
    );
    setEmployees(data.employees);
  }
  useEffect(() => {
    fetchEmployees();
  }, []);

  const getAvatarName = (employee) => {
    if (employee.employeeDetail) {
      if (employee.employeeDetail.fname && employee.employeeDetail.lname) {
        return employee.employeeDetail.fname.charAt(0).toUpperCase() + employee.employeeDetail.lname.charAt(0).toUpperCase();
      }
      else if (employee.employeeDetail.fname) {
        return employee.employeeDetail.fname.charAt(0).toUpperCase();
      }
      else if (employee.employeeDetail.lname) {
        return employee.employeeDetail.lname.charAt(0).toUpperCase();
      }
    }
    else {
      return employee.email.charAt(0).toUpperCase();
    }
  }

  return (
    <div>
      <Paper
        sx={{
          marginTop: "1rem",
          borderRadius: "10px",
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        }}
      >
        <TableContainer sx={{ width: "100%",  }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600" }}
                  className={classes.tableCell}
                ></TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600" }}
                  className={classes.tableCell}
                >
                  Name
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600" }}
                  className={classes.tableCell}
                >
                  Email
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600" }}
                  className={classes.tableCell}
                >
                  Department
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600" }}
                  className={classes.tableCell}
                >
                  Mobile
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600" }}
                  className={classes.tableCell}
                >
                  Status
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600" }}
                  className={classes.tableCell}
                ></TableCell>
              </TableRow>
            </TableHead>

            {!employeeLoading && (
              <TableBody>
                {employees
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((employees, index) => (
                    employees.employeeDetail &&
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      className={classes.tableRow}
                    >
                      <TableCell className={classes.bodyCell} align="left">
                        {" "}
                        {/* <img
                          src={`https://eu.ui-avatars.com/api/?name=${employees.email.toUpperCase()}&background=random`}
                          alt="avatar"
                          style={{
                            width: "2.5rem",
                            marginRight: "0.5rem",
                            borderRadius: "50%",
                          }}
                        /> */}
                        <Avatar style={{
                          width: "2.5rem", height: "2.5rem", color: "#fff", fontWeight: "500", fontSize: "1rem",
                          backgroundColor: "#474d56", borderRadius: "20%", boxShadow: "0px 0px 5px 0px #1d1d1d74"
                        }}
                        >
                          {getAvatarName(employees)}
                        </Avatar>
                      </TableCell>
                      {/* <TableCell className={classes.bodyCell} >
                {employees.email}
              </TableCell> */}
                      <TableCell
                        className={classes.bodyCell}
                        align="left"
                        component="th"
                        scope="row"
                      >
                        <Link
                          to={`/employee/${employees.id}`}
                          style={{ textDecoration: "none",
                          color: theme.palette.text.primary
                        
                        }}
                        >
                          {employees.employeeDetail.fname != "N/A"
                            ? employees.employeeDetail.fname
                            : ""}{" "}
                          {employees.employeeDetail.lname != "N/A"
                            ? employees.employeeDetail.lname
                            : ""}
                        </Link>
                      </TableCell>
                      <TableCell className={classes.bodyCell} align="left">
                        {employees.email}
                      </TableCell>
                      <TableCell className={classes.bodyCell} align="left">
                        {/* <span
                          // style={{ fontWeight: "600", backgroundColor: "#EFEFEF", padding: "0.2rem 0.5rem", borderRadius: "5px" }}
                          // style={employees.role == "Admin" ? {color: "red"} : {color: "black"}}
                          style={
                            employees.department && employees.department.department == "Basic"
                              ? { fontWeight: "600", backgroundColor: "#EFEFEF", padding: "0.3rem 0.5rem", borderRadius: "5px" }
                              : { fontWeight: "600", backgroundColor: "skyblue", padding: "0.3rem 0.5rem", borderRadius: "5px" }
                          }

                        >
                          {employees.department && employees.department.department}
                        </span> */}
                        <Chip label={employees.department && employees.department.department} />
                      </TableCell>
                      <TableCell className={classes.bodyCell} align="left">
                        {employees.employeeDetail.mobNumber}
                      </TableCell>
                      <TableCell className={classes.bodyCell}>
                        {employees.flag.flag !== "Active" ? (
                          <Chip
                            label={
                              (employees.flag.flag == "Onboarding" ||
                                employees.flag.flag == "Email Sent" ||
                                employees.flag.flag == "Registered") ?
                                employees.flag.flag : "Inactive"
                            }
                          />
                        ) : (
                          <Chip
                            sx={{ backgroundColor: theme.palette.success.main, color: theme.palette.text.secondary}}
                              
                            label={employees.flag.flag}
                          />
                        )}
                      </TableCell>
                      <TableCell className={classes.bodyCell}>
                        {" "}
                        <Link
                          to={`/employee/${employees.id}`}
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          {userInfo.isAdmin === true || Object.keys(userInfo.permissionData).length !== 0 && userInfo.permissionData.Employee.write ? (
                            <EditIcon sx={{ color: theme.palette.text.primary}} />
                          ) : (
                            <RemoveRedEyeOutlined sx={{ color: theme.palette.text.primary}} />
                          )}
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
      {employeeLoading && <TableLoader />}

      <Box
        style={{
          display: "flex",
          justifyContent: "right",
          alignItems: "center",
          marginTop: "2rem",
        }}
      >
        <FormControl size="small">
          <TextField
            id="demo-simple-select-helper"
            value={timesort}
            onChange={handleChange}
            select
            label="Sort by"
            size="small"
          >
            <MenuItem value={"Newest Employees"}>Newest Employees</MenuItem>
            <MenuItem value={"Oldest Employees"}>Oldest Employees</MenuItem>
          </TextField>
        </FormControl>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={employees.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{ fontWeight: "700" }}
        ></TablePagination>
      </Box>
    </div>
  );
};

export default EmployeeTable;

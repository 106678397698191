import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { withStyles, makeStyles } from "@material-ui/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import TablePagination from "@mui/material/TablePagination";
import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";
import { useSelector } from "react-redux";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Box, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Link, useHistory, useLocation } from "react-router-dom";
import TableLoader from "../../TableLoader";

// custom style for table
const tableStyles = makeStyles({
  tableRow: {
    height: 20,
  },
  tableCell: {
    padding: "10px 16px",
  },
  bodyCell: {
    padding: "6px 16px",
  },
});
const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 20,
  },
}))(TableRow);
const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "10px 5px",
  },
}))(TableCell);

const CRMContactTable = () => {
  const classes = tableStyles();

  // state for pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // function to handle pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // function to handle the number of rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <div>
      <Paper
        sx={{
          marginTop: "1rem",
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        }}
      >
        <TableContainer sx={{ width: "100%", overflowX: "hidden" }}>
          <Table sx={{ minWidth: "90vw" }} aria-label="simple table">
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600" }}
                  className={classes.tableCell}
                ></TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600" }}
                  className={classes.tableCell}
                >
                  Name
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600" }}
                  className={classes.tableCell}
                >
                  Mobile
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600" }}
                  className={classes.tableCell}
                >
                  Source
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600" }}
                  className={classes.tableCell}
                >
                  Type
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600" }}
                  className={classes.tableCell}
                >
                  Status
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600" }}
                  className={classes.tableCell}
                >
                  Subscribed
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600" }}
                  className={classes.tableCell}
                >
                  Created
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600" }}
                  className={classes.tableCell}
                ></TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Paper>
      <Box
        style={{
          display: "flex",
          justifyContent: "right",
          alignItems: "center",
          marginTop: "2rem",
        }}
      >
        <FormControl size="small">
          <TextField
            id="demo-simple-select-helper"
            select
            value="Newest Companies"
            label="Sort by"
            size="small"
            style={{ width: "12rem" }}
          >
            <MenuItem value={"Newest Employees"}>Newest contacts</MenuItem>
            <MenuItem value={"Oldest Employees"}>Oldest contacts</MenuItem>
          </TextField>
        </FormControl>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={10}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{ fontWeight: "700" }}
        ></TablePagination>
      </Box>
    </div>
  );
};

export default CRMContactTable;

import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import axios from "axios";
import Chip from "@mui/material/Chip";
import SuccessAlerts from "../../SuccessAlert";
import ErrorAlerts from "../../ErrorAlert";
import { CircularProgress, InputAdornment, TextField, useTheme } from "@mui/material";
import { TailSpin } from "react-loader-spinner";
import { Search } from "@mui/icons-material";
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}


function AllBranchesTab() {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [branches, setBranches] = useState([]);
  const [allBranches, setAllBranches] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
    },
  };
  function fetchAllBranches() {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_BACKEND}/api/branches`, config)
      .then((res) => {
        setLoading(false);
        res.data.branches.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        
        setAllBranches(res.data.branches);
        setBranches(res.data.branches);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  const handleSearch = (e) => {
    let value = e.target.value;
    if (value == "") {
      setBranches(allBranches);
      return;
    }
    let searchedBranches = allBranches.filter((branch) => {
      return branch.name.toLowerCase().includes(value.toLowerCase());
    });
    setBranches(searchedBranches);
  };


  const switchBranch = (branch) => {
    window.scrollTo(0, 0);

    axios
      .get(
        `${process.env.REACT_APP_API_BACKEND}/api/branch/switch/${branch}`,
        config
      )
      .then((res) => {
        setSuccess(res.data.message);
        localStorage.setItem("userInfo", JSON.stringify(res.data));
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setError(err.response.data.message);
      });
  };
  if (success) {
    setTimeout(() => {
      setSuccess("");
      window.location.reload(false);
    }, 500);
  }
  if (error) {
    setTimeout(() => {
      setError("");
    }, 3000);
  }
  useEffect(() => {
    fetchAllBranches();
  }, []);
  const theme = useTheme();
  return (
    <div>
      <div
        style={{
          textAlign: "center",
          margin: "auto",
          marginTop: "1rem",
          zIndex: "1000",
          position: "absolute",
          right: "36vw",
          top: "10vh",
        }}
      >
        {error && <ErrorAlerts error={error} />}
        {success && <SuccessAlerts success={success} />}
      </div>
      <h2 style={{ fontWeight: "500" }}>My workspaces</h2>

      {
        //length of branches is greater than 5
        allBranches.length > 5 &&
        <TextField
        id="outlined-basic"
        label="Search"
        size="small"
        variant="outlined"
        onChange={handleSearch}
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        style={{ width: "100%", marginBottom: "1rem" }}
      
      />}
      {!loading && branches == "" && <h2 className="loader-companies">No branches found.</h2>}
      {loading ? (
        <div className="loader-companies">
          <TailSpin color={theme.palette.primary.main}  height={120} width={120}  />
        </div>
      ) : (
        

          <Table sx={{ 
          backgroundColor: "none",
          '&.MuiTable-root': {
            borderRadius: "10px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            backgroundColor: "none",
            border: "none",
          },
          }} aria-label="simple table">
            
            <TableBody sx={{backgroundColor: "none",border:"none"}}>


              {branches && branches.map((branch) => (
                <TableRow
                  key={branch.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    padding: 0,
                    '&:nth-of-type(odd)': { backgroundColor: theme.palette.card.bg },
                    '&:nth-of-type(even)': { backgroundColor: theme.palette.card.alt },
                    '&.MuiTableRow-root ': { borderRadius: "10px" },
                    border: "none",
                  }}
                  style={{ padding: "0px" }}
                >
                  <TableCell
                    style={{
                      width: "6%",
                      padding: "8px",
                      paddingBottom: "3px",
                      borderRadius: "10px 0px 0px 10px",
                      border: "none",
                    }}
                  >
                    <img
                      src={branch.logo}
                      style={{
                        width: "3rem",
                        height: "3rem",
                        margin: "0",
                        padding: "0",
                        borderRadius: "10px"
                      }}
                    />
                  </TableCell>
                  <TableCell
                    style={{ width: "80%", fontSize: "1.2rem", padding: "8px", border: "none" }}
                  >
                    {branch.company.name}
                    {" - "}
                    {branch.name}
                    {""}
                    {userInfo.currentBranchId == branch.id && (
                      <Chip
                        sx={{
                          marginLeft: "1rem",
                          backgroundColor: "#22baee",
                          color: "white",
                        }}
                        label="Current"
                      />
                    )}
                  </TableCell>
                  <TableCell align="right" style={{ padding: "8px", borderRadius: "0px 10px 10px 0px", border: "none" }}>
                    {" "}
                    {userInfo.currentBranchId != branch.id && (
                      <Button
                        variant="contained"
                        style={{
                          textTransform: "none",
                          fontSize: "0.9rem",
                          fontWeight: "600",
                        }}
                        onClick={() => {
                          switchBranch(branch.id);
                        }}
                      >
                        Switch to this
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
      )}
    </div>
  );
}

export default AllBranchesTab;

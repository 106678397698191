import React, { useState } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Profile from "../EmployeeDetail/Profile";
import Role from "../EmployeeDetail/Role";
import Emergency from "../EmployeeDetail/Emergency";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import TimeLine from "../EmployeeDetail/ShiftTimeLine.js";
import ScheduleTimeline from "../EmployeeDetail/ScheduleTimeline";
import { useTheme } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    sx: { color: "text.primary" },
  };
}

function EmployeeDetail() {
  const location = useLocation();
  const urlSplit = location.pathname.split("/employee/");
  const id = urlSplit[1];
  const [value, setValue] = React.useState(0);

  // getting logged in user info
  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;
  const history = useHistory();
  useEffect(() => {

    // if (userInfo.flag == 'Registered') {
    //   history.push('/onboard')
    // }
    // if (userInfo.flag == 'Onboarding') {
    //   history.push('/registered')
    // }
  }, [userInfo])
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const theme = useTheme();
  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{ color: theme.palette.primary.main ,
               "& .MuiTabs-indicator": {
                backgroundColor: "theme.palette.primary.main,",
                borderRadius: "10px 10px 0px 0px",
                height: "4px",
               }
            }}

          >
            <Tab
              sx={{ px: 5, textTransform: "capitalize" }}
              label="Profile"
              {...a11yProps(0)}
            />
            <Tab
              sx={{ px: 5, textTransform: "capitalize" }}
              label="Timeline"
              {...a11yProps(1)}
            />
            <Tab
              sx={{ px: 5, textTransform: "capitalize" }}
              label="Schedule"
              {...a11yProps(2)}
            />
            {/* <Tab
              sx={{ px: 5, textTransform: "capitalize" }}
              label="Role"
              {...a11yProps(3)}
            />
            <Tab
              sx={{ px: 5, textTransform: "capitalize" }}
              label="Emergency"
              {...a11yProps(4)}
            />
            <Tab
              sx={{ px: 5, textTransform: "capitalize" }}
              label="Notes"
              {...a11yProps(5)}
            />
            <Tab
              sx={{ px: 5, textTransform: "capitalize" }}
              label="Documents"
              {...a11yProps(6)}
            /> */}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0} sx={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
          <Profile id={id} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TimeLine id={id} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ScheduleTimeline id={id} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Role id={id} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Emergency id={id} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <div style={{ height: "78vh", width: "80vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <h2>Under Development</h2>
          </div>
        </TabPanel>
        <TabPanel value={value} index={6}>
          <div style={{ height: "78vh", width: "80vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <h2>Under Development</h2>
          </div>
        </TabPanel>
      </Box>
    </div>
  );
}

export default EmployeeDetail;


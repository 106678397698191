import React from "react";
import InventoryHeader from "../components/Purchase Orders/Inventory/InventoryHeader";
import InventoryTable from "../components/Purchase Orders/Inventory/InventoryTable";
import UnderDevelopmentMessage from "../components/UnderDevelopment/UnderDevelopmentMessage";

function Inventory() {
  return (
    <div>
      <UnderDevelopmentMessage/>
    <div
      style={{
        minWidth: "1280px",
        overflowX: "auto",
        overflowY: "hidden",
        whiteSpace: "nowrap",
        display: "inline-block",
      }}
    >
      <InventoryHeader />
      <InventoryTable />
    </div>
    </div>
  );
}

export default Inventory;

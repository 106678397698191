import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { withStyles, styled } from "@material-ui/styles";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { DropzoneArea } from "react-mui-dropzone";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { parseISO } from "date-fns";
import { useTheme } from "@mui/material";
import dayjs from "dayjs";

// custom styling for input text field
const styles = (theme) => ({
  input: {
    height: 40,
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
  },
});

const OnboardingForm = withStyles(styles)((props) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;
  const { classes } = props;

  // state for handling step count
  const [activeStep, setActiveStep] = useState(0);
  const [files, setFiles] = useState([]);
  const [value, setValue] = useState("");
  const [title, setTitle] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [emailWork, setEmailWork] = useState("");
  const [emailPersonal, setEmailPersonal] = useState(userInfo.user);
  const [mobNumber, setMobNumber] = useState("");
  const [dob, setDob] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("Single");
  const [gender, setGender] = useState("Male");
  const [medicareNumber, setMedicareNumber] = useState("");
  const [drivingLicense, setDrivingLicense] = useState("");
  const [passportNumber, setPassportNumber] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");
  const [bankName, setBankName] = useState("");
  const [bsb, setBsb] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [taxFileNumber, setTaxFileNumber] = useState("");
  const [workingRights, setWorkingRights] = useState("Sponsored");
  const [eFname, setEFname] = useState("");
  const [eLname, setELname] = useState("");
  const [eEmail, setEEmail] = useState("");
  const [eMobile, setEMobile] = useState("");
  const [eWorkPhone, setEWorkPhone] = useState("");
  const [eContactType, setEContactType] = useState("Family");
  const [linkedin, setLinkedin] = useState("");
  const history = useHistory();
  const location = useLocation();

  // function to go to next step
  const handleNext1 = () => {
    //check if all fields are filled out
    if (
      title !== "" &&
      fname !== "" &&
      lname !== "" &&
      emailPersonal !== "" &&
      mobNumber !== ""
    ) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      alert("Please fill out all fields");
    }
  };
  const handleNext2 = () => {
    if (dob !== "") {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      alert("Please fill out Date of Birth");
    }
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // function to go to previous step
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  //function to reste all the step count
  const handleReset = () => {
    setActiveStep(0);
  };

  const fileUploadHandler = (newFiles) => {
    console.log(newFiles);
    setFiles(newFiles);
    // setFiles(newFiles);
    // console.log(files);
  };

  useEffect(() => {
    if (userInfo.flag == "Registered") {
      history.push("/onboard");
    }
    if (userInfo.flag == "Onboarding") {
      history.push("/registered");
    }
  }, [userInfo]);
  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    formData.append("fname", fname);
    formData.append("lname", lname);
    formData.append("workEmail", emailWork);
    formData.append("personalEmail", emailPersonal);
    formData.append("mobNumber", mobNumber);
    formData.append("maritalStatus", maritalStatus);
    formData.append("gender", gender);
    formData.append("medicareNumber", medicareNumber);
    formData.append("driversLicense", drivingLicense);
    formData.append("passportNumber", passportNumber);
    formData.append("DOB", dob);
    formData.append("address", address);
    formData.append("address2", address2);
    formData.append("city", city);
    formData.append("state", state);
    formData.append("postCode", postalCode);
    formData.append("country", country);
    formData.append("bankName", bankName);
    formData.append("BSB", bsb);
    formData.append("accountNumber", accountNumber);
    formData.append("workingRights", workingRights);
    formData.append("taxFileNumber", taxFileNumber);
    formData.append("firstName", eFname);
    formData.append("lastName", eLname);
    formData.append("email", eEmail);
    formData.append("mobile", eMobile);
    formData.append("workPhone", eWorkPhone);
    formData.append("contactType", eContactType);
    formData.append("linkedIn", linkedin);
    // formData.append("files", files);
    // files.forEach((file) => {
    //   formData.append("files", file); // appending image one by one for the same key
    // });

    console.log(files);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
      },
    };

    axios
      .patch(
        `${process.env.REACT_APP_API_BACKEND}/api/employee/detailsform`,
        formData,
        config
      )
      .then((res) => {
        console.log(res.data.message);
        userInfo.flag = "Onboarding";
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        history.push("/registered");
      })
      .catch((err) => {
        // console.log(err.response.data.message);
        console.log(err.response.data);
        // console.log(errormessage);
      });
  };
  const theme = useTheme();

  return (
    <div>
      <span style={{ fontSize: "1.3rem" }}>
        Please Complete your details below
      </span>
      <Box
        sx={{ maxWidth: "100%" }}
        style={{
          margin: "2.5rem 1.5rem",
          backgroundColor: theme.palette.card.bg,
          borderRadius: "10px",
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        }}
      >
        <form onSubmit={submitHandler}>
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            style={{ padding: "1rem 2rem" }}
          >
            <Step>
              <StepLabel>
                <span style={{ fontSize: "1.2rem" }}> Name and Contact</span>
              </StepLabel>
              <StepContent>
                <Grid container spacing={2} style={{ margin: "1.2rem 0" }}>
                  <Grid item xs={12} md={6}>
                    <div>Title*</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Title"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required
                      variant="outlined"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>First name*</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="First name"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required
                      variant="outlined"
                      value={fname}
                      onChange={(e) => setFname(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Last name*</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Last name"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required
                      variant="outlined"
                      value={lname}
                      onChange={(e) => setLname(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Email work*</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Email Work"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required
                      variant="outlined"
                      value={emailWork}
                      onChange={(e) => setEmailWork(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Email personal</div>
                    <TextField
                      id="outlined-basic"
                      placeholder={userInfo.user}
                      fullWidth
                      InputProps={{
                        className: classes.input,
                        readOnly: true,
                      }}
                      required={false}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Mobile Number*</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Mobile Number"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={mobNumber}
                      onChange={(e) => setMobNumber(e.target.value)}
                    />
                  </Grid>
                </Grid>

                <Box sx={{ mb: 2 }}>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleNext1}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Next
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>
                <span style={{ fontSize: "1.2rem" }}>
                  {" "}
                  Personal Information
                </span>
              </StepLabel>
              <StepContent>
                <Grid container spacing={2} style={{ margin: "1.2rem 0" }}>
                  <Grid item xs={12} md={6}>
                    <div>Date of birth*</div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={parseISO(dob)}
                        format="dd/MM/yyyy"
                        defaultValue={dayjs(dob).format("DD/MM/YYYY")}
                        onChange={(newValue) => {
                          setDob(newValue);
                        }}
                        sx={{ bgcolor: theme.palette.card.bg , 
                          color: theme.palette.text.primary,
                          '& .MuiPickersCalendarHeader-switchHeader': {
                              color: theme.palette.text.primary,
                          },
                          '& .MuiDayCalendar-weekNumber': {
                              color: `${theme.palette.text.primary} !important`,
                          }
                      }}

                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Marital status</div>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Age"
                      fullWidth
                      input={<OutlinedInput />}
                      size="small"
                      onChange={(e) => setMaritalStatus(e.target.value)}
                      value={maritalStatus}
                    >
                      <MenuItem value={"Single"}>Single</MenuItem>
                      <MenuItem value={"Married"}>Married</MenuItem>
                      <MenuItem value={"Divorced"}>Divorced</MenuItem>
                      <MenuItem value={"Defacto"}>Defacto</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Gender</div>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Age"
                      fullWidth
                      input={<OutlinedInput />}
                      size="small"
                      onChange={(e) => setGender(e.target.value)}
                      value={gender}
                    >
                      <MenuItem value={"Male"}>Male</MenuItem>
                      <MenuItem value={"Female"}>Female</MenuItem>
                      <MenuItem value={"Not Stated"}>Not Stated</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Medicare number</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Medicare number"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={medicareNumber}
                      onChange={(e) => setMedicareNumber(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Drivers licence</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Drivers licence"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={drivingLicense}
                      onChange={(e) => setDrivingLicense(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Passport number</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Passport number"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={passportNumber}
                      onChange={(e) => setPassportNumber(e.target.value)}
                    />
                  </Grid>
                </Grid>

                <Box sx={{ mb: 2 }}>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleNext2}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Next
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>
                <span style={{ fontSize: "1.2rem" }}>Address Details</span>
              </StepLabel>
              <StepContent>
                <Grid container spacing={2} style={{ margin: "1.2rem 0" }}>
                  <Grid item xs={12} md={6}>
                    <div>Address</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Address"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Address 2</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Address 2"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={address2}
                      onChange={(e) => setAddress2(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>City</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="City"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>State</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="State"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Post code</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Post code"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={postalCode}
                      onChange={(e) => setPostalCode(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Country</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Country"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    />
                  </Grid>
                </Grid>

                <Box sx={{ mb: 2 }}>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Next
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>
                <span style={{ fontSize: "1.2rem" }}>Bank Details</span>
              </StepLabel>
              <StepContent>
                <Grid container spacing={2} style={{ margin: "1.2rem 0" }}>
                  <Grid item xs={12} md={6}>
                    <div>Bank name</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Bank name"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>BSB</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="BSB"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={bsb}
                      onChange={(e) => setBsb(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Account number</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Account number"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={accountNumber}
                      onChange={(e) => setAccountNumber(e.target.value)}
                    />
                  </Grid>
                </Grid>

                <Box sx={{ mb: 2 }}>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Next
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>
                <span style={{ fontSize: "1.2rem" }}>Tax Info</span>
              </StepLabel>
              <StepContent>
                <Grid container spacing={2} style={{ margin: "1.2rem 0" }}>
                  <Grid item xs={12} md={6}>
                    <div>Tax file number</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Tax file number"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={taxFileNumber}
                      onChange={(e) => setTaxFileNumber(e.target.value)}
                    />
                  </Grid>
                </Grid>

                <Box sx={{ mb: 2 }}>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Next
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>
                <span style={{ fontSize: "1.2rem" }}>Working Rights</span>
              </StepLabel>
              <StepContent>
                <Grid container spacing={2} style={{ margin: "1.2rem 0" }}>
                  <Grid item xs={12} md={6}>
                    <div>Working Rights</div>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Age"
                      fullWidth
                      input={<OutlinedInput />}
                      size="small"
                      value={workingRights}
                      onChange={(e) => setWorkingRights(e.target.value)}
                    >
                      <MenuItem value={"Australian Citizen"}>
                        Australian Citizen
                      </MenuItem>
                      <MenuItem value={"Holiday Visa"}>Holiday Visa</MenuItem>
                      <MenuItem value={"Partner Visa"}>Partner Visa</MenuItem>
                      <MenuItem value={"Permanent Resident"}>
                        Permanent Resident
                      </MenuItem>
                      <MenuItem value={"Sponsored"}>Sponsored</MenuItem>
                      <MenuItem value={"Student Visa"}>Student Visa</MenuItem>
                      <MenuItem value={"Visa 408"}>Visa 408</MenuItem>
                      <MenuItem value={"Visa 485"}>Visa 485</MenuItem>
                    </Select>
                  </Grid>
                </Grid>

                <Box sx={{ mb: 2 }}>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Next
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>
                <span style={{ fontSize: "1.2rem" }}>Emergency</span>
              </StepLabel>
              <StepContent>
                <Grid container spacing={2} style={{ margin: "1.2rem 0" }}>
                  <Grid item xs={12} md={6}>
                    <div>First name</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="First name"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={eFname}
                      onChange={(e) => setEFname(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Last name</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Last name"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={eLname}
                      onChange={(e) => setELname(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Email</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Email"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={eEmail}
                      onChange={(e) => setEEmail(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Mobile</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Mobile"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={eMobile}
                      onChange={(e) => setEMobile(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Work phone</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Work phone"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={eWorkPhone}
                      onChange={(e) => setEWorkPhone(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Contact type</div>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Age"
                      fullWidth
                      input={<OutlinedInput />}
                      size="small"
                      value={eContactType}
                      onChange={(e) => setEContactType(e.target.value)}
                    >
                      <MenuItem value={"Family"}>Family</MenuItem>
                      <MenuItem value={"Friend"}>Friend</MenuItem>
                      <MenuItem value={"Partner"}>Partner</MenuItem>
                    </Select>
                  </Grid>
                </Grid>

                <Box sx={{ mb: 2 }}>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Next
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>
                <span style={{ fontSize: "1.2rem" }}>Social Info</span>
              </StepLabel>
              <StepContent>
                <Grid container spacing={2} style={{ margin: "1.2rem 0" }}>
                  <Grid item xs={12} md={6}>
                    <div>Linked In</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Linked In"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={linkedin}
                      onChange={(e) => setLinkedin(e.target.value)}
                    />
                  </Grid>
                </Grid>

                <Box sx={{ mb: 2 }}>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Next
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>
                <span style={{ fontSize: "1.2rem" }}>Upload Documents</span>
              </StepLabel>
              <StepContent>
                <Grid container spacing={2} style={{ margin: "1.2rem 0" }}>
                  <Grid item xs={12} md={12}>
                    <DropzoneArea
                      showPreviews={true}
                      showPreviewsInDropzone={false}
                      useChipsForPreview
                      previewGridProps={{
                        container: { spacing: 1, direction: "row" },
                      }}
                      previewChipProps={{
                        classes: { root: classes.previewChip },
                      }}
                      previewText="Selected files"
                      onChange={fileUploadHandler}
                      fileObjects={files}
                    />
                  </Grid>
                </Grid>

                <Box sx={{ mb: 2 }}>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={submitHandler}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Finish
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          </Stepper>
        </form>
        {activeStep === 3 && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Reset
            </Button>
          </Paper>
        )}
      </Box>
    </div>
  );
});

export default OnboardingForm;

// <Box sx={{ maxWidth: "100%" }}>
// <Stepper activeStep={activeStep} orientation="vertical">
//   {steps.map((step, index) => (
//     <Step key={step.label}>
//       <StepLabel
//         optional={
//           index === 3 ? (
//             <Typography variant="caption">Last step</Typography>
//           ) : null
//         }
//       >
//         {step.label}
//       </StepLabel>
//       <StepContent>
//         <Typography>{step.description}</Typography>
//         <Box sx={{ mb: 2 }}>
//           <div>
//             <Button
//               disabled={index === 0}
//               onClick={handleBack}
//               sx={{ mt: 1, mr: 1 }}
//             >
//               Back
//             </Button>
//             <Button
//               variant="contained"
//               onClick={handleNext}
//               sx={{ mt: 1, mr: 1 }}
//             >
//               {index === steps.length - 1 ? "Finish" : "Next"}
//             </Button>
//           </div>
//         </Box>
//       </StepContent>
//     </Step>
//   ))}
// </Stepper>
// {activeStep === steps.length && (
//   <Paper square elevation={0} sx={{ p: 3 }}>
//     <Typography>All steps completed - you&apos;re finished</Typography>
//     <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
//       Reset
//     </Button>
//   </Paper>
// )}
// </Box>

import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { withStyles, makeStyles } from "@material-ui/styles";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import axios from "axios";
import SuccessAlerts from "../SuccessAlert";
import ErrorAlerts from "../ErrorAlert";
import { DropzoneArea } from "react-mui-dropzone";
import { MenuItem, Select, TextField, useTheme } from "@mui/material";
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  borderRadius: "20px",
}));

// custom style for table
const tableStyles = makeStyles({
  tableRow: {
    height: 10,
  },
  tableCell: {
    padding: "8px 0",
  },
  bodyCell: {
    padding: "5px 16px",
  },
  input: {
    height: 40,
  },
});
const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 20,
  },
}))(TableRow);
const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "10px 5px",
  },
}))(TableCell);

export default function InformationTab() {
  const classes = tableStyles();
  const theme = useTheme();
  const [edit, setEdit] = useState(0);
  const editHandler = () => {
    if (edit == 0) {
      setEdit(1);
    } else {
      setEdit(0);
    }
  };
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [branch, setBranch] = useState([]);
  const [branchName, setBranchName] = useState("");
  const [ABN, setABN] = useState("");
  const [website, setWebsite] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const [employee, setEmployee] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postCode, setPostCode] = useState("");
  const [country, setCountry] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [twitter, setTwitter] = useState("");
  const [bankName, setBankName] = useState("");
  const [BSB, setBSB] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [files, setFiles] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(true);
  const [requireApproval, setRequireApproval] = useState(false);
  function fetchBranch() {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
      },
    };
    axios
      .get(`${process.env.REACT_APP_API_BACKEND}/api/branch`, config)
      .then((res) => {
        setBranch(res.data.branch);
        setBranchName(res.data.branch.name);
        setABN(res.data.branch.ABN);
        setWebsite(res.data.branch.website);
        setPhone(res.data.branch.phone);
        setEmail(res.data.branch.email);
        setLocation(res.data.branch.location);
        setEmployee(res.data.branch.employee);
        setAddress(res.data.branch.address);
        setAddress2(res.data.branch.address2);
        setCity(res.data.branch.city);
        setState(res.data.branch.state);
        setPostCode(res.data.branch.postCode);
        setCountry(res.data.branch.country);
        setFacebook(res.data.branch.facebook);
        setInstagram(res.data.branch.instagram);
        setLinkedIn(res.data.branch.linkedIn);
        setTwitter(res.data.branch.twitter);
        setBankName(res.data.branch.bankName);
        setBSB(res.data.branch.BSB);
        setAccountNumber(res.data.branch.accountNumber);
        setRequireApproval(res.data.branch.requireApproval);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }
  const fileUploadHandler = (newFiles) => {
    setFiles(newFiles);
  };
  function updateBranchDetails() {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
      },
    };
    const formData = new FormData();
    const file = files[0];
    formData.append("name", branchName);
    formData.append("ABN", ABN);
    formData.append("website", website);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("location", location);
    formData.append("employee", employee);
    formData.append("address", address);
    formData.append("address2", address2);
    formData.append("city", city);
    formData.append("state", state);
    formData.append("postCode", postCode);
    formData.append("country", country);
    formData.append("facebook", facebook);
    formData.append("instagram", instagram);
    formData.append("linkedIn", linkedIn);
    formData.append("twitter", twitter);
    formData.append("bankName", bankName);
    formData.append("BSB", BSB);
    formData.append("accountNumber", accountNumber);
    formData.append("branchLogo", file);
    formData.append("requireApproval", requireApproval); 
    setLoading(true);

    axios
      .patch(`${process.env.REACT_APP_API_BACKEND}/api/branch/edit/${userInfo.currentBranchId}`, formData, config)
      .then((res) => {
        console.log(res.data);
        setSuccess(res.data.message);
        localStorage.setItem("userInfo", JSON.stringify(res.data));
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response.data.message);
      });
  }
  if (success) {
    setTimeout(() => {
      setSuccess("");
      window.location.reload(false);
    }, 500);
  }
  if (error) {
    setTimeout(() => {
      setError("");
    }, 3000);
  }
  useEffect(() => {
    fetchBranch();
    setTimeout(() => {
      setLoading(false);
    }
    , 1000);

  }, []);
  return (
    <div>
      <div
        style={{
          textAlign: "center",
          margin: "auto",
          marginTop: "1rem",
          zIndex: "1000",
          position: "absolute",
          right: "36vw",
          top: "10vh",
        }}
      >
        {error && <ErrorAlerts error={error} />}
        {success && <SuccessAlerts success={success} />}
      </div>
      {edit == 0 && branch != "" && !loading && (
        <div>
          <Stack
            spacing={1}
            mb={2}
            mt={1}
            direction="row"
            justifyContent="right"
          >
            <Button
              variant="contained"
              style={{ textTransform: "none" }}
              onClick={editHandler}
            >
              Edit Information
            </Button>
          </Stack>
        </div>
      )}
      {edit == 1 && (
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                marginTop: "0.7rem",
                color: "#32c0f0",
                fontSize: "1.2rem",
              }}
             
            >
              Please save your changes.
            </div>
            <Stack
              spacing={1}
              mb={2}
              mt={1}
              direction="row"
              justifyContent="space-between"
            >
              <Button
                variant="contained"
                style={{
                  textTransform: "none",
                  backgroundColor: "#e0e0e0",
                  color: "#000000",
                  fontWeight: "600",
                  margin: "0 0.2rem",
                }}
                onClick={editHandler}
              >
                Cancel changes
              </Button>
              <Button
                variant="contained"
                style={{ textTransform: "none", margin: "0 0.2rem" }}
                // onClick={submitHandler}
                onClick={updateBranchDetails}
              >
                Save changes
              </Button>
            </Stack>
          </div>
        </div>
      )}
      {!loading && branch == "" && <h3>No branch found.</h3>}
      {edit == 0 && (
        <div>
          {loading ? (
            <div className="loader-companies">
              <TailSpin color={theme.palette.primary.main} height={120} width={120} />
            </div>
          ) : (
            <>
              {branch != "" && (
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={4}>
                      <Item>
                        {" "}
                        <TableContainer>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow className={classes.tableRow}>
                                <TableCell
                                  className={classes.tableCell}
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Branch Information
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                  align="right"
                                ></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow
                                className={classes.tableRow}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  className={classes.tableCell}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  Branch Name
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {branch.name}
                                </TableCell>
                              </TableRow>
                              <TableRow
                                className={classes.tableRow}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  className={classes.tableCell}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  ABN
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {branch.ABN}
                                </TableCell>
                              </TableRow>
                              <TableRow
                                className={classes.tableRow}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  className={classes.tableCell}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  Website
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {branch.website}
                                </TableCell>
                              </TableRow>
                              <TableRow
                                className={classes.tableRow}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  className={classes.tableCell}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  Phone
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {" "}
                                  {branch.phone}
                                </TableCell>
                              </TableRow>
                              <TableRow
                                className={classes.tableRow}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  className={classes.tableCell}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  Email
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {branch.email}
                                </TableCell>
                              </TableRow>
                              <TableRow
                                className={classes.tableRow}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  className={classes.tableCell}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  Location
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {branch.location}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Item>
                      <Item sx={{ mt: 2 }}>
                        {" "}
                        <TableContainer>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow className={classes.tableRow}>
                                <TableCell
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                  className={classes.tableCell}
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "1.1rem",
                                  }}
                                >
                                  Logo
                                </TableCell>
                              </TableRow>
                              <TableRow className={classes.tableRow}>
                                <TableCell
                                  className={classes.tableCell}
                                  style={{ textAlign: "center" }}
                                  align="right"
                                >
                                  {" "}
                                  <img
                                    style={{ width: "10rem" }}
                                    src={branch.logo}
                                    alt=""
                                  />
                                </TableCell>
                              </TableRow>
                            </TableHead>
                          </Table>
                        </TableContainer>
                      </Item>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Item>
                        <TableContainer>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow className={classes.tableRow}>
                                <TableCell
                                  className={classes.tableCell}
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "1.1rem",
                                  }}
                                >
                                  Primary Contact
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                  align="right"
                                ></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow
                                className={classes.tableRow}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  className={classes.tableCell}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  Employee
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {branch.employee}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Item>
                      <Item sx={{ mt: 2 }}>
                        <TableContainer>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow className={classes.tableRow}>
                                <TableCell
                                  className={classes.tableCell}
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "1.1rem",
                                  }}
                                >
                                  Primary Address
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                  align="right"
                                ></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow
                                className={classes.tableRow}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  className={classes.tableCell}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  Address
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {branch.address}
                                </TableCell>
                              </TableRow>
                              <TableRow
                                className={classes.tableRow}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  className={classes.tableCell}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  Address 2
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {branch.address2}
                                </TableCell>
                              </TableRow>
                              <TableRow
                                className={classes.tableRow}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  className={classes.tableCell}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  City
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {branch.city}
                                </TableCell>
                              </TableRow>
                              <TableRow
                                className={classes.tableRow}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  className={classes.tableCell}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  State
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  {branch.state}
                                </TableCell>
                              </TableRow>
                              <TableRow
                                className={classes.tableRow}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  className={classes.tableCell}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  Post Code
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  {branch.postCode}
                                </TableCell>
                              </TableRow>
                              <TableRow
                                className={classes.tableRow}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  className={classes.tableCell}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  Country
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  {branch.country}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Item>
                      <Item sx={{ mt: 2 }}>
                        <TableContainer>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow className={classes.tableRow}>
                                <TableCell
                                  className={classes.tableCell}
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "1.1rem",
                                  }}
                                >
                                  Kiosk Information
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                  align="right"
                                ></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow
                                className={classes.tableRow}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  className={classes.tableCell}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  Kiosk ID
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {branch.kioskId}
                                </TableCell>
                              </TableRow>
                              <TableRow
                                className={classes.tableRow}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  className={classes.tableCell}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  Code
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {branch.code}
                                </TableCell>
                              </TableRow>
                              <TableRow
                                className={classes.tableRow}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  className={classes.tableCell}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  Require Approval
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {branch.requireApproval ? "Yes" : "No"}
                                </TableCell>
                              </TableRow>

                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Item>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Item>
                        <TableContainer>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow className={classes.tableRow}>
                                <TableCell
                                  className={classes.tableCell}
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "1.1rem",
                                  }}
                                >
                                  Social Information
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                  align="right"
                                ></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow
                                className={classes.tableRow}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  className={classes.tableCell}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  Facebook
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {branch.facebook}
                                </TableCell>
                              </TableRow>
                              <TableRow
                                className={classes.tableRow}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  className={classes.tableCell}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  Instagram
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {branch.instagram}
                                </TableCell>
                              </TableRow>
                              <TableRow
                                className={classes.tableRow}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  className={classes.tableCell}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  Linked In
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {branch.linkedIn}
                                </TableCell>
                              </TableRow>
                              <TableRow
                                className={classes.tableRow}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  className={classes.tableCell}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  Twitter
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {branch.twitter}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Item>

                      <Item sx={{ mt: 2 }}>
                        <TableContainer>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow className={classes.tableRow}>
                                <TableCell
                                  className={classes.tableCell}
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "1.1rem",
                                  }}
                                >
                                  Bank Details
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                  align="right"
                                ></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow
                                className={classes.tableRow}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  className={classes.tableCell}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  Bank name
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {branch.bankName}
                                </TableCell>
                              </TableRow>
                              <TableRow
                                className={classes.tableRow}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  className={classes.tableCell}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  BSB
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {branch.BSB}
                                </TableCell>
                              </TableRow>
                              <TableRow
                                className={classes.tableRow}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  className={classes.tableCell}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  Account number
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {branch.accountNumber}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Item>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </>
          )}
        </div>
      )}
      {edit == 1 && (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <Item>
                {" "}
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow className={classes.tableRow}>
                        <TableCell
                          className={classes.tableCell}
                          style={{ fontWeight: "600", fontSize: "1rem" }}
                        >
                          Branch Information
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          align="right"
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        className={classes.tableRow}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          className={classes.tableCell}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          Branch Name
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <TextField
                            value={branchName}
                        onChange={(e) => setBranchName(e.target.value)}
                            InputProps={{
                              className: classes.input,
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow
                        className={classes.tableRow}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          className={classes.tableCell}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          ABN
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <TextField
                            value={ABN}
                            InputProps={{
                              className: classes.input,
                            }}
                            onChange={(e) => setABN(e.target.value)}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow
                        className={classes.tableRow}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          className={classes.tableCell}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          Website
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <TextField
                            value={website}
                            InputProps={{
                              className: classes.input,
                            }}
                            onChange={(e) => setWebsite(e.target.value)}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow
                        className={classes.tableRow}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          className={classes.tableCell}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          Phone
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <TextField
                            value={phone}
                            InputProps={{
                              className: classes.input,
                            }}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow
                        className={classes.tableRow}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          className={classes.tableCell}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          Email
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <TextField
                            value={email}
                            InputProps={{
                              className: classes.input,
                            }}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow
                        className={classes.tableRow}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          className={classes.tableCell}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          Location
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <TextField
                            value={location}
                            InputProps={{
                              className: classes.input,
                            }}
                            onChange={(e) => setLocation(e.target.value)}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Item>
              <Item sx={{ mt: 2 }}>
                {" "}
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow className={classes.tableRow}>
                        <TableCell
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          className={classes.tableCell}
                          style={{ fontWeight: "600", fontSize: "1.1rem" }}
                        >
                          Logo
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          align="right"
                        >  </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        className={classes.tableRow}
                        style={{
                          padding:"10px",
                        }}
                        >
                        <TableCell
                          className={classes.tableCell}
                          >
                          <DropzoneArea
                          fullWidth={false}
                        showPreviews={true}
                        showPreviewsInDropzone={false}
                        useChipsForPreview
                        previewGridProps={{
                          container: { spacing: 1, direction: "row" },
                        }}
                        previewChipProps={{
                          classes: { root: classes.previewChip },
                        }}
                        acceptedFiles={['image/*']} 
                        filesLimit={1}
                        previewText="Selected files"
                        onChange={fileUploadHandler}
                        fileObjects={files}
                      />
                          </TableCell>
                        </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Item>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Item>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow className={classes.tableRow}>
                        <TableCell
                          className={classes.tableCell}
                          style={{ fontWeight: "600", fontSize: "1.1rem" }}
                        >
                          Primary Contact
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          align="right"
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        className={classes.tableRow}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          className={classes.tableCell}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          Employee
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <TextField
                            value={employee}
                            InputProps={{
                              className: classes.input,
                            }}
                            onChange={(e) => setEmployee(e.target.value)}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Item>
              <Item sx={{ mt: 2 }}>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow className={classes.tableRow}>
                        <TableCell
                          className={classes.tableCell}
                          style={{ fontWeight: "600", fontSize: "1.1rem" }}
                        >
                          Primary Address
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          align="right"
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        className={classes.tableRow}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          className={classes.tableCell}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          Address
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <TextField
                            value={address}
                            InputProps={{
                              className: classes.input,
                            }}
                            onChange={(e) => setAddress(e.target.value)}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow
                        className={classes.tableRow}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          className={classes.tableCell}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          Address 2
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <TextField
                            value={address2}
                            InputProps={{
                              className: classes.input,
                            }}
                            onChange={(e) => setAddress2(e.target.value)}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow
                        className={classes.tableRow}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          className={classes.tableCell}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          City
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <TextField
                            value={city}
                            InputProps={{
                              className: classes.input,
                            }}
                            onChange={(e) => setCity(e.target.value)}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow
                        className={classes.tableRow}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          className={classes.tableCell}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          State
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TextField
                            value={state}
                            InputProps={{
                              className: classes.input,
                            }}
                            onChange={(e) => setState(e.target.value)}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow
                        className={classes.tableRow}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          className={classes.tableCell}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          Post Code
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TextField
                            value={postCode}
                            InputProps={{
                              className: classes.input,
                            }}
                            onChange={(e) => setPostCode(e.target.value)}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow
                        className={classes.tableRow}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          className={classes.tableCell}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          Country
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TextField
                            value={country}
                            InputProps={{
                              className: classes.input,
                            }}
                            onChange={(e) => setCountry(e.target.value)}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Item>
              <Item sx={{ mt: 2 }}>
                        <TableContainer>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow className={classes.tableRow}>
                                <TableCell
                                  className={classes.tableCell}
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "1.1rem",
                                  }}
                                >
                                  Kiosk Information
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                  align="right"
                                ></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow
                                className={classes.tableRow}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  className={classes.tableCell}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  Kiosk ID
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {branch.kioskId}
                                </TableCell>
                              </TableRow>
                              <TableRow
                                className={classes.tableRow}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  className={classes.tableCell}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  Code
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {branch.code}
                                </TableCell>
                              </TableRow>
                              <TableRow
                                className={classes.tableRow}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  className={classes.tableCell}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  Require Approval
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                 
                                  <Select
                                    value={requireApproval}
                                    onChange={(e) => setRequireApproval(e.target.value)}
                                    className={classes.selectEmpty}
                                    size="small"
                                    inputProps={{ "aria-label": "Without label" }}
                                  >
                                    <MenuItem value={true}>Yes</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                  </Select>

                                </TableCell>
                              </TableRow>

                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Item>
            </Grid>

            <Grid item xs={12} lg={4}>
              <Item>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow className={classes.tableRow}>
                        <TableCell
                          className={classes.tableCell}
                          style={{ fontWeight: "600", fontSize: "1.1rem" }}
                        >
                          Social Information
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          align="right"
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        className={classes.tableRow}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          className={classes.tableCell}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          Facebook
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <TextField
                            value={facebook}
                            InputProps={{
                              className: classes.input,
                            }}
                            onChange={(e) => setFacebook(e.target.value)}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow
                        className={classes.tableRow}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          className={classes.tableCell}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          Instagram
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <TextField
                            value={instagram}
                            InputProps={{
                              className: classes.input,
                            }}
                            onChange={(e) => setInstagram(e.target.value)}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow
                        className={classes.tableRow}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          className={classes.tableCell}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          Linked In
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <TextField
                            value={linkedIn}
                            InputProps={{
                              className: classes.input,
                            }}
                            onChange={(e) => setLinkedIn(e.target.value)}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow
                        className={classes.tableRow}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          className={classes.tableCell}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          Twitter
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <TextField
                            value={twitter}
                            InputProps={{
                              className: classes.input,
                            }}
                            onChange={(e) => setTwitter(e.target.value)}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Item>
              <Item sx={{ mt: 2 }}>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow className={classes.tableRow}>
                        <TableCell
                          className={classes.tableCell}
                          style={{ fontWeight: "600", fontSize: "1.1rem" }}
                        >
                          Bank Details
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          align="right"
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        className={classes.tableRow}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          className={classes.tableCell}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          Bank name
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <TextField
                            value={bankName}
                            InputProps={{
                              className: classes.input,
                            }}
                            onChange={(e) => setBankName(e.target.value)}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow
                        className={classes.tableRow}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          className={classes.tableCell}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          BSB
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <TextField
                            value={BSB}
                            InputProps={{
                              className: classes.input,
                            }}
                            onChange={(e) => setBSB(e.target.value)}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow
                        className={classes.tableRow}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          className={classes.tableCell}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          Account number
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <TextField
                            value={accountNumber}
                            InputProps={{
                              className: classes.input,
                            }}
                            onChange={(e) => setAccountNumber(e.target.value)}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Item>
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
}

import { createTheme} from "@mui/material";

const theme = createTheme({
    palette: {
      primary: {
        main: '#1a237e',
      },
      secondary: {
        main: '#0044ff'
      },
      root:{
          //backgroundColor: "5f#f5f5"
          backgroundColor: "#f5f5f5"
      }
    },
    typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  });

  export default theme;     



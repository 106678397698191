import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Button, CardActionArea, CardActions } from "@mui/material";
import Box from "@mui/material/Box";
import logo from "../crinitis-logo.png";
import { useLocation, useHistory, Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import InputAdornment from "@mui/material/InputAdornment";
import LockIcon from "@mui/icons-material/Lock";
import { useDispatch, useSelector } from "react-redux";
import { cmsUserRegister } from "../actions/userActions";
import { borderRadius } from "@mui/system";
import FormControl from "@mui/material/FormControl";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorAlert from "../components/ErrorAlert";
import { login } from "../actions/userActions";
import SuccessAlerts from "../components/SuccessAlert";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import UnderDevelopmentMessage from "../components/UnderDevelopment/UnderDevelopmentMessage";
export default function UserSignup() {
  const [value, setValue] = useState("/user/signup");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState("");
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const userRegister = useSelector((state) => state.userRegister);
  const userLogin = useSelector((state) => state.userLogin);
  const { error, success, loading } = userRegister;
  const { userInfo } = userLogin;
  const redirect = location.search ? location.search.split("=")[1] : "/";
  useEffect(() => {
    if (userInfo) {
      history.push("/");
    }
  }, [history, userInfo]);

  const [clickCount, setClickCount] = useState(0);

  const submitHandler = (e) => {
    e.preventDefault();

    console.log(email, password);
    if (!email) {
      setErrorMessage("Please provide an email");
      if (!show) {
        setShow(true);
        setTimeout(() => {
          setShow(false);
          setErrorMessage("");
        }, 5000);
      }
    } else if (!password) {
      setErrorMessage("Please provide a password");
      if (!show) {
        setShow(true);
        setTimeout(() => {
          setShow(false);
          setErrorMessage("");
        }, 5000);
      }
    } else {
      dispatch(cmsUserRegister(email.toLowerCase(), password));
      console.log(error);
      if (!show) {
        setShow(true);
        setTimeout(() => {
          setShow(false);
        }, 5000);
      }
    }
  };
  const handleChange = (event, newValue) => {
    history.push(newValue);
  };

  

  return (
    <div>
      {clickCount < 10 ? (
        <div onClick={() => setClickCount(clickCount + 1)}>
      <UnderDevelopmentMessage 
      goBack={true} msg="Currently SignUp is not allowed."/>
      </div>
      ) : (
        <div></div>
      )}

    <div
      style={{
        backgroundColor: "black",
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "20rem",
          marginBottom: "1rem",
          zIndex: "1000",
          position: "absolute",
          top: 50,
          left: 0,
          right: 0,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="secondary" />
          </div>
        )}

        {show && error && <ErrorAlert error={error} />}
        {show && errorMessage && <ErrorAlert error={errorMessage} />}
        {show && success && <SuccessAlerts success={success} />}
      </div>
      <Card sx={{ maxWidth: 284 }}>
        <div
          style={{
            padding: "1rem 2.5rem 0.1rem 2.5rem",
            backgroundColor: "#ebebeb",
          }}
        >
          <CardMedia component="img" image={logo} alt="logo" />
          <p
            style={{
              textAlign: "center",
              fontSize: "1.5rem",
              marginTop: "0.1rem",
            }}
          >
           Admin Signup
          </p>
        </div>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            aria-label="basic tabs example"
            value={value}
            onChange={handleChange}
            centered
          >
            <Tab value="/user/login" label="Login" style={{ width: "50%" }} />
            <Tab value="/user/signup" label="Signup" style={{ width: "50%" }} />
          </Tabs>
        </Box>

        <Box noValidate autoComplete="off">
          <form
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            onSubmit={submitHandler}
          >
            <TextField
              id="outlined-basic"
              label="Email"
              type="email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={{
                width: "85%",
                margin: "auto",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CreditCardIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id="outlined-basic"
              label="Password"
              type="password"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              style={{
                width: "85%",
                margin: "auto",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
            />
            <div
              style={{
                textAlign: "center",
                backgroundColor: "#ebebeb",
                color: "grey",
                padding: "0.7rem 0.5rem",
              }}
            >
              By signing up, you agree to our terms of service and privacy
              policy.
            </div>

            <Button
              type="submit"
              style={{
                width: "100%",
                backgroundColor: "#1a237e",
                color: "white",
                padding: "1.8rem 0",
                borderRadius: "0",
              }}
            >
              SIGN UP <ArrowForwardIosIcon sx={{fontSize:"1rem"}} />
            </Button>
          </form>
        </Box>
      </Card>
    </div>
    </div>
  );
}

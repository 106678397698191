import React, { Suspense, useEffect, useState } from 'react';
// import ScheduleHeader from '../components/Employees/Schedule/ScheduleHeader';
// import ScheduleTable from '../components/Employees/Schedule/ScheduleTable';
import { addDays, set } from 'date-fns';
import moment from 'moment';
import axios from 'axios';
import { useSelector } from 'react-redux';

import "../components/Employees/Schedule/Schedule.css";
import SuccessAlerts from '../components/SuccessAlert';
import ErrorAlerts from '../components/ErrorAlert';
import MobileScheduleHeader from '../components/Employees/Schedule/MobileScheduleHeader';

const ScheduleHeader = React.lazy(() => import('../components/Employees/Schedule/ScheduleHeader'));
const ScheduleTable = React.lazy(() => import('../components/Employees/Schedule/ScheduleTable'));

const Schedule = () => {
  const [scheduleLoading, setScheduleLoading] = useState(true);
  const [scheduleArray, setScheduleArray] = useState([]);
  const [allScheduleArray, setAllScheduleArray] = useState([]);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [search, setSearch] = useState("");
  const [summary, setSummary] = useState([]);
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1024);
  const updateMedia = () => {
    setIsMobile(window.innerWidth < 1024);
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const getMonday = (d) => {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }
  const getSunday = (d) => {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? 0 : 7); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }


  const [startDate, setStartDate] = useState(getMonday(new Date()));
  const [endDate, setEndDate] = useState(getSunday(new Date()));
  const [totalPublished, setTotalPublished] = useState(0);
  const [totalUnPublished, setTotalUnPublished] = useState(0);
  const [employees, setEmployees] = useState([]);
  const [employeeLoading, setEmployeeLoading] = useState(false);

  const getWeekNumber = (d) => {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    const weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    return weekNo < 10 ? `0${weekNo}` : weekNo;
  };

  // function getWeekString(date) {
  //   const week = getWeekNumber(date);
  //   const year = date.getFullYear();
  //   return `${year}-W${week}`;
  // }



  const [week, setWeek] = useState(moment().format('YYYY-[W]WW'));


  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
    },
  };

  //*********Employee Count******
  const fetchEmployees = () => {
    setEmployeeLoading(true);
    axios.get(
      `${process.env.REACT_APP_API_BACKEND}/api/schedule/employees`,
      config
    ).then((res) => {
      setEmployees(res.data.employees);
      setEmployeeLoading(false);
    }).catch((err) => {
      console.log(err);
    }
    );
  }

  const getRoster = (week) => {
    setScheduleLoading(true);
    setScheduleArray([]);
    setAllScheduleArray([]);
    const data = {
      "week": week,
    }
    axios.post(`${process.env.REACT_APP_API_BACKEND}/api/schedule`, data, config).then((res) => {
      setTotalPublished(res.data.totalPublishedShifts);
      setTotalUnPublished(res.data.totalUnpublishedShifts);
      const sortedScheduleItems = [];
      res.data.scheduleItems.forEach((department) => {
        const sortedDepartment = department.scheduleItems.sort((a, b) => {
          return a.published - b.published;
        });
        sortedScheduleItems.push({ department: department.department, scheduleItems: sortedDepartment });
      });
      setAllScheduleArray(sortedScheduleItems);
      setSummary(res.data.summary);
      if (search === "") {
        setScheduleArray(sortedScheduleItems);
      }
      else {
        const data = search;
        const filteredArray = sortedScheduleItems.map((department) => {
          const filteredDepartment = department.scheduleItems.filter((schedule) => {
            //schedule.employeeDetails.fname.toLowerCase().includes(data.toLowerCase()) || schedule.employeeDetails.lname.toLowerCase().includes(data.toLowerCase())
            return (
              schedule.employee.employeeDetail.fname.toLowerCase().includes(data.toLowerCase()) 
              || schedule.employee.employeeDetail.lname.toLowerCase().includes(data.toLowerCase()) 
              || schedule.employee.email.toLowerCase().includes(data.toLowerCase())
              //or in combo of first and last name
              || schedule.employee.employeeDetail.fname.toLowerCase() + " " + schedule.employee.employeeDetail.lname.toLowerCase() === data.toLowerCase()
            )
          });
  
          if (filteredDepartment.length > 0) {
            return { department: department.department, scheduleItems: filteredDepartment };
          }
          return null; // If no matching schedule items, return null
        }).filter(Boolean); // Filter out null values

        setScheduleArray(filteredArray);
      }
      // setScheduleLoading(false);
      setTimeout(() => {
        setScheduleLoading(false);
      }
        , 500);
    })

  }




  const handleWeekChange = (week) => {
    setWeek(week);
    const firstDay = moment(week, 'YYYY-[W]WW').startOf('isoWeek');
    const lastDay = moment(week, 'YYYY-[W]WW').endOf('isoWeek');
    setStartDate(firstDay);
    setEndDate(lastDay);
    sessionStorage.setItem("week", week);
    getRoster(week);
  };






  const handlePreviousWeek = () => {
    const newWeek = moment(week, 'YYYY-[W]WW').subtract(1, 'weeks').format('YYYY-[W]WW');
    setWeek(newWeek);
    sessionStorage.setItem("week", newWeek);
    const firstDay = moment(newWeek, 'YYYY-[W]WW').startOf('isoWeek');
    const lastDay = moment(newWeek, 'YYYY-[W]WW').endOf('isoWeek');
    setStartDate(firstDay);
    setEndDate(lastDay);
    getRoster(newWeek);
  };
  const handleNextWeek = () => {
    const newWeek = moment(week, 'YYYY-[W]WW').add(1, 'weeks').format('YYYY-[W]WW');
    setWeek(newWeek);
    sessionStorage.setItem("week", newWeek);
    const firstDay = moment(newWeek, 'YYYY-[W]WW').startOf('isoWeek');
    const lastDay = moment(newWeek, 'YYYY-[W]WW').endOf('isoWeek');
    setStartDate(firstDay);
    setEndDate(lastDay);
    getRoster(newWeek);

  };


  
  const handleEmployeeSearch = (e) => {
    const data = e.target.value;
    setSearch(data);

    if (data === "") {
      setScheduleArray(allScheduleArray);
    } else {
      const filteredArray = allScheduleArray.map((department) => {
        const filteredDepartment = department.scheduleItems.filter((schedule) => {
          //schedule.employeeDetails.fname.toLowerCase().includes(data.toLowerCase()) || schedule.employeeDetails.lname.toLowerCase().includes(data.toLowerCase())
          return (
            schedule.employee.employeeDetail.fname.toLowerCase().includes(data.toLowerCase()) 
            || schedule.employee.employeeDetail.lname.toLowerCase().includes(data.toLowerCase()) 
            || schedule.employee.email.toLowerCase().includes(data.toLowerCase())
            //or in combo of first and last name
            || schedule.employee.employeeDetail.fname.toLowerCase() + " " + schedule.employee.employeeDetail.lname.toLowerCase() === data.toLowerCase()
            || `${schedule.employee.employeeDetail.fname.toLowerCase()} ${schedule.employee.employeeDetail.lname.toLowerCase()}`.includes(data.toLowerCase())
          )
        });

        if (filteredDepartment.length > 0) {
          return { department: department.department, scheduleItems: filteredDepartment };
        }
        return null; // If no matching schedule items, return null
      }).filter(Boolean); // Filter out null values

      setScheduleArray(filteredArray);
    }
  };








  const sessionWeek = sessionStorage.getItem("week");
  useEffect(() => {
    fetchEmployees();
    if (sessionWeek) {
      setWeek(sessionWeek);
      const firstDay = moment(sessionWeek, 'YYYY-[W]WW').startOf('isoWeek');
      const lastDay = moment(sessionWeek, 'YYYY-[W]WW').endOf('isoWeek');

      setStartDate(firstDay);
      setEndDate(lastDay);
      getRoster(sessionWeek);
    } else {
      const year = startDate.getFullYear();
      const week = getWeekNumber(startDate);
      setWeek(`${year}-W${week}`);
      getRoster(`${year}-W${week}`);
      sessionStorage.setItem("week", `${year}-W${week}`);
    }
  }
    , []);

  return (
    <div
      style={{
        overflow: "auto",
      }}
    >
      {success && <SuccessAlerts success={success} />}
      {error && <ErrorAlerts error={error} />}
<Suspense fallback={<div>Loading...</div>}>
      {/* <ScheduleHeader fetch={getRoster}
        handlePreviousWeek={handlePreviousWeek}
        handleNextWeek={handleNextWeek}
        handleWeekChange={handleWeekChange}
        week={week}
        totalPublished={totalPublished}
        totalUnPublished={totalUnPublished}
        search={search} handleSearch={handleSearch}
        setSearch={setSearch}
        loading={scheduleLoading}
        setSuccess={setSuccess}
        setError={setError}
        allScheduleArray={allScheduleArray}
        setScheduleArray={setScheduleArray}
        summary={summary}
      /> */}
      {!isMobile ? 
        <ScheduleHeader 
        fetch={getRoster}
        handlePreviousWeek={handlePreviousWeek}
        handleNextWeek={handleNextWeek}
        handleWeekChange={handleWeekChange}
        week={week}
        totalPublished={totalPublished}
        totalUnPublished={totalUnPublished}
        search={search} 
        setSearch={setSearch}
        loading={scheduleLoading}
        setSuccess={setSuccess}
        setError={setError}
        allScheduleArray={allScheduleArray}
        setScheduleArray={setScheduleArray}
        summary={summary}
        handleEmployeeSearch={handleEmployeeSearch}
      />
      :
      <MobileScheduleHeader
      fetch={getRoster}
        handlePreviousWeek={handlePreviousWeek}
        handleNextWeek={handleNextWeek}
        handleWeekChange={handleWeekChange}
        week={week}
        totalPublished={totalPublished}
        totalUnPublished={totalUnPublished}
        search={search}
        setSearch={setSearch}
        loading={scheduleLoading}
        setSuccess={setSuccess}
        setError={setError}
        allScheduleArray={allScheduleArray}
        setScheduleArray={setScheduleArray}
        summary={summary}
      /> 
      }

</Suspense>
      <Suspense fallback={<div>Loading...</div>}>
      <ScheduleTable 
      fetch={getRoster} 
      startDate={startDate} 
      endDate={endDate} 
      data={scheduleArray} 
      loading={scheduleLoading} 
      employees={employees} 
      employeeLoading={employeeLoading} 
      fetchEmployees={fetchEmployees} 
      setSuccess={setSuccess} 
      setError={setError} 
      summary={summary} 
      setSearch={setSearch}
      handleSearch={handleEmployeeSearch}
      />
      </Suspense>
    </div>
  );
}

export default Schedule;
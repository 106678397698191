import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Alert, Button, CardActionArea, CardActions } from "@mui/material";
import Box from "@mui/material/Box";
import logo from "../crinitis-logo.png";
import { useLocation, useHistory, Link, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import InputAdornment from "@mui/material/InputAdornment";
import LockIcon from "@mui/icons-material/Lock";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions/userActions";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorAlert from "../components/ErrorAlert";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from "axios";
export default function NewPassword() {
  const [email, setEmail] = useState("");
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const [showOTP, setSHowOTP] = useState(false);
  const location = useLocation();
  const history = useHistory();

  // getting logged in user info
  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;
const prt = sessionStorage.getItem("password-reset-token");
  const params = useParams();
  const post = params.post;
useEffect(() => {
    if(!prt){
      if(post === "user"){
        history.push("/user/login");
    }else{
        history.push("/login");
    }
    }
  } , [prt, post, history]);

const handlePasswordReset = (e) => {
    e.preventDefault();
    if (!password) {
        setErrorMessage("Please provide a password");
        setShow(true);
        setTimeout(() => {
            setShow(false);
            setErrorMessage("");
        } , 5000);
        
    } else if (password !== confirmPassword) {
        setErrorMessage("Passwords do not match");
        setShow(true);
        setTimeout(() => {
            setShow(false);
            setErrorMessage("");
        } , 5000);

    } else {
        setErrorMessage("");
        setShow(false);
        axios.post(`${process.env.REACT_APP_API_BACKEND}/api/otp/resetpassword`, {
            password: password,
            confirmPassword: confirmPassword
        }, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${prt}`
            },
        })
            .then((res) => {
                
                setShow(true);
                setMessage("Password reset successfully");
                setTimeout(() => {
                    setShow(false);
                    setMessage("");
                    sessionStorage.removeItem("password-reset-token");
                    if(post === "user"){
                        history.push("/user/login");
                    }else{
                        history.push("/login");
                    }

                } , 3000);

            }
            )
            .catch((err) => {
                console.log(err);
            }
            );
    }
}

  
  const handleChange = (event, newValue) => {
    history.push(newValue);
  };
const goBack = () => {
    history.goBack();
}


  return (
    <div
    style={{
      backgroundColor: "#1f1f1fff",
      width: "100vw",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
      <div
        style={{
          width: "20rem",
          marginBottom: "1rem",
          zIndex: "1000",
          position: "absolute",
          top: 50,
          left: 0,
          right: 0,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="secondary" />
          </div>
        )}
        {show && errorMessage && <ErrorAlert error={errorMessage} />}
        {show && message && <Alert severity="success">{message}</Alert>}
      </div>
      <Card
        elevation={3}
        sx={{
          width: 360,
          borderRadius: "20px",
          backgroundColor: "#292a2c",
        }}>
        <div
          style={{
            padding: "1rem 2.5rem 0.1rem 2.5rem",
            backgroundColor: "#969696ff",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <img src={logo} alt="logo" style={{ height: "100px", margin: "auto" }} />
          </div>
          <p
            style={{
              textAlign: "center",
              fontSize: "1.5rem",
              marginTop: "0.3rem",
              marginBottom:"0.8rem",
              marginLeft:"1.5rem",
            }}
          >
            Set New Password
          </p>
        </div>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          
        </Box>
        <Box noValidate autoComplete="off">
          <form
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            // onSubmit={submitHandler}
          >
            <TextField
              id="outlined-basic"
              label="Password"
              type="email"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              sx={{ '& label': { color: 'white' } }}
              style={{
                width: "85%",
                margin: "auto",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            />
            <TextField
              id="outlined-basic"
              label="Confirm Password"
              type="email"  
              variant="outlined"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              sx={{ '& label': { color: 'white' } }}
              style={{
                width: "85%",
                margin: "auto",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            />
            <Button
              type="submit"
              style={{
                width: "100%",
                color: "white",
                padding: "1.8rem 0",
                borderRadius: "0",
                color: "#000",
              }}
              sx={{
                backgroundColor: "primary.main",
                "&:hover": {
                  backgroundColor: "primary.dark",
                },
              }}
                onClick={handlePasswordReset}
            >
             Confirm<ArrowForwardIosIcon sx={{fontSize:"1rem"}} />
            </Button>
          
          </form>
        </Box>
      </Card>
    </div>
  );
}
